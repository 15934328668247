import { Edit as EditIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { capitalize } from 'inflection';
import _ from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { ArrayInput, AutocompleteInput, Create, CreateProps, Datagrid, DateField, DeleteWithConfirmButton, Edit, EditProps, FormDataConsumer, FunctionField, Identifier, List, ListProps, Pagination, RaRecord, RadioButtonGroupInput, ReferenceField, ReferenceInput, ReferenceOneField, SaveButton, SelectInput, SimpleFormIterator, SimpleShowLayout, TextField, TextInput, Toolbar, email, maxLength, useCreateContext, useEditContext, useListContext, useUpdate } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import { EmailsInput } from './EmailsInput';

import { SimpleFormWithLocation } from './MyBreadcrumb';
import { Childless } from './types';
import { nodupes } from './validate';

export { default as ReputationReportSchedulesIcon } from '@mui/icons-material/CalendarMonth';

export const ReputationReportScheduleList: FC<Childless<ListProps>> = props => {
  const theme = useTheme();
  const isDarkMode = useMemo( () => theme.palette?.mode == 'dark', [ theme.palette ] );
  const backgroundColor = useMemo( () => theme.palette?.background?.default || '#313131', [ theme.palette ] );
  return (
    <List { ...props }
      exporter={ false }
      filters={ [
        <SelectInput source='kind' label='Type' choices={ [
          { id: 'profile', name: 'Profile' },
          { id: 'visualization', name: 'Visualization' },
        ] } />,
      ] }
      filter={ { kind: [ 'profile', 'visualization' ] } }
      pagination={ <Pagination rowsPerPageOptions={ [ 10 ] } /> }
    >
      <Datagrid
        bulkActionButtons={ <>
          <BulkReportScheduleSubscribeButton />
          <BulkReportScheduleUnsubscribeButton />
        </> }
        rowClick='edit'
        sx={ {
          '& .MuiToolbar-root': {
            backgroundColor: isDarkMode ? backgroundColor : null,
            overflow: 'hidden',
          }
        } }
      >
        <FunctionField label='Type' source='kind' render={ ( record: RaRecord ) => capitalize( record.kind ) } />
        <FunctionField label='Source' render={ ( record: RaRecord ) => (
          record.kind == 'visualization'
            ? (
              <ReferenceField source='visualization' reference='reputationvisualizations' link={ false } >
                <TextField source='title' />
              </ReferenceField>
            )
            : (
              <>
                <ReferenceField source='profile' reference='reputationprofiles' link={ false } >
                  <ReferenceOneField reference='reputationsummaries' target='profile' sort={ { field: 'createdAt', order: 'DESC' } } >
                    <TextField source='name' />
                  </ReferenceOneField>
                </ReferenceField>
                { /* ' - ' */ }
                {/* @ts-ignore:2322 */ }
                <ReferenceField source='profile' reference='reputationprofiles' link={ false } isLoading={ <></> } >
                  <FunctionField source='platform' render={ ( r ) => ( r?.platform ? ` - ${ capitalize( r.platform ) }` : '' ) } />
                  {/*

                  <ReferenceField source='platform' reference='reputationplatforms'>
                  <TextField source='name' />
                  </ReferenceField>
                */}
                </ReferenceField>
              </>
            )
        ) } />
        <FunctionField label='Frequency' render={ ( record: RaRecord ) => record.frequency.unit == 'weeks' ? 'Weekly' : 'Monthly' } />
        <DateField source='nextScheduledAt' />
        <TextField source='comment' />
      </Datagrid>
    </List>
  );
}

export const BulkReportScheduleSubscribeButton: FC = () => {
  const { data, resource, selectedIds } = useListContext<{ id: Identifier, recipients: string[] }>();
  const [ update, { isLoading } ] = useUpdate();
  const [ open, setOpen ] = useState( false );
  const [ recipientsToAdd, setRecipientsToAdd ] = useState<string[]>( [] );
  const [ isValidInput, setIsValidInput ] = useState( true );

  const handleClickOpen = () => setOpen( true );
  const handleClose = () => setOpen( false );
  const handleSubmit = useCallback( async () => {
    if( !data ) return;
    for( const id of selectedIds ) {
      const previousData = data.find( r => r.id == id );
      if( !previousData ) continue;
      const patchData = {
        recipients: _( previousData.recipients.concat( recipientsToAdd ) )
          .sortBy( x => x.toLowerCase() )
          .sortedUniqBy( x => x.toLowerCase() )
          .value(),
      };
      const newRecipients = _.differenceBy( patchData.recipients, previousData.recipients, x => x.toLowerCase() );
      if( newRecipients.length == 0 ) continue;
      await update( resource, {
        id,
        data: patchData,
        previousData: previousData,
      } );
    }
    handleClose();
  }, [ data, recipientsToAdd, resource, selectedIds, update ] );
  return (
    <>
      <Button
        variant='text'
        size='small'
        onClick={ handleClickOpen }
        startIcon={ <EditIcon /> }
        disableFocusRipple
      > Subscribe </Button>
      <Dialog open={ open || isLoading } onClose={ handleClose }>
        <DialogTitle> Add Recipients </DialogTitle>
        <DialogContent sx={ { width: '400px' } } >
          <EmailsInput
            value={ recipientsToAdd }
            onChange={ setRecipientsToAdd }
            setValidity={ setIsValidInput }
            tabIndex={ 0 }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={ handleClose }
            color='inherit'
            variant='contained'
            disableFocusRipple
            tabIndex={ 2 }
          >Cancel</Button>
          <LoadingButton
            loading={ isLoading }
            onClick={ handleSubmit }
            color='primary'
            variant='contained'
            disableFocusRipple
            disabled={ !isValidInput || isLoading }
            tabIndex={ 1 }
          >Save</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
export const BulkReportScheduleUnsubscribeButton: FC = () => {
  const { data, resource, selectedIds } = useListContext<{ id: Identifier, recipients: string[] }>();
  const [ update, { isLoading } ] = useUpdate();
  const [ open, setOpen ] = useState( false );
  const [ recipientsToRemove, setRecipientsToRemove ] = useState<string[]>( [] );
  const [ isValidInput, setIsValidInput ] = useState( true );

  const handleClickOpen = () => setOpen( true );
  const handleClose = () => setOpen( false );
  const handleSubmit = useCallback( async () => {
    if( !data ) return;
    for( const id of selectedIds ) {
      const previousData = data.find( r => r.id == id );
      if( !previousData ) continue;
      const patchData = {
        recipients: _.differenceBy( previousData.recipients, recipientsToRemove, x => x.toLowerCase() )
      };
      const removedRecipients = _.differenceBy( previousData.recipients, patchData.recipients, x => x.toLowerCase() );
      if( removedRecipients.length == 0 ) continue;
      await update( resource, {
        id,
        data: patchData,
        previousData: previousData,
      } );
    }
    handleClose();
  }, [ data, recipientsToRemove, resource, selectedIds, update ] );
  return (
    <>
      <Button
        variant='text'
        size='small'
        onClick={ handleClickOpen }
        startIcon={ <EditIcon /> }
        disableFocusRipple
      > Unsubscribe </Button>
      <Dialog open={ open || isLoading } onClose={ handleClose }>
        <DialogTitle> Remove Recipients </DialogTitle>
        <DialogContent sx={ { width: '400px' } } >
          <EmailsInput
            value={ recipientsToRemove }
            onChange={ setRecipientsToRemove }
            setValidity={ setIsValidInput }
            tabIndex={ 0 }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={ handleClose }
            color='inherit'
            variant='contained'
            disableFocusRipple
            tabIndex={ 2 }
          >Cancel</Button>
          <LoadingButton
            loading={ isLoading }
            onClick={ handleSubmit }
            color='primary'
            variant='contained'
            disableFocusRipple
            disabled={ !isValidInput || isLoading }
            tabIndex={ 1 }
          >Save</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

const transform = ( data: RaRecord & { recipients: string[] } ): RaRecord => {
  return {
    ...data,
    comment: data.comment || '',
    recipients: _( data.recipients )
      .sortBy( r => r.toLowerCase() )
      .sortedUniqBy( r => r.toLowerCase() )
      .value(),
  }
}

const ReputationReportScheduleCreateForm: FC = () => {
  const createContext = useCreateContext();
  const queryClient = useQueryClient();
  return (
    ( <SimpleFormWithLocation
      basePath='reputation'
      defaultValues={ {
        frequency: { count: 1 },
        kind: 'visualization',
        nextScheduledAt: new Date(),
      } }
      // @ts-ignore: 2339
      onSubmit={ data => {
        if( !createContext.save ) return;
        createContext.save( data );
        queryClient.invalidateQueries( {
          queryKey: [ createContext.resource, 'getList' ]
        } );
      } }
    >
      <ReferenceInput source='visualization' reference='reputationvisualizations'>
        <AutocompleteInput source='title' fullWidth />
      </ReferenceInput>
      <RadioButtonGroupInput source='frequency.unit' label='Frequency'
        defaultValue='months'
        choices={ [
          { id: 'months', name: 'Monthly' },
          { id: 'weeks', name: 'Weekly' },
        ] }
      />
      <TextInput source='comment' fullWidth resettable />
      <ArrayInput source='recipients' validate={ [ nodupes() ] } defaultValue={ [ '' ] } >
        <SimpleFormIterator inline disableReordering fullWidth >
          <TextInput source='' label='Email' helperText={ false } fullWidth autoComplete={ 'new-password' } validate={ [ email(), maxLength( 255 ) ] } required />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleFormWithLocation> )
  );
}
export const ReputationReportScheduleCreate: FC<CreateProps> = props => {
  return (
    <Create { ...props }
      redirect='/reputation/reputationreportschedules'
      transform={ transform }
    >
      <ReputationReportScheduleCreateForm />
    </Create>
  );
}

const ReputationReportScheduleEditForm: FC = () => {
  const editContext = useEditContext();
  const queryClient = useQueryClient();
  return (
    ( <SimpleFormWithLocation
      basePath='reputation'
      defaultValues={ {
        frequency: { count: 1 },
        kind: 'visualization',
        nextScheduledAt: new Date(),
      } }
      // @ts-ignore: 2339
      onSubmit={ data => {
        if( !editContext.save ) return;
        editContext.save( data );
        queryClient.invalidateQueries( {
          queryKey: [ editContext.resource, 'getList' ]
        } );
      } }
      toolbar={ (
        <Toolbar sx={ { display: 'flex', justifyContent: 'space-between' } }>
          <SaveButton />
          <DeleteWithConfirmButton
            redirect={ () => 'reputation/reputationreportschedules' }
            translateOptions={ { name: 'Email List' } }
          />
        </Toolbar>
      ) }
    >
      <FormDataConsumer>
        { ( { formData } ) => formData.kind == 'visualization' ?
          (
            <ReferenceInput source='visualization' reference='reputationvisualizations'>
              <AutocompleteInput source='title' fullWidth />
            </ReferenceInput>
          ) :
          (
            <SimpleShowLayout sx={ { padding: 0 } }>
              <ReferenceField label='Profile' source='profile' reference='reputationprofiles'>
                <ReferenceOneField reference='reputationsummaries' target='profile' sort={ { field: 'createdAt', order: 'DESC' } } >
                  <TextField source='name' />
                </ReferenceOneField>
              </ReferenceField>
              <ReferenceField label='Platform' source='profile' reference='reputationprofiles'>
                <ReferenceField source='platform' reference='reputationplatforms'>
                  <TextField source='name' />
                </ReferenceField>
              </ReferenceField>
            </SimpleShowLayout>
          )
        }
      </FormDataConsumer>
      <RadioButtonGroupInput source='frequency.unit' label='Frequency'
        defaultValue='months'
        choices={ [
          { id: 'months', name: 'Monthly' },
          { id: 'weeks', name: 'Weekly' },
        ] }
      />
      <TextInput source='comment' fullWidth resettable />
      <ArrayInput source='recipients' validate={ [ nodupes() ] } defaultValue={ [ '' ] } >
        <SimpleFormIterator inline disableReordering fullWidth >
          <TextInput source='' label='Email' helperText={ false } fullWidth autoComplete={ 'new-password' } validate={ [ email(), maxLength( 255 ) ] } required />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleFormWithLocation> )
  );
}
export const ReputationReportScheduleEdit: FC<EditProps> = props => {
  return (
    <Edit { ...props }
      redirect='/reputation/reputationreportschedules'  // not sure why I have to hard code this one and not others
      mutationMode='pessimistic'
      transform={ transform }
    >
      <ReputationReportScheduleEditForm />
    </Edit>
  );
}
