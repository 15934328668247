import { DragIndicator, Palette, Cached as ReloadIcon, Replay as ResetIcon } from '@mui/icons-material';
import { Box, Button, Card, CardContent, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Tooltip, Typography } from '@mui/material';
import { amber, deepPurple, green, indigo, purple, red, teal, yellow } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { applyNodeChanges, Background, BackgroundVariant, ControlButton, Controls, Edge, Handle, MiniMap, Node, NodeProps, NodeToolbar, NodeToolbarProps, OnNodesChange, Panel, Position, ReactFlow, ReactFlowProvider, useReactFlow } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { default as dayjs, Dayjs } from 'dayjs';
import { dump } from 'js-yaml';
import { keyBy, sortBy, get } from 'lodash';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Title, useGetList, useNotify } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { apiUrl, httpClient } from './DataProvider';
import './FlowDevTools.css';
import { useUserPreference } from './UserPreferences';
import { useParentOrg } from './Organizations';

export interface MuiColor { 50: string; 100: string; 200: string; 300: string; 400: string; 500: string; 600: string; 700: string; 800: string; 900: string; A100: string; A200: string; A400: string; A700: string; }

const palettes: MuiColor[][] = [
  [ indigo, green, red ],
  [ red, yellow, green ],
  [ teal, amber, deepPurple ],
  [ purple, green, yellow ],
];

interface AppointmentType extends Record<string, unknown> {
  id: string;
  name: string;
  units: number;
  tagSpacing?: {
    tag: string;
    units: number;
  }
  tags?: { name: string }[];
}

// export const appointmentTypes = [
//   //  { code: 'lunch', duration: 60, units: 4, display: 'Lunch' },
//   { code: '505', duration: 15, units: 1, display: 'Follow-up' },
//   { code: '506', duration: 15, units: 1, display: 'New Patient' },
//   // { code: '1099', duration: 30, units: 2, display: 'Cataract Surgery' },
//   { code: '1100', duration: 30, units: 2, display: 'Cataract Work Up' },
//   { code: '1101', duration: 30, units: 2, display: 'Cataract Work Up 2' },
//   { code: '1102', duration: 30, units: 2, display: 'CL Exam New Patient' },
//   { code: '1103', duration: 15, units: 1, display: 'CL Followup - no charge' },
//   { code: '1104', duration: 60, units: 4, display: 'Consult Myopia Mgmt' },
//   { code: '1105', duration: 60, units: 4, display: 'Consult Cataract' },
//   { code: '1106', duration: 60, units: 4, display: 'Consult LASIK' },
//   { code: '1107', duration: 60, units: 4, display: 'Consult Pterygium' },
//   { code: '1108', duration: 60, units: 4, display: 'Consult Skin' },
//   { code: '1109', duration: 15, units: 1, display: 'Glaucoma Test' },
//   { code: '1110', duration: 30, units: 2, display: 'Glaucoma VF' },
//   { code: '1111', duration: 15, units: 1, display: 'Green Laser' },
//   // { code: '1112', duration: 60, units: 4, display: 'Eyelid Sx Long' },
//   // { code: '1113', duration: 15, units: 1, display: 'Eyelid Sx Short' },
//   // { code: '1114', duration: 30, units: 2, display: 'LASIK Surgery' },
//   // { code: '1115', duration: 30, units: 2, display: 'Outpatient Sx' },
//   { code: '1118', duration: 30, units: 2, display: 'Vision New' },
//   { code: '1119', duration: 15, units: 1, display: 'Myopia Mgmt FU - no chrge' },
//   { code: '1120', duration: 15, units: 1, display: 'Post Op LASIK' },
//   { code: '1121', duration: 15, units: 1, display: 'Post Op Medical' },
//   { code: '1122', duration: 15, units: 1, display: 'Post Op Refraction' },
//   // { code: '1123', duration: 30, units: 2, display: 'Pterygium Surgery' },
//   { code: '1124', duration: 15, units: 1, display: 'Refraction' },
//   { code: '1125', duration: 15, units: 1, display: 'Rx Check - no charge' },
//   { code: '1126', duration: 30, units: 2, display: 'Testing VF' },
//   { code: '1127', duration: 15, units: 1, display: 'Testing Other' },
//   { code: '1128', duration: 30, units: 2, display: 'Vision Established' },
//   { code: '1129', duration: 60, units: 4, display: 'Work Up Bleph' },
//   { code: '1130', duration: 60, units: 4, display: 'Work Up Myopia Mgmt' },
//   { code: '1131', duration: 60, units: 4, display: 'Work Up LASIK' },
//   { code: '1132', duration: 60, units: 4, display: 'Work Up Pterygium' },
//   // { code: '1133', duration: 15, units: 1, display: 'YAG Laser' },
//   { code: '3223', duration: 30, units: 2, display: 'CL Exam Established Pat' },
//   { code: '7518', duration: 30, units: 2, display: 'Myopia Mgmt Established' },
//   { code: '9171', duration: 30, units: 2, display: 'Vision MCAL' }
// ];
//  
// export const appointmentsMap = keyBy( appointmentTypes, 'code' );


export interface LayoutScheduleProps {
  nodes: Node<Partial<ScheduleData>>[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  slots?: string[];
  code?: string;
  palette?: MuiColor[];
  appointmentsMap: Record<string, AppointmentType>;
}

export const LayoutSchedule: FC<LayoutScheduleProps> = props => {
  const { nodes, edges, setNodes, slots = [], code, palette = [ indigo, green, red ], appointmentsMap } = props;
  const { fitView } = useReactFlow();
  const [ nodesDraggable, setNodesDraggable ] = useState( false );
  const appointmentType = useMemo<AppointmentType | undefined>( () => code && appointmentsMap[ code ] || undefined, [ appointmentsMap, code ] );
  const { tagSpacing } = appointmentType || {};

  const onNodesChange = useCallback<OnNodesChange>( changes => setNodes( ( nodes ) => applyNodeChanges( changes, nodes ) ), [] );
  // const onEdgesChange = useCallback<OnEdgesChange>( changes => setEdges( ( edges ) => applyEdgeChanges( changes, edges ) ), [] );
  // const onConnect = useCallback<OnConnect>( params => setEdges( ( edges ) => addEdge( params, edges ) ), [] );

  const edgeTypes = {
    // button: ButtonEdge
  };

  const nodeTypes = {
    schedule: ScheduleNode,
    annotation: AnnotationNode,
  };

  useEffect( () => {
    const proposedIds = slots.flatMap( s => s.split( ',' ) );
    setNodes( nodes => nodes.map( node => {
      if( !node?.data ) return node;
      const { id, data } = node;
      const { tags = [] } = data;
      node.data.proposed = proposedIds.includes( id ) ? code : undefined;
      node.data.sameAsProposed = !node.data.proposed
        && ( node.data.code == code || ( tagSpacing?.tag && ( tags as { name: string }[] ).map( t => t.name ).includes( tagSpacing.tag ) ) )
        || undefined;
      const paths = slots.filter( s => s.includes( id ) );
      const path = paths.find( s => s.startsWith( id ) ) || paths[ 0 ];
      if( path ) node.data.path = path;
      return node;
    } ) );

    window.requestAnimationFrame( () => {
      fitView();
    } );
  }, [ slots, setNodes, code ] );


  return (
    <Box
      sx={ {
        // minHeight: '60rem',
        // width: '100%',
        border: '1px solid gray',
        display: 'flex',
        '& .react-flow__controls .react-flow__controls-button ': {
          width: '30px',
          height: '30px',
          '& svg': {
            maxWidth: '80%',
            maxHeight: '80%',
          }
        },

        '& .scheduleNode': {
          background: '#fff',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'gray',

          '&.booked': {
            background: palette[ 0 ][ 50 ], //'#f8f8ff', //( preReserve ? '#f8f8f8' : isFirstSlot ? '#e8e8ff' :  ),
            zIndex: 1,
            borderColor: palette[ 0 ][ 500 ], // 'blue',
            '&.vision-assist': {
              borderWidth: '4px',
            },
            '&.preReserve': {
              background: '#f8f8f8',
              borderColor: 'black',
              '&.vision-assist': {
                borderWidth: '1px',
              },
            },
            '&.isFirstSlot': {
              background: palette[ 0 ][ 100 ], //'#e8e8ff',
              '&.vision-assist': {
                borderWidth: '8px'
              },
            },
            '&.sameAsProposed': {
              background: palette[ 1 ][ 50 ], // '#f8fff8',
              borderColor: palette[ 1 ][ 500 ], // 'green',
              '&.vision-assist': {
                borderStyle: 'dashed dotted',
              },
              '&.isFirstSlot': {
                background: palette[ 1 ][ 100 ], // '#e8ffe8',
                '&.vision-assist': {
                  borderWidth: '8px'
                },
              },
            },
          },
          '&.proposed': {
            background: palette[ 2 ][ 50 ], //'#fff8f8',
            borderColor: palette[ 2 ][ 500 ], // 'red',
            '&.vision-assist': {
              borderStyle: 'dotted',
              borderWidth: '4px',
            },
            '&.isFirstSlot': {
              background: palette[ 2 ][ 100 ], //'#ffe8e8',
              '&.vision-assist': {
                borderWidth: '8px',
              },
            },
          },

          // background: sameAsProposed ? '#e8ffe8' : proposed ? ( isFirstSlot ? '#ffe8e8' : '#fff8f8' ) : code ? ( preReserve ? '#f8f8f8' : isFirstSlot ? '#e8e8ff' : '#f8f8ff' ) : '#fff',
          // zIndex: code ? 1 : undefined,
          // borderWidth: sameAsProposed ? ( isFirstSlot ? '8px' : '4px' ) : proposed ? ( isFirstSlot ? '8px' : '4px' ) : code ? ( preReserve ? '1px' : isFirstSlot ? '8px' : '4px' ) : '1px',
          // borderStyle: sameAsProposed ? 'dashed dotted' : proposed ? 'dotted' : 'solid',   // "dashed" | "dotted" | "double" | "groove" | "hidden" | "inset" | "none" | "outset" | "ridge" | "solid"
          // borderColor: sameAsProposed ? 'green' : proposed ? 'red' : preReserve ? 'black' : code ? 'blue' : 'gray',

        },
      } }>
      <Box
        sx={ {
          width: '100%', // '80rem',
          height: '60rem',
          flexGrow: 1,
          border: '1px solid',
          borderColor: 'white',
          '& .react-flow__node-input': {
            borderColor: 'red',
          },
          '& .schedule-edge path': {
            stroke: nodesDraggable ? undefined : 'transparent',
          },
          '& .connectionindicator': nodesDraggable
            ? undefined
            : {
              background: 'transparent !important',
              borderColor: 'transparent',
            },
          '& .react-flow__controls.top.right': {
            right: '224px',
          },
          '& .react-flow__minimap.top.right': {
            // top: '48px',
          },
          '& .schedule-legend': {
            top: '146px',
            right: '-26px',
          },
        } } >
        <ReactFlow
          // onNodeClick
          nodes={ nodes }
          onNodesChange={ onNodesChange }
          edges={ edges }
          fitView
          defaultEdgeOptions={ {
            // type: ConnectionLineType.SmoothStep,
            // type: 'button',
          } }
          edgeTypes={ edgeTypes }
          nodeTypes={ nodeTypes }
          nodesDraggable={ nodesDraggable }
        >

          <Background
            variant={ BackgroundVariant.Dots }
            bgColor={ '#f3f3f9' }
          />

          <Controls
            orientation='vertical'
            position='top-right'
            showInteractive={ false }
            fitViewOptions={ {
              nodes: [ { id: 'schedule' }, { id: 'annotation' } ],
            } }
          >
            <Tooltip title={ `${ nodesDraggable ? 'Disable' : 'Enable' } node dragging` }>
              <Box>
                <ControlButton onClick={ () => setNodesDraggable( d => !d ) }
                  style={ {
                    backgroundColor: nodesDraggable ? 'white' : '#f5f5f5',
                  } }
                >
                  <DragIndicator color={ nodesDraggable ? 'primary' : undefined } />
                </ControlButton>
              </Box>
            </Tooltip>
            {/*
                <ControlButton onClick={ () => setAutoLayout( auto => !auto ) }>
                <MagicWand color={ autoLayout ? 'error' : undefined } />
                </ControlButton>
              */}
          </Controls>

          <MiniMap
            position='top-right'
            nodeStrokeWidth={ 3 }
            nodeStrokeColor={ ( _n: Node ) => {
              // const { data } = n || {};
              // const { code, proposed, preReserve } = data;
              // if( proposed ) return 'red';
              // if( code && !preReserve ) return 'blue';
              // if( n.type === 'selectorNode' ) return 'green';
              // if( n.type === 'split' ) return 'purple';
              // if( n.type === 'wait' ) return 'red';
              // if( n.type === 'output' ) return 'blue';
              return 'black';
            } }
            nodeColor={ ( n: Node ) => {
              const { data } = n || {};
              const { code, proposed, preReserve, sameAsProposed } = data;
              if( sameAsProposed ) return '#b8ffb8'; // 'green'; //'#e8ffe8'
              if( proposed ) return '#ffb8b8'; // 'red'; // '#ffe8e8';
              if( preReserve ) return '#f8f8f8';
              if( code && !preReserve ) return '#b8b8ff'; // 'blue'; // '#e8e8ff';
              return '#fff';
            } }
          />
          <ScheduleLegend />
        </ReactFlow>
      </Box>
    </Box>
  );
}

export const Schedules: FC<{ date?: string }> = ( { date } ) => {
  const navigate = useNavigate();

  useEffect( () => {
    navigate( `/appointment/graph/${ date || dayjs().add( 1, 'day' ).toISOString().slice( 0, 10 ) }` );
  }, [] );
  return null;
}

export interface NodeValue extends Record<string, unknown> {
  pid: number;
  rank: number;
  book: number;
  practitioner?: string;
  practitionerName?: string;
  label?: string;
}
export interface ApiSchedule {
  value: string;
  nodes: { v: string; value?: NodeValue; parent?: string; }[],
  edges: { v: string; w: string; name: string }[],
  date?: string;
  practitioner?: string;
}


export const Schedule: FC<{ d?: string }> = ( { d } ) => {
  const basePath = 'schedule';
  const baseLocation = `admin.${ basePath }`;
  const { id: idParam } = useParams();
  const id = useMemo( () => d || idParam, [ d, idParam ] );
  const [ _appLocation, setAppLocation ] = useAppLocationState();
  const { scheduleUnitMinutes: unitMinutes = 15 } = useParentOrg();
  const { data: locations } = useGetList<{ id: string; name: string }>( 'locations', { filter: { active: true, 'locationEnabledForMessageSend': 'true' }, pagination: { page: 1, perPage: 100 } } );
  const { data: practitioners } = useGetList( 'practitioners', { filter: { active: true, 'q:npi': '1' }, pagination: { page: 1, perPage: 100 } } );
  const { data: appointmentTypes } = useGetList<AppointmentType>( 'appointmenttypes', { pagination: { page: 1, perPage: 100 } } );
  const appointmentsMap = useMemo( () => keyBy( appointmentTypes || [], 'id' ), [ appointmentTypes ] );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ label, setLabel ] = useState( '' );
  const [ schedule, setSchedule ] = useState<ApiSchedule | undefined>();
  const [ date, setDate ] = useState<Dayjs>( dayjs( id ) );
  const [ paletteIdx, setPaletteIdx ] = useState( 0 );
  const [ location, setLocation ] = useState( '' );
  // const [ practitioner, setPractitioner ] = useState( '' );
  const [ nodes, setNodes ] = useState<Node<Partial<ScheduleData>>[]>( [] );
  const [ edges, setEdges ] = useState<Edge[]>( [] );
  const [ code, setCode ] = useState( '' );
  const [ recentCodes, setRecentCodes ] = useState<string[]>( [] );
  const [ slots, setSlots ] = useState<string[]>( [] );
  const { preferences, update: updatePreference } = useUserPreference();
  const navigate = useNavigate();
  const notify = useNotify();

  const fetchSchedule = useCallback( async (): Promise<ApiSchedule> => {
    const { body } = await httpClient( `${ apiUrl }/schedule/${ id }` );
    return JSON.parse( body ) as ApiSchedule;
  }, [ httpClient, id ] );

  const fetchSlots = useCallback( async ( code: string, location?: string ) => {
    const { body } = await httpClient( `${ apiUrl }/schedule/${ id }/${ code }${ location ? `?location=${ location }` : '' }` );
    return JSON.parse( body );
  }, [ httpClient, id ] );

  // const bookSlot = useCallback( async ( code: string, slot: string, patient = '12345' ) => {
  //   const { body } = await httpClient( `${ apiUrl }/schedule/${ id }/${ code }`, {
  //     method: 'POST',
  //     body: JSON.stringify( { patient, slot } ),
  //   } );
  //   return JSON.parse( body );
  // }, [ httpClient, id ] );

  const loadSchedule = useCallback( async ( date: string, name: string ) => {
    if( !name || !date ) return;
    try {
      const { body } = await httpClient( `${ apiUrl }/schedule`, {
        method: 'POST',
        body: JSON.stringify( { name, date } ),
      } );
      return JSON.parse( body ) as ApiSchedule;
    } catch( e ) {
      return;
    }
  }, [ httpClient, id, schedule ] );

  const reloadSchedule = useCallback( async () => {
    const { value: name, date } = schedule || {};
    if( !name || !date ) return;
    return loadSchedule( date, name );
  }, [ httpClient, id, schedule ] );

  useEffect( () => {
    setAppLocation( `${ baseLocation }` );
    return () => setAppLocation( null );
  }, [] );

  useEffect( () => {
    setIsLoading( true )
  }, [ id ] );

  useEffect( () => {
    ( async () => {
      if( !isLoading || !practitioners ) return;
      const schedule = await fetchSchedule();
      if( !schedule ) return;
      setSchedule( schedule );
      const { value: label, edges = [], nodes = [] } = schedule as ApiSchedule; // { value: string; nodes: { v: string, value: Record<string, unknown> }[], edges: { v: string; w: string; name: string }[] };
      const type = 'schedule';
      const maxBook = nodes.reduce( ( max, n ) => Math.max( max, ( n.value?.book || 0 ) as number ), 0 );
      const practitionerHeaders = nodes.reduce<[ number, string ][]>( ( map, n ) => {
        const { parent: practitionerId } = n;
        const { pid = 0 } = n?.value || {};
        if( map.find( ( [ k ] ) => k === pid ) ) return map;
        const { lastName } = ( practitioners || [] ).find( p => p.id == practitionerId ) || {};
        return [ ...map, [ pid, lastName || ( practitionerId || '' ).slice( -4 ) ] ];
      }, [] );
      setNodes( [
        ...nodes.filter( n => n.parent ).map( n => {
          const { v: id, value } = n;
          const { pid = 0, rank, book = 0 } = value || {};
          if( rank === undefined ) {
            return { id, type: 'hidden', position: { x: 0, y: 0 }, data: { ...value, label: value?.label || id } };
          }
          const position = {
            x: book * 170 + pid * ( ( maxBook + 1 ) * 170 + 85 ),
            y: rank * 85,
          };
          return { id, type, position, data: { ...value, label: value?.label || id } };

        } ),
        // Practitioner name headers
        ...practitionerHeaders.map( ( [ pid, label ] ) => (
          {
            id: `p${ pid }`,
            type: 'annotation',
            position: {
              x: pid * ( ( maxBook + 1 ) * 170 + 85 ),
              y: -100,
            },
            data: {
              label,
              width: ( maxBook + 1 ) * 170,
            },
          } ) ),
        // Appointment time Hour headers on left
        ...nodes.filter( n => n.value?.pid === 0 && n.value?.book === 0 && n.value?.label?.match( /\d+:00/ ) ).map( n => {
          const { rank = 0, label = '' } = n?.value || {};
          const [ hour ] = label.split( ' ' );
          return {
            id: `h${ hour }`,
            type: 'annotation',
            position: {
              x: -30,
              y: rank * 85,
            },
            origin: [ 1, 0.5 ],
            data: {
              label: hour,
              width: 120,
            },
          };
        } ),
      ] as Node[] );
      setEdges( edges.map( e => {
        const { v: source, w: target, name: id } = e;
        return { id, source, target, className: 'schedule-edge' };
      } ) as Edge[] );
      setLabel( label );
      setIsLoading( false );
    } )();
  }, [ id, fetchSchedule, isLoading, practitioners ] );

  // useEffect( () => {
  //   ( async () => {
  //     if( !date || !schedule?.practitioner ) return;
  //     const d = date.toISOString().slice( 0, 10 );
  //     await loadSchedule( d, schedule.practitioner, d + '-vu' );
  //     setIsLoading( true );
  //   } )();
  // }, [ date, schedule ] );

  useEffect( () => {
    ( async () => {
      const slots = await fetchSlots( code, location );
      if( !slots?.length ) {
        setSlots( [] );
        if( code ) {
          notify( 'Found no available times.', { type: 'error', autoHideDuration: 8000 } );
        }
        return;
      }
      setSlots( sortBy( slots, s => {
        const [ _0, p = '0', r = '0' ] = s.match( /^p(\d+)r(\d+)/ ) || [];
        return parseFloat( `${ r }.${ p }` );
      } ) );
      setRecentCodes( codes => {
        if( codes.includes( code ) ) return codes;
        return [ code, ...codes ].slice( 0, 3 );
      } );
      notify( `Found ${ slots.length } available times.`, { type: 'success', autoHideDuration: 8000 } );
    } )();
  }, [ code, setRecentCodes, setSlots, location ] );

  useEffect( () => { ( async () => recentCodes.length && updatePreference && await updatePreference( 'recentScheduleCodes', recentCodes ) )() }, [ recentCodes, updatePreference ] );

  useEffect( () => {
    ( async () => {
      if( recentCodes.length || !preferences ) return;
      if( !preferences?.recentScheduleCodes ) return;
      setRecentCodes( preferences.recentScheduleCodes as string[] );
    } )();
  }, [ preferences, recentCodes ] );

  // useEffect( () => {
  //   const proposedIds = slots.flatMap( s => s.split( ',' ) );
  //   setNodes( nodes => nodes.map( node => {
  //     if( !node?.data ) return node;
  //     const { id } = node;
  //     node.data.proposed = proposedIds.includes( id ) ? code : undefined;
  //     node.data.sameAsProposed = !node.data.proposed && node.data.code == code || undefined;
  //     const paths = slots.filter( s => s.includes( id ) );
  //     const path = paths.find( s => s.startsWith( id ) ) || paths[ 0 ];
  //     if( path ) node.data.path = path;
  //     return node;
  //   } ) );
  // }, [ slots, setNodes, code ] );


  // const hasBooked = useMemo( () => !!nodes.find( n => n.data.code ), [ nodes ] );
  const isReloadable = useMemo( () => schedule?.value && date, [ schedule, date ] );
  //
  // const handleClearClick = useCallback( async () => {
  //   setCode( '' );
  //   setSlots( [] );
  //   const paths = uniq( nodes.map( n => n.data.path ).filter( isNotEmpty ) );
  //   for( const path of paths ) {
  //     await deleteSlot( path );
  //   }
  //   setIsLoading( true );
  // }, [ nodes ] );
  //  
  const handleReloadClick = useCallback( async () => {
    setCode( '' );
    setSlots( [] );
    await reloadSchedule();
    setIsLoading( true );
  }, [] );

  const handleCodeChange = useCallback( ( e: SelectChangeEvent | string ) => {
    const code = typeof e == 'string' ? e : e.target.value as string;
    setCode( code );
    setSlots( [] );
  }, [ setCode ] );

  const handleDateChange = useCallback( async ( date: dayjs.Dayjs | null ) => {
    setDate( date || dayjs() );
    setCode( '' );
    setSlots( [] );
    const d = ( date || dayjs() ).toISOString().slice( 0, 10 );
    const result = await loadSchedule( d, d );
    if( !result ) {
      notify( `No available times for ${ d }`, { type: 'error' } );
      return;
    }
    navigate( `/admin/schedule/${ d }` );
  }, [ nodes ] );

  const handleResetClick = useCallback( async () => {
    setCode( '' );
    setSlots( [] );
    const d = ( date || dayjs() ).toISOString().slice( 0, 10 );
    await loadSchedule( d, d );
    setIsLoading( true );
  }, [ date ] );

  useEffect( () => {
    ( async () => {
      if( preferences?.schedulePaletteIdx == undefined ) return;
      if( preferences.schedulePaletteIdx == paletteIdx ) return;
      setPaletteIdx( preferences.schedulePaletteIdx as number );
    } )();
  }, [ preferences?.schedulePaletteIdx, paletteIdx ] );

  const handlePaletteClick = useCallback( async () => {
    const idx = ( paletteIdx + 1 ) % palettes.length;
    updatePreference && await updatePreference( 'schedulePaletteIdx', idx );
    // setPaletteIdx( idx );
  }, [ paletteIdx, setPaletteIdx, updatePreference ] );


  const palette = useMemo<MuiColor[]>( () => palettes[ paletteIdx ] || palettes[ 0 ], [ paletteIdx ] );

  const handleLocationChange = useCallback( async ( location: string ) => {
    setLocation( location )
    setCode( '' );
    setSlots( [] );
  }, [ nodes, date ] );

  // const handlePractitionerChange = useCallback( async ( practitioner: string ) => {
  //   setPractitioner( practitioner )
  //   setCode( '' );
  //   setSlots( [] );
  //   if( !date || !practitioner ) return;
  //   const d = date.toISOString().slice( 0, 10 );
  //   const id = d + '-' + practitioner;
  //   await loadSchedule( d, practitioner, id );
  //   navigate( `/admin/schedule/${ id }` );
  // }, [ nodes, date ] );

  // const handleSlotClick = useCallback( ( slot: string ) => async () => {
  //   await bookSlot( code, slot );
  //   setCode( '' );
  //   setSlots( [] );
  //   setIsLoading( true );
  // }, [ code, bookSlot, setIsLoading, setCode, setSlots ] );
  //
  // const slotLabels = useMemo<Record<string, string>>( () => {
  //   return Object.fromEntries( slots.map( path => {
  //     const slot = path.split( ',' )[ 0 ];
  //     const node = nodes.find( n => n.id == slot );
  //     if( !node?.data ) return [ path, slot ];
  //     const { label, practitioner } = node.data;
  //     const p = ( practitioners || [] ).find( p => p.id == practitioner ) || { };
  //     return [ path, `${ label?.split( ' ' )[ 0 ] || '' } ${ p.lastName || practitioner }` ];
  //   } ) );
  // }, [ slots, nodes, practitioners ] );


  return (
    <IfCanAccess resource='locations' action='write'>
      <ReactFlowProvider>
        <Box>
          <Title title='Schedule graph ' />
          <Card
            sx={ {
              minHeight: '25em',
            } }
          >
            <CardContent>
              <Typography color='gray'>{ label || <>&nbsp;</> }</Typography>
              <Box
                sx={ {
                  display: 'flex',
                  '& .MuiButton-root,& .MuiIconButton-root': {
                    marginLeft: '1rem',
                    '&.actionButton': {
                      height: 'fit-content',
                      paddingLeft: '1rem',
                      paddingRight: '1rem',
                    }
                  }
                } }
              >
                <Box
                  sx={ {
                    // flexGrow: 1,
                    width: '22rem',
                  } }
                >
                  <FormControl fullWidth margin='dense'
                    sx={ {
                      // border: '1px solid purple',
                    } }
                  >
                    <InputLabel id="code-select-label">Pick an appointment type to schedule</InputLabel>
                    <Select
                      labelId="code-select-label"
                      id="code-select"
                      value={ code }
                      label="Appointment to schedule"
                      onChange={ handleCodeChange }
                      MenuProps={ {
                        slotProps: {
                          paper: {
                            style: {
                              maxHeight: '40%',
                            },
                          },
                        }
                      } }
                    >
                      <MenuItem key='none' value={ '' }>None</MenuItem>
                      { sortBy( appointmentTypes, 'name' ).map( t => {
                        const { id: code, name, units } = t;
                        const duration = units * unitMinutes;
                        return (
                          <MenuItem key={ code } value={ code }>{ `${ duration.toString() } mins:  ${ name } (${ code })` }</MenuItem>
                        );
                      } ) }
                    </Select>
                    <FormHelperText id="my-helper-text" color='primary'>
                      { slots.length
                        ? 'Select a red box to book a slot.'
                        : ' '
                      }
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box
                  sx={ {
                    marginLeft: '1rem',
                    flexGrow: 1,
                  } }
                >
                  { recentCodes.map( ( code, idx ) => {
                    const { name } = appointmentsMap[ code ] || {};
                    return (
                      <Button
                        key={ idx }
                        variant='outlined'
                        color='secondary'
                        size='small'
                        sx={ {
                          margin: '0.5rem 1rem 0.5rem 0',
                          textTransform: 'initial',
                        } }
                        onClick={ () => handleCodeChange( code ) }
                      >
                        { `${ name }` }
                      </Button>
                    );
                  } ) }
                </Box>
                {/* <Box sx={ { flexGrow: 0.1 } } /> */ }
                {/* 
                    <Button
                    disabled={ !hasBooked }
                    onClick={ handleClearClick }
                    color='error'
                    >
                    Clear
                    </Button>
                  */ }


                <PaletteSelect paletteIdx={ paletteIdx } handleClick={ handlePaletteClick } />

                <Button
                  className='actionButton'
                  startIcon={ <ReloadIcon /> }
                  disabled={ !isReloadable }
                  onClick={ handleReloadClick }
                >
                  Reload
                </Button>

                <Button
                  className='actionButton'
                  startIcon={ <ResetIcon /> }
                  color='error'
                  disabled={ !isReloadable }
                  onClick={ handleResetClick }
                >
                  Reset
                </Button>

                <LocationSelect location={ location } locations={ locations } onValueChange={ handleLocationChange } />

                <ScheduleDatePicker date={ date } setDate={ ( date ) => { if( typeof date != 'function' ) handleDateChange( date ) } } />

              </Box>
              {/* 
                  { slots.map( ( slot, idx ) => (
                  <Button
                  key={ idx }
                  variant='contained'
                  sx={ {
                  margin: '0.5rem 1rem 0.5rem 0',
                  } }
                  onClick={ handleSlotClick( slot ) }
                  >
                  { slotLabels[ slot ] || slot }
                  </Button>
                  ) ) }
                */}

              { !isLoading &&
                <LayoutSchedule { ...{ nodes, edges, setNodes, setEdges, slots, code, preferences, palette, appointmentsMap } } />
              }

              {/* 
                  <MuiTextField
                  id="outlined-multiline-static"
                  label="graphlib json"
                  multiline
                  rows={ 10 }
                  value={ json }
                  onChange={ ( event: React.ChangeEvent<HTMLInputElement> ) => {
                  setJson( event.target.value );
                  } }
                  sx={ {
                  fontSize: '0.4rem',
                  } }
                  />
                */}
            </CardContent>
          </Card>


        </Box >
      </ReactFlowProvider>
    </IfCanAccess >
  );
}


export interface PaletteSelectProps {
  paletteIdx: number;
  handleClick?: () => void;
}

export const PaletteSelect: FC<PaletteSelectProps> = props => {
  const { paletteIdx, handleClick } = props;
  return (
    <IconButton
      className='actionButton'
      size='small'
      onClick={ handleClick }
      sx={ {
        '&.MuiIconButton-root .MuiSvgIcon-root': {
          fill: palettes[ paletteIdx ][ 0 ][ 500 ] || palettes[ 0 ][ 0 ][ 500 ],
          '&:hover': {
            fill: palettes[ paletteIdx + 1 ] && palettes[ paletteIdx + 1 ][ 0 ][ 500 ] || palettes[ 0 ][ 0 ][ 500 ],
          }
        }
      } }
    >
      <Palette />
    </IconButton>
  );
}

export interface LocationSelectProps {
  location: string;
  locations?: { id: string; name: string }[];
  onValueChange: ( v: string ) => void;
}

export const LocationSelect: FC<LocationSelectProps> = props => {
  const { location, locations, onValueChange } = props;
  return (
    <FormControl
      margin='none'
      variant='standard'
      sx={ {
        maxWidth: '12rem',
        marginTop: '-1rem',
        marginLeft: '1rem',
      } }
    >
      <InputLabel id="location-select-label">Location</InputLabel>
      <Select
        labelId="location-select-label"
        id="location-select"
        value={ location }
        label="Location"
        onChange={ e => onValueChange( e.target.value ) }
        MenuProps={ {
          slotProps: {
            paper: {
              style: {
                maxHeight: '40%', // controls long lists
              },
            },
          }
        } }
      >
        <MenuItem key='none' value={ '' }>Any</MenuItem>
        { sortBy( ( locations || [] ), 'name' ).map( p => {
          const { id, name } = p;
          return (
            <MenuItem key={ id } value={ id }>{ name }</MenuItem>
          );
        } ) }
      </Select>
    </FormControl>
  );
}

export interface ScheduleDatePickerProps {
  date: Dayjs | null;
  setDate: Dispatch<SetStateAction<Dayjs | null>>;
}

export const ScheduleDatePicker: FC<ScheduleDatePickerProps> = ( { date, setDate } ) => {

  return (
    <LocalizationProvider dateAdapter={ AdapterDayjs }>
      <DatePicker
        label=""
        value={ date }
        views={ [ 'year', 'month', 'day' ] }
        onChange={ ( d ) => !d || d.isSame( new Date(), 'day' ) ? setDate( null ) : setDate( d ) }
        slotProps={ {
          actionBar: ( { wrapperVariant } ) => ( {
            actions: wrapperVariant === 'desktop' && date ? [ 'clear' ] : [],
          } ),
          textField: {
            InputProps: {
              placeholder: 'Schedule date',
              sx: {
                '&:before': {
                  border: '0px solid transparent',
                },
                backgroundColor: 'transparent',
                borderBottomColor: 'transparent',
                '& .MuiInputBase-input': {
                  textAlign: 'end',
                }
              }
            },
            fullWidth: false,
            size: 'small',
            color: 'primary',
            margin: 'none',
            hiddenLabel: true,
            // helperText: 'helper',
            sx: {
              border: '0px solid transparent',
              width: '12rem',
            },
          }
        } }
      />
    </LocalizationProvider>
  );
}


export interface AnnotationData extends Record<string, unknown> {
  label: string;
  width?: number;
}

export interface AnnotationNodeProps extends NodeProps<Node<AnnotationData, 'annotation'>> {
}

export const AnnotationNode: FC<AnnotationNodeProps> = props => {
  const { data } = props;
  const { label, width = 340 } = data;

  return (
    <Box
      className='annotationNode'
      sx={ {
        fontSize: '48px',
        fontWeight: 'bold',
        color: 'gray',
        // background: proposed ? ( isFirstSlot ? '#ffe8e8' : '#fff8f8' ) : code ? ( preReserve ? '#f8f8f8' : isFirstSlot ? '#e8e8ff' : '#f8f8ff' ) : '#fff',
        // borderWidth: '1px',
        // borderStyle: 'solid',
        // borderColor: isVisible || proposed ? 'red' : preReserve ? 'black' : code ? 'blue' : 'gray',
        // borderRadius: '5px',
        textAlign: 'center',
        verticalAlign: 'middle',
        width: `${ width }px`,
        padding: '0.2rem',
        height: '85px',
        // overflowY: 'scroll',
      } }
    >
      <div>
        { label }
      </div>
    </Box >
  );
}



export interface ScheduleData extends Record<string, unknown> {
  id: string;
  label: string;
  overrideLabel?: string; // legend
  rank: number;
  book: number;
  path?: string; // comma separated
  code?: string;
  display?: string;
  duration?: number;
  // units: number;
  patient?: string;
  proposed?: string; // code
  sameAsProposed?: boolean;
  fhirId?: string;
  practitioner?: string;
  practitionerName?: string;
  location?: string;
}

export interface ScheduleNodeProps extends NodeProps<Node<ScheduleData, 'schedule'>> {
  // data: {
  //   // splits: number
  //   // onChange: ( e: ChangeEvent<HTMLInputElement> ) => void;
  // };
  toolbar?: NodeToolbarProps;
}

export const ScheduleNode: FC<ScheduleNodeProps> = props => {
  const { id: scheduleId } = useParams();
  const { id, data, toolbar } = props;
  const { position, isVisible } = toolbar || {};
  const { label, overrideLabel, rank, code, display, preReserve, patient, proposed, sameAsProposed, path, fhirId } = data;
  const { preferences } = useUserPreference();
  const { enableVisionAssistiveMode, dashboardEnablePatterns } = preferences || {};

  const bookSlot = useCallback( async ( code: string, path: string, patient: string ) => {
    const { body } = await httpClient( `${ apiUrl }/schedule/${ scheduleId }/${ code }`, {
      method: 'POST',
      body: JSON.stringify( { patient, path } ),
    } );
    return JSON.parse( body );
  }, [ httpClient, scheduleId ] );

  const deleteSlot = useCallback( async ( path: string ) => {
    const { body } = await httpClient( `${ apiUrl }/schedule/${ scheduleId }/${ path }`, { method: 'DELETE' } );
    return JSON.parse( body );
  }, [ httpClient, scheduleId ] );

  const handleBook = useCallback( async () => {
    if( !proposed || !path ) return;
    await bookSlot( proposed, path, patient || '12345' );
    window.location.reload();
  }, [ proposed, path, bookSlot ] );

  const handleDelete = useCallback( async () => {
    if( !path ) return;
    await deleteSlot( path );
    window.location.reload();
  }, [ path, deleteSlot ] );

  const isFirstSlot = useMemo( () => path?.split( ',' )[ 0 ] == id, [ path, id ] );

  const className = useMemo( () => {
    const isFirstSlot = path?.split( ',' )[ 0 ] == id;
    const classes = [ 'scheduleNode' ];
    if( dashboardEnablePatterns || enableVisionAssistiveMode ) classes.push( 'vision-assist' );
    if( code ) classes.push( 'booked' );
    if( proposed ) classes.push( 'proposed' );
    if( sameAsProposed ) classes.push( 'sameAsProposed' );
    if( isFirstSlot ) classes.push( 'isFirstSlot' );
    if( preReserve ) classes.push( 'preReserve' );
    return classes.join( ' ' );
  }, [ code, proposed, sameAsProposed, path, id, preReserve ] );

  if( overrideLabel ) return (
    <Box
      className={ className }
      sx={ {
        fontSize: '12px',
        textAlign: 'center',
        width: '170px', // code ? '166px' : '170px',
        height: '85px', // code ? '81px' : '85px',
      } }
    >
      <Box sx={ { width: '100%', height: '100%', display: 'flex' } }>
        <Typography sx={ { fontWeight: 'normal', alignSelf: 'center', margin: '0 auto' } } >
          { overrideLabel }
        </Typography>
      </Box>
    </Box >
  );

  return (
    <Tooltip
      title={ <Typography component='pre'>{ dump( data ) }</Typography> }
      followCursor
      placement='bottom-start'
      arrow
    >
      <Box
        className={ className }
        sx={ {
          fontSize: '12px',
          textAlign: 'center',
          width: '170px', // code ? '166px' : '170px',
          height: '85px', // code ? '81px' : '85px',
        } }
      >
        <NodeToolbar isVisible={ isVisible } position={ position } offset={ 0 } >
          { !proposed &&
            <Button variant='contained' size='small' onClick={ handleDelete }>Delete</Button>
          }
          { proposed && isFirstSlot &&
            <Button variant='contained'
              // size='small'
              color='success' onClick={ handleBook }>Book</Button>
          }
        </NodeToolbar>
        <Handle
          type="target"
          position={ Position.Top }
          style={ { background: '#555' } }
        // onConnect={ ( params ) => console.log( 'handle onConnect', params ) }
        // isConnectable={ isConnectable }
        />
        <div>
          { `Rank ${ rank }: ${ label }` }
        </div>
        {
          preReserve
            ? <div>{ display } </div>
            : code &&
            <div>
              { code } - { display }
            </div>
        }
        {
          fhirId &&
          <div>
            FHIR Id: { fhirId }
          </div>
        }
        <Handle
          type="source"
          position={ Position.Bottom }
          style={ { background: '#555' } }
        // isConnectable={ isConnectable }
        />
      </Box >
    </Tooltip >
  );
}


export const ScheduleLegend: FC = () => (
  <Panel
    className='schedule-legend'
    position='top-right'
    style={ {
      background: 'white',
      padding: '1rem',
      transform: 'scale(0.8)',
      margin: '4px',
    } }
  >
    <Stack
      sx={ {
        '& .legend-row': {
          display: 'flex',
          columnGap: '1rem',
        }

      } }
    >
      <Typography variant='h6'>Legend</Typography>
      <Box className='legend-row' >
        <ScheduleNode id='open' data={ { id: 'open', label: '', overrideLabel: 'Open', rank: 0, book: 0 } } type='schedule' dragging={ false } zIndex={ 0 } isConnectable={ false } positionAbsoluteX={ 0 } positionAbsoluteY={ 0 } />
        <ScheduleNode id='proposed1' data={ { id: 'open', label: '', overrideLabel: 'Proposed (head)', rank: 0, book: 0, proposed: '2', path: 'proposed1' } } type='schedule' dragging={ false } zIndex={ 0 } isConnectable={ false } positionAbsoluteX={ 0 } positionAbsoluteY={ 0 } />
      </Box>
      <Box className='legend-row' >
        <ScheduleNode id='open' data={ { id: 'open', label: '', overrideLabel: 'Reserved', rank: 0, book: 0, code: '1', preReserve: true } } type='schedule' dragging={ false } zIndex={ 0 } isConnectable={ false } positionAbsoluteX={ 0 } positionAbsoluteY={ 0 } />
        <ScheduleNode id='proposed2' data={ { id: 'open', label: '', overrideLabel: 'Proposed (tail)', rank: 0, book: 0, proposed: '2', path: 'proposed1' } } type='schedule' dragging={ false } zIndex={ 0 } isConnectable={ false } positionAbsoluteX={ 0 } positionAbsoluteY={ 0 } />
      </Box>
      <Box className='legend-row' >
        <ScheduleNode id='booked1' data={ { id: 'open', label: '', overrideLabel: 'Booked (head)', rank: 0, book: 0, code: '1', path: 'booked1' } } type='schedule' dragging={ false } zIndex={ 0 } isConnectable={ false } positionAbsoluteX={ 0 } positionAbsoluteY={ 0 } />
        <ScheduleNode id='sameas1' data={ { id: 'open', label: '', overrideLabel: 'Booked, Same as Proposed (head)', rank: 0, book: 0, code: '1', sameAsProposed: true, path: 'sameas1' } } type='schedule' dragging={ false } zIndex={ 0 } isConnectable={ false } positionAbsoluteX={ 0 } positionAbsoluteY={ 0 } />
      </Box>
      <Box className='legend-row' >
        <ScheduleNode id='booked2' data={ { id: 'open', label: '', overrideLabel: 'Booked (tail)', rank: 0, book: 0, code: '1', path: 'booked1' } } type='schedule' dragging={ false } zIndex={ 0 } isConnectable={ false } positionAbsoluteX={ 0 } positionAbsoluteY={ 0 } />
        <ScheduleNode id='sameas2' data={ { id: 'open', label: '', overrideLabel: 'Booked, Same as Proposed (tail)', rank: 0, book: 0, code: '1', sameAsProposed: true, path: 'sameas1' } } type='schedule' dragging={ false } zIndex={ 0 } isConnectable={ false } positionAbsoluteX={ 0 } positionAbsoluteY={ 0 } />
      </Box>
    </Stack>
  </Panel>
);
