import { Check as CheckIcon, DragIndicator } from '@mui/icons-material';
import { Box, Card, CardContent, CircularProgress, Grid, InputProps, List as MuiList, ListItem, ListItemIcon, ListItemText, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useAppLocationState, useDefineAppLocation } from '@react-admin/ra-navigation';
import _, { sortBy } from 'lodash';
import { FC, ReactElement, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ArrayInput, AutocompleteInput, Datagrid, Edit, EditButton, EditProps, Filter, FilterProps, Form, FunctionField, Identifier, Labeled, List, ListProps, ReferenceInput, SearchInput, SelectField, SelectInput, Show, ShowButton, ShowProps, SimpleFormIterator, TextField, TextInput, Title, TopToolbar, useGetList, useGetOne, useNotify, useRecordContext, useRedirect, WithRecord } from 'react-admin';
import { default as ReactAudioPlayer } from 'react-audio-player';
import { Draggable } from 'react-drag-reorder';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AdminShowLayout, useAdminListAppLocation } from './Admin';
import { apiUrl, choices, httpClient, resourceMap } from './DataProvider';
import { AddItemButton, RemoveItemButton, ReOrderButtons } from './HtmlAssemblies';
import { EditorSetup, HtmlEditorProps } from './HtmlEditor';
import { LangApi } from './langs';
import { LanguageSelect, LanguageSelectProps } from './LanguageSelect';
import { MessageConfigEdit, SectionHeader } from './MessageConfigs';
import { CustomToolbar } from './ReminderTemplateLists';
import { ResourceContextProvider } from './ResourceContextProvider';
import { ShowActionsWithReset } from './ShowActionsWithReset';

export { default as MessageTemplateIcon } from '@mui/icons-material/Code';

export const arrayBufferToBase64 = ( buffer: ArrayBufferLike ): string => {
  let binary = '';
  const bytes = new Uint8Array( buffer );
  const len = bytes.byteLength;
  for( let i = 0; i < len; i++ ) {
    binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}

export const encodeBase64 = async ( array: BlobPart ): Promise<string> => {
  return new Promise( ( resolve, reject ) => {
    const blob = new Blob( [ array ] );
    const reader = new FileReader();
    reader.onload = ( event: ProgressEvent<FileReader> ) => {
      const { result: blobDataUrl } = event.target || {};
      if( typeof blobDataUrl == 'string' ) {
        const [ _, base64 ] = blobDataUrl.split( ',' );
        resolve( base64 );
      }
      reject();
    };
    reader.readAsDataURL( blob ); // returns "data:application/octet-stream;base64,...
  } );
}

//  unzip -p Replacement\ Variables.docx word/document.xml | xq -c 'include "helpers2"; [ ..|."w:t"? | strings | select( . | test( "^[a-z]+([A-Z][a-z]+)+$" ) ) ] | unique[] | { id: ., title: ( . | camel_to_snake | snake_to_title ) }' | jq -s '[ .[] | { id, title, content: ( "{{" + .id + "}}" ) } ]' | pbcopy
export const templates = [
  { 'id': 'alertEmail', 'title': 'Alert Email', 'content': '{{alertEmail}}' },
  { 'id': 'alertSms', 'title': 'Alert Sms', 'content': '{{alertSms}}' },
  { 'id': 'alertTwiml', 'title': 'Alert Twiml', 'content': '{{alertTwiml}}' },
  { 'id': 'appointmentAltText', 'title': 'Appointment Alt Text', 'content': '{{appointmentAltText}}' },
  { 'id': 'appointmentConfirmationUrlLink', 'title': 'Appointment Confirmation Url Link', 'content': '{{appointmentConfirmationUrlLink}}' },
  { 'id': 'appointmentDateHuman', 'title': 'Appointment Date Human', 'content': '{{appointmentDateHuman}}' },
  { 'id': 'appointmentDateTimeZoneHuman', 'title': 'Appointment Date Time Zone Human', 'content': '{{appointmentDateTimeZoneHuman}}' },
  { 'id': 'appointmentImageUrl', 'title': 'Appointment Image Url', 'content': '{{appointmentImageUrl}}' },
  { 'id': 'appointmentLocalDate', 'title': 'Appointment Local Date', 'content': '{{appointmentLocalDate}}' },
  { 'id': 'appointmentLocalDateTime', 'title': 'Appointment Local Date Time', 'content': '{{appointmentLocalDateTime}}' },
  { 'id': 'appointmentLocalTime', 'title': 'Appointment Local Time', 'content': '{{appointmentLocalTime}}' },
  { 'id': 'appointmentStatus', 'title': 'Appointment Status', 'content': '{{appointmentStatus}}' },
  { 'id': 'appointmentStatusChangeMessage', 'title': 'Appointment Status Change Message', 'content': '{{appointmentStatusChangeMessage}}' },
  { 'id': 'appointmentTimeHuman', 'title': 'Appointment Time Human', 'content': '{{appointmentTimeHuman}}' },
  { 'id': 'appointmentTimeZoneAbbreviation', 'title': 'Appointment Time Zone Abbreviation', 'content': '{{appointmentTimeZoneAbbreviation}}' },
  { 'id': 'appointmentTitleText', 'title': 'Appointment Title Text', 'content': '{{appointmentTitleText}}' },
  { 'id': 'atOpCalendarBasePath', 'title': 'At Op Calendar Base Path', 'content': '{{atOpCalendarBasePath}}' },
  { 'id': 'atOpCalendarData', 'title': 'At Op Calendar Data', 'content': '{{atOpCalendarData}}' },
  { 'id': 'atOpCalendarFullPath', 'title': 'At Op Calendar Full Path', 'content': '{{atOpCalendarFullPath}}' },
  { 'id': 'atOpCareHtml', 'title': 'At Op Care Html', 'content': '{{atOpCareHtml}}' },
  { 'id': 'atOpCareShortSms', 'title': 'At Op Care Short Sms', 'content': '{{atOpCareShortSms}}' },
  { 'id': 'atOpCareSms', 'title': 'At Op Care Sms', 'content': '{{atOpCareSms}}' },
  { 'id': 'calendarBasePath', 'title': 'Calendar Base Path', 'content': '{{calendarBasePath}}' },
  { 'id': 'calendarData', 'title': 'Calendar Data', 'content': '{{calendarData}}' },
  { 'id': 'calendarFullPath', 'title': 'Calendar Full Path', 'content': '{{calendarFullPath}}' },
  { 'id': 'calendarUid', 'title': 'Calendar Uid', 'content': '{{calendarUid}}' },
  { 'id': 'cancelledEmail', 'title': 'Cancelled Email', 'content': '{{cancelledEmail}}' },
  { 'id': 'cancelledSms', 'title': 'Cancelled Sms', 'content': '{{cancelledSms}}' },
  { 'id': 'emailManageSubscriptionsUrlLink', 'title': 'Email Manage Subscriptions Url Link', 'content': '{{emailManageSubscriptionsUrlLink}}' },
  { 'id': 'emailSubjectLine', 'title': 'Email Subject Line', 'content': '{{emailSubjectLine}}' },
  { 'id': 'emailUnsubscribeUrlLink', 'title': 'Email Unsubscribe Url Link', 'content': '{{emailUnsubscribeUrlLink}}' },
  { 'id': 'feedbackUrlLink', 'title': 'Feedback Url Link', 'content': '{{feedbackUrlLink}}' },
  { 'id': 'htmlAmenityText', 'title': 'Html Amenity Text', 'content': '{{htmlAmenityText}}' },
  { 'id': 'internalEvalSurveyUrl', 'title': 'Internal Eval Survey Url', 'content': '{{internalEvalSurveyUrl}}' },
  { 'id': 'locationAddress', 'title': 'Location Address', 'content': '{{locationAddress}}' },
  { 'id': 'locationAddressLineOne', 'title': 'Location Address Line One', 'content': '{{locationAddressLineOne}}' },
  { 'id': 'locationAddressLineTwo', 'title': 'Location Address Line Two', 'content': '{{locationAddressLineTwo}}' },
  { 'id': 'locationAddressNewLine', 'title': 'Location Address New Line', 'content': '{{locationAddressNewLine}}' },
  { 'id': 'locationAddressNoZip', 'title': 'Location Address No Zip', 'content': '{{locationAddressNoZip}}' },
  { 'id': 'locationEmail', 'title': 'Location Email', 'content': '{{locationEmail}}' },
  { 'id': 'locationFaxNumber', 'title': 'Location Fax Number', 'content': '{{locationFaxNumber}}' },
  { 'id': 'locationName', 'title': 'Location Name', 'content': '{{locationName}}' },
  { 'id': 'locationPhone', 'title': 'Location Phone', 'content': '{{locationPhone}}' },
  { 'id': 'locationPhoneNoSpaces', 'title': 'Location Phone No Spaces', 'content': '{{locationPhoneNoSpaces}}' },
  { 'id': 'locationPhoneNumber', 'title': 'Location Phone Number', 'content': '{{locationPhoneNumber}}' },
  { 'id': 'locationSignature', 'title': 'Location Signature', 'content': '{{locationSignature}}' },
  { 'id': 'locationWebShortUrl', 'title': 'Location Web Short Url', 'content': '{{locationWebShortUrl}}' },
  { 'id': 'locationWebUrl', 'title': 'Location Web Url', 'content': '{{locationWebUrl}}' },
  { 'id': 'messageTitle', 'title': 'Message Title', 'content': '{{messageTitle}}' },
  { 'id': 'noShowEmail', 'title': 'No Show Email', 'content': '{{noShowEmail}}' },
  { 'id': 'noShowShortSms', 'title': 'No Show Short Sms', 'content': '{{noShowShortSms}}' },
  { 'id': 'noShowSms', 'title': 'No Show Sms', 'content': '{{noShowSms}}' },
  { 'id': 'pathAuthor', 'title': 'Path Author', 'content': '{{pathAuthor}}' },
  { 'id': 'pathCountReport', 'title': 'Path Count Report', 'content': '{{pathCountReport}}' },
  { 'id': 'pathCountReportShort', 'title': 'Path Count Report Short', 'content': '{{pathCountReportShort}}' },
  { 'id': 'pathStartDate', 'title': 'Path Start Date', 'content': '{{pathStartDate}}' },
  { 'id': 'pathologyAltText', 'title': 'Pathology Alt Text', 'content': '{{pathologyAltText}}' },
  { 'id': 'pathologyImageUrl', 'title': 'Pathology Image Url', 'content': '{{pathologyImageUrl}}' },
  { 'id': 'pathologyTitleText', 'title': 'Pathology Title Text', 'content': '{{pathologyTitleText}}' },
  { 'id': 'patientFirstName', 'title': 'Patient First Name', 'content': '{{patientFirstName}}' },
  { 'id': 'postOpCalendarBasePath', 'title': 'Post Op Calendar Base Path', 'content': '{{postOpCalendarBasePath}}' },
  { 'id': 'postOpCalendarData', 'title': 'Post Op Calendar Data', 'content': '{{postOpCalendarData}}' },
  { 'id': 'postOpCalendarFullPath', 'title': 'Post Op Calendar Full Path', 'content': '{{postOpCalendarFullPath}}' },
  { 'id': 'postOpCareHtml', 'title': 'Post Op Care Html', 'content': '{{postOpCareHtml}}' },
  { 'id': 'postOpCareShortSms', 'title': 'Post Op Care Short Sms', 'content': '{{postOpCareShortSms}}' },
  { 'id': 'postOpCareSms', 'title': 'Post Op Care Sms', 'content': '{{postOpCareSms}}' },
  { 'id': 'ppointmentUpdateText', 'title': 'Ppointment Update Text', 'content': '{{ppointmentUpdateText}}' },
  { 'id': 'practiceWebSiteAltText', 'title': 'Practice Web Site Alt Text', 'content': '{{practiceWebSiteAltText}}' },
  { 'id': 'practiceWebSiteImageUrl', 'title': 'Practice Web Site Image Url', 'content': '{{practiceWebSiteImageUrl}}' },
  { 'id': 'practiceWebSiteTitleText', 'title': 'Practice Web Site Title Text', 'content': '{{practiceWebSiteTitleText}}' },
  { 'id': 'practiceWebSiteUrl', 'title': 'Practice Web Site Url', 'content': '{{practiceWebSiteUrl}}' },
  { 'id': 'practitionerName', 'title': 'Practitioner Name', 'content': '{{practitionerName}}' },
  { 'id': 'practitionerNameNoFixup', 'title': 'Practitioner Name No Fixup', 'content': '{{practitionerNameNoFixup}}' },
  { 'id': 'practitionerNamePrefix', 'title': 'Practitioner Name Prefix', 'content': '{{practitionerNamePrefix}}' },
  { 'id': 'practitionerNameSuffix', 'title': 'Practitioner Name Suffix', 'content': '{{practitionerNameSuffix}}' },
  { 'id': 'practitionerThankYouAltText', 'title': 'Practitioner Thank You Alt Text', 'content': '{{practitionerThankYouAltText}}' },
  { 'id': 'practitionerThankYouImageUrl', 'title': 'Practitioner Thank You Image Url', 'content': '{{practitionerThankYouImageUrl}}' },
  { 'id': 'practitionerThankYouTitleText', 'title': 'Practitioner Thank You Title Text', 'content': '{{practitionerThankYouTitleText}}' },
  { 'id': 'preOpCalendarBasePath', 'title': 'Pre Op Calendar Base Path', 'content': '{{preOpCalendarBasePath}}' },
  { 'id': 'preOpCalendarData', 'title': 'Pre Op Calendar Data', 'content': '{{preOpCalendarData}}' },
  { 'id': 'preOpCalendarFullPath', 'title': 'Pre Op Calendar Full Path', 'content': '{{preOpCalendarFullPath}}' },
  { 'id': 'preOpCareHtml', 'title': 'Pre Op Care Html', 'content': '{{preOpCareHtml}}' },
  { 'id': 'preOpCareSms', 'title': 'Pre Op Care Sms', 'content': '{{preOpCareSms}}' },
  { 'id': 'preOpShortSms', 'title': 'Pre Op Short Sms', 'content': '{{preOpShortSms}}' },
  { 'id': 'pressFourToConnectToOfficeTwiml', 'title': 'Press Four To Connect To Office Twiml', 'content': '{{pressFourToConnectToOfficeTwiml}}' },
  { 'id': 'pressNineToUnsubscribeTwiml', 'title': 'Press Nine To Unsubscribe Twiml', 'content': '{{pressNineToUnsubscribeTwiml}}' },
  { 'id': 'publicEvalSurveyUrl', 'title': 'Public Eval Survey Url', 'content': '{{publicEvalSurveyUrl}}' },
  { 'id': 'reviewEmail', 'title': 'Review Email', 'content': '{{reviewEmail}}' },
  { 'id': 'reviewShortSms', 'title': 'Review Short Sms', 'content': '{{reviewShortSms}}' },
  { 'id': 'reviewSms', 'title': 'Review Sms', 'content': '{{reviewSms}}' },
  { 'id': 'smsAmenityText', 'title': 'Sms Amenity Text', 'content': '{{smsAmenityText}}' },
  { 'id': 'smsEmailHelpTrailerText', 'title': 'Sms Email Help Trailer Text', 'content': '{{smsEmailHelpTrailerText}}' },
  { 'id': 'smsEmailUnsubscribeManagePreferencesText', 'title': 'Sms Email Unsubscribe Manage Preferences Text', 'content': '{{smsEmailUnsubscribeManagePreferencesText}}' },
  { 'id': 'smsHelpTrailerText', 'title': 'Sms Help Trailer Text', 'content': '{{smsHelpTrailerText}}' },
  { 'id': 'smsPatientHelpText', 'title': 'Sms Patient Help Text', 'content': '{{smsPatientHelpText}}' },
  { 'id': 'smsShortHelpTrailerText', 'title': 'Sms Short Help Trailer Text', 'content': '{{smsShortHelpTrailerText}}' },
  { 'id': 'smsShortPatientHelpText', 'title': 'Sms Short Patient Help Text', 'content': '{{smsShortPatientHelpText}}' },
  { 'id': 'socialMediaInstagramAltText', 'title': 'Social Media Instagram Alt Text', 'content': '{{socialMediaInstagramAltText}}' },
  { 'id': 'socialMediaInstagramImageUrl', 'title': 'Social Media Instagram Image Url', 'content': '{{socialMediaInstagramImageUrl}}' },
  { 'id': 'socialMediaInstagramTitleText', 'title': 'Social Media Instagram Title Text', 'content': '{{socialMediaInstagramTitleText}}' },
  { 'id': 'socialMediaInstagramUrl', 'title': 'Social Media Instagram Url', 'content': '{{socialMediaInstagramUrl}}' },
  { 'id': 'socialMediaLinkedInAltText', 'title': 'Social Media Linked In Alt Text', 'content': '{{socialMediaLinkedInAltText}}' },
  { 'id': 'socialMediaLinkedInImageUrl', 'title': 'Social Media Linked In Image Url', 'content': '{{socialMediaLinkedInImageUrl}}' },
  { 'id': 'socialMediaLinkedInTitleText', 'title': 'Social Media Linked In Title Text', 'content': '{{socialMediaLinkedInTitleText}}' },
  { 'id': 'socialMediaLinkedInUrl', 'title': 'Social Media Linked In Url', 'content': '{{socialMediaLinkedInUrl}}' },
  { 'id': 'socialMediaTwitterAltText', 'title': 'Social Media Twitter Alt Text', 'content': '{{socialMediaTwitterAltText}}' },
  { 'id': 'socialMediaTwitterImageUrl', 'title': 'Social Media Twitter Image Url', 'content': '{{socialMediaTwitterImageUrl}}' },
  { 'id': 'socialMediaTwitterTitleText', 'title': 'Social Media Twitter Title Text', 'content': '{{socialMediaTwitterTitleText}}' },
  { 'id': 'socialMediaTwitterUrl', 'title': 'Social Media Twitter Url', 'content': '{{socialMediaTwitterUrl}}' },
  { 'id': 'timeZoneName', 'title': 'Time Zone Name', 'content': '{{timeZoneName}}' },
  { 'id': 'todayHumanDate', 'title': 'Today Human Date', 'content': '{{todayHumanDate}}' },
  { 'id': 'welcomeAltText', 'title': 'Welcome Alt Text', 'content': '{{welcomeAltText}}' },
  { 'id': 'welcomeImageUrl', 'title': 'Welcome Image Url', 'content': '{{welcomeImageUrl}}' },
  { 'id': 'welcomeTitleText', 'title': 'Welcome Title Text', 'content': '{{welcomeTitleText}}' }
].map( t => ( { ...t, description: `Insert the "${ t.title }" field code into the message.` } ) );

// https://developer.amazon.com/en-US/docs/alexa/custom-skills/speech-synthesis-markup-language-ssml-reference.html
export const ssmlTags = [
  { title: 'Break', content: '<break time="3s"/> ' },
  { title: 'Say number as cardinal', content: '<say-as interpret-as="cardinal">12345 </say-as> ' },
  { title: 'Say number as digits', content: '<say-as interpret-as="digits">12345 </say-as> ' },
  { title: 'Say date as Month-Day', content: '<say-as interpret-as="date" format="md">2022-04-12 </say-as> ' },
  { title: 'Say telephone', content: '<say-as interpret-as="telephone" format="md">212-555-1212 </say-as> ' },
  { title: 'Say address', content: '<say-as interpret-as="address" format="md">123 Main St, Topeka, KS </say-as> ' },

  // voice <voice name="Kendra">I am not a real human.</voice>
  // amazon:domain      <amazon:domain name="conversational">
  // amazon:effect  whispered
  // amazon:emotion  name excited/disappointed  intensity low/medium/high
  // audio  <audio src="soundbank://soundlibrary/transportation/amzn_sfx_car_accelerate_01" />
  // break  <break time="3s"/>
  // emphasis <emphasis level="strong">really like</emphasis>   strong/moderate/reduced
  // lang <lang xml:lang="fr-FR">Paris</lang>
  // p <p>This is the first paragraph. There should be a pause after this text is spoken.</p>
  // phoneme <phoneme alphabet="ipa" ph="ˈpi.kæn">pecan</phoneme>
  // prosody <prosody rate="x-slow">I speak quite slowly</prosody>
  // s   <s>This is a sentence</s>
  // say-as <say-as interpret-as="cardinal">12345</say-as>
  // <say-as interpret-as="digits">12345</say-as>
  // spell-out: Spell out each letter.
  // cardinal, number: Interpret the value as a cardinal number.
  // ordinal: Interpret the value as an ordinal number.
  // digits: Spell each digit separately .
  // fraction: Interpret the value as a fraction. This works for both common fractions (such as 3/20) and mixed fractions (such as 1+1/2).
  // unit: Interpret a value as a measurement. The value should be either a number or fraction followed by a unit (with no space in between) or just a unit.
  // date: Interpret the value as a date. Specify the format with the format attribute.
  // time: Interpret a value such as 1'21" as duration in minutes and seconds.
  // telephone: Interpret a value as a 7-digit or 10-digit telephone number. This can also handle extensions (for example, 2025551212x345).
  // address: Interpret a value as part of street address.

  // sub <sub alias="aluminum">Al</sub>

  // w
  // amazon:VB: Interpret the word as a verb (present simple).
  //    <w role="amazon:VB">read</w>
  // amazon:VBD: Interpret the word as a past participle.
  //   <w role="amazon:VBD">read</w>
  // amazon:NN: Interpret the word as a noun.
  // amazon:SENSE_1: Use the non-default sense of the word. bass: sound v fish
  //  <w role="amazon:SENSE_1">bass</w>

].map( t => ( { ...t, description: `Insert the "${ t.title }" tag into the message.` } ) );


// const MessageTemplateListPanel: FC<Partial<{ basePath: string; id: Identifier; record: RaRecord; resource: string; }>> = ( { record } ) => (
//   <div dangerouslySetInnerHTML={ { __html: '<SmsMessageField source="smsMessage" label="SMS Message" />' } } />
// );

// const MessageTemplateListTitle: FC<TitleProps> = props => {
//   return <span>ReminderTemplateList { props.record?.name ? `"${ props.record.name }"` : '' }</span>;
// };

const MessageTemplateFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SelectInput source="recipientType" choices={ choices.recipientType } alwaysOn />
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const MessageTemplateList: FC<ListProps> = props => {
  useAdminListAppLocation();
  return (
    ( <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
      filters={ <MessageTemplateFilter /> }
      sort={ { field: 'name', order: 'ASC' } }
    >
      <Datagrid
        rowClick="show"
        // expand={ <MessageTemplateExpandEdit /> }
        bulkActionButtons={ false }
      >
        <SelectField source="recipientType" choices={ choices.recipientType } />
        <TextField source="name" />
        <SelectField source="messagePurpose" choices={ choices.messagePurpose } />
        <FunctionField source="ssmlMessage" label="Has any disable"
          render={ ( record ): ReactElement | null => {
            const disables = resourceMap.messagetemplates.attributes.filter( a => !!a.match( /^disable/ ) );
            if( disables.filter( d => _.get( record, d, false ) ).length ) {
              return <CheckIcon />;
            }
            return null;
          } } />
        <EditButton />
      </Datagrid>
    </List> )
  );
}


export const MessageTemplateShow: FC<ShowProps> = props => {
  const theme = useTheme();
  const { data: _apiLangs, isLoading: isLangsLoading } = useGetList<LangApi>( 'languages', {
    pagination: { perPage: 200, page: 1 },
    sort: { field: 'name', order: 'ASC' },
    filter: { isSupported: true },
  } );
  const [ sms, setSms ] = useState( '' );
  const [ smsShort, setSmsShort ] = useState( '' );
  const [ subject, setSubject ] = useState( '' );
  const [ lang, setLang ] = useState<string>( 'en' );
  const [ voiceText, setVoiceText ] = useState( '' );

  const fetchPreview = useCallback( async ( id: Identifier, format: string ) => {
    return await httpClient( `${ apiUrl }/preview/messagetemplates/${ id }/${ format }${ lang == 'en' ? '' : `?lang=${ lang }` }` );
  }, [ lang ] );

  useEffect( () => {
    ( async () => {
      const saved = localStorage.getItem( 'lang' );
      // if( !saved || lang == saved ) return;
      setLang( saved || 'en' );
    } )()
  }, [ lang, setLang ] );

  const langChange: LanguageSelectProps[ 'onChange' ] = ( e ) => {
    setLang( e.target.value );
    localStorage.setItem( 'lang', e.target.value || 'en' );
  }

  return (
    ( <Show
      actions={ <ShowActionsWithReset
        buttons={ isLangsLoading
          ? <CircularProgress size='small' />
          : <LanguageSelect onChange={ langChange } lang={ lang } />
        } /> }
      // actions={ <ShowActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
      // title={ <MessageTemplateListTitle /> }
      { ...props }>
      <AdminShowLayout>
        <TextField source="name" />

        <WithRecord render={ record => {

          useEffect( () => {
            ( async () => {
              if( !record.smsMessage ) {
                setSms( '' );
                return;
              }
              const { body: sms } = await fetchPreview( record.id, 'sms' );
              setSms( sms );
            } )();
          }, [ record.id, record.smsMessage, setSms, lang ] );

          useEffect( () => {
            ( async () => {
              if( !record.smsShortMessage ) {
                setSmsShort( '' );
                return;
              }
              const { body: sms } = await fetchPreview( record.id, 'smsShort' );
              setSmsShort( sms );
            } )();
          }, [ record.id, record.smsShortMessage, setSmsShort, lang ] );

          useEffect( () => {
            ( async () => {
              if( !record.emailSubjectLine ) {
                setSubject( '' );
                return;
              }
              const { body: subject } = await fetchPreview( record.id, 'subject' );
              setSubject( subject );
            } )();
          }, [ record.id, record.emailSubjectLine, setSubject, lang ] );

          useEffect( () => {
            ( async () => {
              if( !record.twimlStart ) {
                setVoiceText( '' );
                return;
              }
              const { body: voice } = await fetchPreview( record.id, 'voice.txt' );
              setVoiceText( voice );
            } )();
          }, [ record.id, record.smsMessage, setSms, lang ] );

          return (
            ( <Grid container spacing={ 6 }>
              <Grid key='email' item xs={ 'auto' }>
                <Stack>
                  <Labeled label='Email Subject' >
                    <Box
                      sx={ {
                        // minHeight: '3rem',
                        marginTop: 0,
                        padding: '1rem',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: theme.palette.divider,
                        overflowWrap: 'break-word',
                        '& .MuiTypography-root': {
                          fontFamily: 'monospace',
                          fontSize: '90%',
                        },
                      } }
                    >
                      { subject } &nbsp;
                    </Box>
                  </Labeled>
                  <Labeled label='Email Preview' sx={ { marginTop: 2 } } >
                    <Box sx={ {
                      //margin: '0 10rem 2rem'
                    } }
                    >

                      <iframe
                        width={ 600 }
                        height={ 1200 }
                        seamless
                        src={ `${ apiUrl }/preview/messagetemplates/${ record.id }/email${ lang == 'en' ? '' : `?lang=${ lang }` }` }
                        style={ {
                          borderWidth: '1px',
                          borderStyle: 'solid',
                          borderColor: theme.palette.divider,
                        } }
                      />

                    </Box>
                  </Labeled>
                </Stack>
              </Grid>
              <Grid key='sms' item xs={ 5 }>
                <Stack>
                  <Labeled label='Short SMS Preview' >
                    <Box
                      sx={ {
                        minHeight: '10rem',
                        marginTop: 0,
                        padding: '1rem',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: theme.palette.divider,
                        overflowWrap: 'break-word',
                        '& .MuiTypography-root': {
                          fontFamily: 'monospace',
                          fontSize: '90%',
                        },
                      } }
                    >
                      { smsShort.split( /\n/ ).map( ( s, key ) => <Typography key={ key }> { s || ( <span>&nbsp;</span> ) } </Typography> ) }
                    </Box>
                  </Labeled>
                  <Labeled label='SMS Preview' sx={ { marginTop: 6 } } >
                    <Box
                      sx={ {
                        minHeight: '10rem',
                        marginTop: 0,
                        padding: '1rem',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: theme.palette.divider,
                        overflowWrap: 'break-word',
                        '& .MuiTypography-root': {
                          fontFamily: 'monospace',
                          fontSize: '90%',
                        },
                      } }
                    >
                      { sms.split( /\n/ ).map( ( s, key ) => <Typography key={ key }> { s || ( <span>&nbsp;</span> ) } </Typography> ) }
                    </Box>
                  </Labeled>
                  { /* </Grid> <Grid item> */ }
                  <Labeled label='Voice Preview' sx={ { marginTop: 6 } }>
                    <Box >
                      { record.twimlStart
                        ?
                        <ReactAudioPlayer
                          title={ record.name }
                          src={ `${ apiUrl }/preview/messagetemplates/${ record.id }/voice.mp3${ lang == 'en' ? '' : `?lang=${ lang }` }` }
                          controls
                        />
                        : <Typography>
                          None configured
                        </Typography>
                      }
                    </Box>
                  </Labeled>
                  <Box
                    sx={ {
                      minHeight: '10rem',
                      marginTop: 0,
                      padding: '1rem',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: theme.palette.divider,
                      overflowWrap: 'break-word',
                      '& .MuiTypography-root': {
                        // fontFamily: 'monospace',
                        fontSize: '90%',
                      },
                    } }
                  >
                    { voiceText.split( /\n/ ).map( ( s, key ) => <Typography key={ key }> { s || ( <span>&nbsp;</span> ) } </Typography> ) }
                  </Box>
                </Stack>
              </Grid>
            </Grid> )
          );
        } } />


        { /*
             <SmsMessageField source="smsMessage" label="SMS Message" />
             <TextField source="emailSubjectLine" label="Email Subject" />
             <HtmlViewer source="htmlMessage" label="Email Message"  />
             <HtmlViewer source="ssmlMessage" label="Voice Message"  />

             <BooleanField source='requestAppointmentConfirmation' looseValue />

             <BooleanField source='enableEmail' looseValue />
             <BooleanField source='enableSms' looseValue />
             <BooleanField source='enableVoice' looseValue />
             <BooleanField source='enableVoiceCallTransfers' looseValue />
             <SelectField source='smsMessageLength' choices={ choices.smsMessageLength } />

             <SelectField source='messagePriority' choices={ choices.messagePriority } />
             <SelectField source="preferredCommMode" choices={ choices.preferredCommMode } />
           */}
      </AdminShowLayout>
    </Show > )
  );
}

const setup: EditorSetup = ( editor ) => {
  editor.ui.registry.addMenuButton( 'variables', {
    text: 'Add variable',
    fetch: ( callback ) => {
      callback( templates.map( t => ( {
        type: 'menuitem',
        text: t.title,
        onAction: () => {
          // const bm = editor.selection.getBookmark();
          editor.insertContent( t.content );
          // editor.selection.moveToBookmark( bm );
          // editor.selection.setCursorLocation(); // undefined, t.content.length );
        }
      } ) ) );
    }
  } );
  editor.ui.registry.addMenuButton( 'ssml', {
    text: 'Add ssml markup',
    fetch: ( callback ) => {
      callback( ssmlTags.map( t => ( {
        type: 'menuitem',
        text: t.title,
        onAction: () => {
          editor.insertContent( t.content );
        }
      } ) ) );
    }
  } );

}

export const editorConfig: Record<string, Partial<HtmlEditorProps>> = {
  htmlMessage: {
    plugins: [
      'advlist', 'autolink', 'autoresize', 'lists', 'link',
      'searchreplace', 'code', 'emoticons', // 'paste',
    ],
    toolbar: [
      'undo redo | formatselect | ' +
      'bold italic backcolor | bullist numlist outdent indent | ' +
      'removeformat | emoticons code',
      'variables',
    ],
    setup,
  },
  smsMessage: {
    // https://www.tiny.cloud/docs/configure/advanced-editing-behavior/#br_in_pre
    // br_in_pre: true, // Creates multiple <pre></pre> blocks on <Enter>. Since <S-Enter> still adds <br>, convert those too
    format: ( value: string ): string => value.split( '\n' ).map( s => `<p>${ s }</p>` ).join( '' ),
    parse: ( value: string ): string => value.replaceAll( /(<p(re)?>)(&nbsp;)+(<\p(re)?>)/gi, '$1$3' ).replaceAll( /<\/p(re)?\s*>\s*<p(re)?\s*>/g, '\n' ).replaceAll( /<\/?p(re)?\s*>/g, '' ).replaceAll( /<br\s*\/?>/g, '\n' ),
    plugins: [ 'autoresize', 'code' ],
    toolbar: 'variables', // code
    setup,
  },
  ssmlMessage: {
    format: ( value: string ): string => `<speak>${ value }</speak>`,
    parse: ( value: string ): string => value.replaceAll( /<speak\s*>/g, '' ).replaceAll( /<\/speak\s*>/g, ' ' ),
    plugins: [ 'autoresize', 'code' ],
    toolbar: 'ssml code',
    setup,
    disabled: true,  // TODO - Tiny is trashing the twiml code, need complete re-do
  },
};

const MessageTemplateEmailForm: FC = () => {
  const record = useRecordContext();
  useDefineAppLocation( 'admin.messagetemplates.edit.email', { record } );
  const { data: components, isLoading } = useGetList( 'htmlcomponents', { pagination: { page: 1, perPage: 1000 } } );
  const allChoices = useMemo( () => components?.map( c => ( { id: c.id, name: c.id } ) ) || [], [ components ] );
  const sectionChoices = useMemo( () => allChoices.filter( c => !c.id.match( /^htmlPresentation/ ) ), [ allChoices ] );
  const componentChoices = useMemo( () => allChoices.filter( c => c.id.match( /^htmlPresentation/ ) ), [ allChoices ] );

  // if( isLoading ) return <CircularProgress />;

  return (

    <Form
      record={ record }
      sanitizeEmptyValues
      warnWhenUnsavedChanges
    >


      <Grid item container spacing={ 2 } >

        <Grid item xs={ 12 }  >
          <TextInput source="emailSubjectLine" label="Email Subject" />
        </Grid>

      </Grid>

      <SectionHeader sectionName='HTML body assembly' />

      <Grid item container spacing={ 'auto' }  >
        { isLoading
          ? <CircularProgress />
          : <ArrayInput source='assemblies' label={ false }>
            <SimpleFormIterator
              inline

              getItemLabel={ index => `Section ${ index + 1 }` }
              addButton={ <AddItemButton label='section' /> }
              removeButton={ <RemoveItemButton label='section' /> }
              reOrderButtons={ <ReOrderButtons label='section' /> }
              disableClear
            >
              <AutocompleteInput label="Name" source="id" choices={ sectionChoices }
                sx={ {
                  minWidth: '20rem',
                  // border: 'solid red 1px',
                } }
                isRequired
              />

              <ArrayInput source='components'
                sx={ {
                  // border: 'solid blue 1px',
                  width: 'inherit',
                  // paddingTop: '4rem',
                } }
              >
                <SimpleFormIterator
                  inline
                  getItemLabel={ index => `Component ${ index + 1 }` }
                  addButton={ <AddItemButton label='component' /> }
                  removeButton={ <RemoveItemButton label='component' /> }
                  reOrderButtons={ <ReOrderButtons label='component' /> }
                  disableClear
                >
                  <AutocompleteInput label="Name" source="id" choices={ componentChoices }
                    sx={ {
                      minWidth: '25rem',
                      // border: 'solid red 1px',
                    } }
                    isRequired
                  />
                </SimpleFormIterator>
              </ArrayInput>

            </SimpleFormIterator>
          </ArrayInput>
        }
      </Grid >
      <CustomToolbar />
    </Form>
  );
}

const MessageTemplateSmsForm: FC = () => {
  const record = useRecordContext();
  useDefineAppLocation( 'admin.messagetemplates.edit.sms', { record } );

  return (
    <Form
      record={ record }
      sanitizeEmptyValues
      warnWhenUnsavedChanges
    >

      <Grid item container spacing={ 2 }>
        { /*
             <Grid item sx={ { display: !formData.enableSms ? 'none' : undefined } }>
             <BooleanInput label='Send as MMS' source='sendAsMms' disabled={ !formData.enableSms } />
             </Grid>
           */ }
        <Grid item xs={ 12 } md={ 6 }  >
          <TextInput
            label='SMS Message'
            source='smsMessage'
            multiline
            minRows={ 8 }

          />
        </Grid>
        <Grid item xs={ 12 } md={ 6 }  >
          <TextInput
            source='smsShortMessage'
            label='Short SMS Message'
            multiline
            minRows={ 8 }

          />

        </Grid>
      </Grid>

      <CustomToolbar />
    </Form>
  );
}

const MessageTemplateVoiceForm: FC = () => {
  const record = useRecordContext();
  useDefineAppLocation( 'admin.messagetemplates.edit.voice', { record } );

  return (
    <Form
      record={ record }
      sanitizeEmptyValues
      warnWhenUnsavedChanges
    >

      <Grid item container spacing={ 2 } >

        <Grid item xs={ 12 }  >
          <ReferenceInput source="twimlStart" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>

        <Grid item xs={ 6 } >
          <ReferenceInput source="twimlMenu" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={ 6 } >
          <ReferenceInput source="twimlAnsweringMachine" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={ 6 } >
          <ReferenceInput source="twimlDigit0" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={ 6 }  >
          <ReferenceInput source="twimlDigit1" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={ 6 } >
          <ReferenceInput source="twimlDigit2" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={ 6 } >
          <ReferenceInput source="twimlDigit3" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={ 6 } >
          <ReferenceInput source="twimlDigit4" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={ 6 } >
          <ReferenceInput source="twimlDigit5" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={ 6 } >
          <ReferenceInput source="twimlDigit6" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={ 6 } >
          <ReferenceInput source="twimlDigit7" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={ 6 } >
          <ReferenceInput source="twimlDigit8" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
        <Grid item xs={ 6 } >
          <ReferenceInput source="twimlDigit9" reference='twimls' perPage={ 100 } >
            <SelectInput source='id' />
          </ReferenceInput>
        </Grid>
      </Grid>
      <CustomToolbar />
    </Form>
  );
}

export const ChannelPrioritesInput: FC<InputProps> = () => {
  const record = useRecordContext();

  useEffect( () => {
    if( !record ) return;
    record.priorities = getPriorities( record );
  }, [ record ] );

  interface PrioritiesRecord {
    'priorityEmail': number;
    'prioritySms': number;
    'priorityVoice': number;
  }
  // type PriorityChannel = ( 'Email' | 'SMS' | 'Voice' )[];
  const getPriorities = useCallback( ( data: unknown ) => {
    const { priorityEmail, prioritySms, priorityVoice } = data as PrioritiesRecord;
    return sortBy( [
      { id: 'priorityEmail', name: 'Email', value: priorityEmail },
      { id: 'prioritySMS', name: 'SMS', value: prioritySms },
      { id: 'priorityVoice', name: 'Voice', value: priorityVoice },
    ], p => p.value );
  }, [] );

  return (
    <Box>
      <MuiList>
        <Draggable>
          { getPriorities( record ).map( p => (
            <ListItem key={ p.id }>
              <ListItemText sx={ { width: '100%' } } >{ p.name }</ListItemText>
              <ListItemIcon>
                <DragIndicator />
              </ListItemIcon>
            </ListItem>
          ) ) }
        </Draggable>
      </MuiList>
    </Box>
  );
}

export const MessageTemplateEmailEdit: FC<EditProps> = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Edit
      { ...props }
      actions={ <></> }
      mutationMode='pessimistic'
      mutationOptions={ {
        onSuccess: () => {
          notify( 'ra.notification.updated', { messageArgs: { smart_count: 1 } } );
          redirect( false );
          window.scrollTo( 0, 0 );
        }
      } }
    >
      <MessageTemplateEmailForm />
    </Edit>
  );
};

export const MessageTemplateSmsEdit: FC<EditProps> = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Edit
      { ...props }
      actions={ <></> }
      mutationMode='pessimistic'
      mutationOptions={ {
        onSuccess: () => {
          notify( 'ra.notification.updated', { messageArgs: { smart_count: 1 } } );
          redirect( false );
          window.scrollTo( 0, 0 );
        }
      } }
    >
      <MessageTemplateSmsForm />
    </Edit>
  );
};

export const MessageTemplateVoiceEdit: FC<EditProps> = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Edit
      { ...props }
      actions={ <></> }
      mutationMode='pessimistic'
      mutationOptions={ {
        onSuccess: () => {
          notify( 'ra.notification.updated', { messageArgs: { smart_count: 1 } } );
          redirect( false );
          window.scrollTo( 0, 0 );
        }
      } }
    >
      <MessageTemplateVoiceForm />
    </Edit>
  );
};


export const MessageTemplateEdit: FC<EditProps> = props => {
  const basePath = 'messagetemplates';
  const baseLocation = `admin.${ basePath }`;
  const tabs = [ 'config', 'email', 'sms', 'voice' ];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ , setLocation ] = useAppLocationState();
  const [ tab, setTab ] = useState( tabs[ 0 ] );
  const { id } = useParams();
  const { data: config } = useGetOne( 'messageconfigs', { id } );
  const { enableEmail, enableSms, enableVoice } = config || {};

  const handleChange = ( _event: SyntheticEvent, newValue: string ) => {
    navigate( `edit/${ newValue }` );
    setTab( newValue );
    setLocation( `${ baseLocation }.edit.${ newValue }` );
  }

  useEffect( () => {
    const [ , , , , tab ] = pathname.split( '/' );
    if( !tabs.includes( tab ) ) return navigate( `edit/${ tabs[ 0 ] }` );
    setTab( tab );
    setLocation( `${ baseLocation }.edit.${ tab }` );
    return () => setLocation( null );
  }, [] );

  return (
    <Box>
      <Title title='Edit' />
      <TopToolbar >
        {/* <Box sx={ { flexGrow: 1 } } /> */ }
        <ShowButton record={ config } resource='messagetemplates' />
      </TopToolbar>
      <Tabs value={ tab } onChange={ handleChange } >
        <Tab disableRipple value='config' label='Config' />
        <Tab disableRipple value='email' label={ `Email ${ !enableEmail ? '(disabled)' : '' }` } />
        <Tab disableRipple value='sms' label={ `SMS ${ !enableSms ? '(disabled)' : '' }` } />
        <Tab disableRipple value='voice' label={ `Voice ${ !enableVoice ? '(disabled)' : '' }` } />
      </Tabs>
      <Card
        sx={ {
          minHeight: '25em',
        } }
      >
        <CardContent>
          <ResourceContextProvider value='messagetemplates'>
            {
              tab == 'config'
                ? <MessageConfigEdit { ...props } resource='messageconfigs' />
                : tab == 'email'
                  ? <MessageTemplateEmailEdit { ...props } />
                  : tab == 'sms'
                    ? <MessageTemplateSmsEdit { ...props } />
                    : <MessageTemplateVoiceEdit { ...props } />
            }
          </ResourceContextProvider>
        </CardContent>
      </Card>
    </Box>
  );
}
