import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { FC, useCallback, useMemo, useState, MouseEventHandler } from 'react';
import { AppBar, AppBarProps, Logout, TitlePortal, useGetList, UserMenu } from 'react-admin';
import { AppBarWarning } from './AppBarWarning';
import { DocButton } from './DocButton';
import { UnreadTextsButton } from './NewMessagesButton';
import { useHasNoEmr } from './Organizations';
import { aicBlue } from './theme';

export const CustomAppBar: FC<AppBarProps> = props => {
  const hasNoEmr = useHasNoEmr();
  const theme = useTheme();
  const isSmall = useMediaQuery( theme.breakpoints.down( 'md' ) );
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const { data } = useGetList( 'organizations', { filter: { parent: null } } );
  const [ hideOrgName, setHideOrgName ] = useState( false );

  const orgName = useMemo( () => {
    const [ org ] = data || [];
    return org?.name || '';
  }, [ data ] );

  const onClickName = useCallback<MouseEventHandler<HTMLSpanElement>>( ( e ) => {
    const { altKey, ctrlKey, metaKey, shiftKey } = e;
    if( altKey || ctrlKey || metaKey || shiftKey ) setHideOrgName( v => !v );
  }, [ setHideOrgName ] )


  return (
    <AppBar
      sx={ {
        backgroundColor: aicBlue,
        '& .user-menu .MuiButton-root': {
          color: hideOrgName ? 'transparent' : undefined,
        }
      } }
      userMenu={
        <UserMenu className='user-menu'>
          <Logout redirectTo='/auth' />
        </UserMenu>
      }
      { ...props }
    >
      <TitlePortal />
      { !isSmall &&
        <Typography
          onClick={ onClickName }
          sx={ {
            textTransform: 'uppercase', marginRight: '1rem',
            color: hideOrgName ? 'transparent' : undefined,
          } }
        >
          { orgName }
        </Typography >
      }
      { !hideOrgName &&
        <IfCanAccess resource='system' action='manage'>
          <AppBarWarning />
        </IfCanAccess>
      }
      { !isXSmall &&
        <DocButton />
      }
      { !isXSmall && !hasNoEmr && !hideOrgName &&
        <IfCanAccess resource='recipients' action='read'>
          <UnreadTextsButton />
        </IfCanAccess>
      }

    </AppBar >
  );
}

