// import spanishMessages from '@blackbox-vision/ra-language-spanish'; // '@blackbox-vision/ra-language-spanish';
import { buildI18nProvider, defaultMessages, RaEnterpriseTranslationMessages } from '@react-admin/ra-enterprise';
import { raFormLayoutLanguageEnglish, raFormLayoutLanguageFrench } from '@react-admin/ra-form-layout';
import { raRelationshipsLanguageEnglish, raRelationshipsLanguageFrench } from '@react-admin/ra-relationships';
import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';

const local = {
  'Invalid state': 'Invalid authentication state',


  app: {
    confirmation: {
      action: {
        appointments: {
          reloadHistorical: 'Requested resync of the historical appointments. Refresh page shortly for results.',
        },
      },
      add: {
        reviewSiteUrl: 'Review site url added.',
        clipboard: 'Copied to clipboard.',
      },
      delete: {
        recipient: 'Recipient deleted',
        contactMethod: 'Deleted "%{formatted}"',
      },
      report: {
        rerun: 'Finished re-running the report',
        send: 'Sending the report',
      },
      value: {
        activated: 'Activated "%{formatted}"',
        deactivated: 'Deactivated "%{formatted}"',
        auto: 'Activate "%{formatted}"',
        use: 'Activate "%{formatted}"',
        ignore: 'Deactivate "%{formatted}"',
        reset: 'Reset "%{formatted}"',
      },
      unsubscribe: {
        allContactMethods: 'All contact methods unsubscribed',
        allRecipients: 'All recipients unsubscribed',
      },
      test: {
        contactMethod: { // app.confirmation.test.contactMethod.success
          success: 'Sent test to "%{formatted}"',
          error: 'Sent test to "%{formatted}": %{error}',
        },
      }
    },
    label: {
      read: 'Read',
      unread: 'Unread',
      all: 'All',
    },
    validation: { // app.validation.phone_invalid
      phone_invalid: 'Must be a valid phone number',
      nodupes: 'There should be no duplicates',
    }
  }
}

const english = { ...defaultMessages.en, ...englishMessages, ...raFormLayoutLanguageEnglish, ...raRelationshipsLanguageEnglish, ...local };

const resources = {
  es: {
    resources: {
      asset: { name: 'activo |||| activos' },
      text: { name: 'activo |||| activos' },
      facility: { name: 'complejo |||| complejos' },
      image: { name: 'imagen |||| imágenes' },
      provider: { name: 'proveedor |||| proveedores' },
      restaurant: { name: 'restaurante |||| restaurantes' },
      salon: { name: 'peluquería |||| peluquerías' },
      shop: { name: 'tienda |||| tiendas' },
      tag: { name: 'etiqueta |||| etiquetas' },
    }
  },
  fr: {
    resources: {
      asset: { name: 'biens |||| actifs' },
      text: { name: 'biens |||| actifs' },
      facility: { name: 'établissement |||| établissements' },
      image: { name: 'image |||| images' },
      provider: { name: 'fournisseur |||| fournisseurs' },
      restaurant: { name: 'restaurant |||| restaurants' },
      salon: { name: 'salon |||| salons' },
      shop: { name: 'boutique |||| boutiques' },
      tag: { name: ' étiquette' },
    }
  }
};

const messages: Record<string, RaEnterpriseTranslationMessages> = {
  'en-US': english,
  'en-UK': english,
  fr: { ...defaultMessages.en, ...frenchMessages, ...raFormLayoutLanguageFrench, ...raRelationshipsLanguageFrench, ...local, ...resources.fr },
  // es: {
  //   ...defaultMessages.en,
  //   ...spanishMessages,
  //   // 'ra-relationships': {
  //   //   duallistinput: {
  //   //     availableItems: 'Elementos disponibles',
  //   //     selectedItems: 'Elementos seleccionados',
  //   //   },
  //   // },
  //   ...local,
  //   ...resources.es,
  // },
};

export const languages = [
  { locale: 'en-US', name: 'English 🇺🇸' },
  { locale: 'en-UK', name: 'English 🇬🇧' },
  { locale: 'es', name: 'Español 🇪🇸' },
  { locale: 'fr', name: 'Français 🇫🇷' },
];
export const defaultLanguage = languages[ 0 ]

export const i18nProvider = buildI18nProvider( messages, defaultLanguage.locale );
