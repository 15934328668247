import { alpha, Box, Button, ButtonProps, Card, CardContent, CircularProgress, Grid, GridProps, Slide, Stack, Typography, useTheme } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { IfCanAccess } from '@react-admin/ra-rbac';
import '@xyflow/react/dist/style.css';
import { sortBy, uniq, uniqBy } from 'lodash';
import { default as queryString } from 'query-string';
import { FC, MutableRefObject, ReactElement, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Title, useGetList, useNotify } from 'react-admin';
import { apiUrl, httpClient } from './DataProvider';
import './FlowDevTools.css';
import { PickByPropertyType } from './types';
import { useParentOrg } from './Organizations';


export interface ProfileScheduleSlot extends Record<string, unknown> {
  id: string;
  // schedule: {
  //   display: string;
  // },
  status: string;
  start: string;
  end: string;
  location: string;
  practitioner: string;
  // appointmentTypeCode: string;
  code: string;
  display: string;
}

export interface AppointmentType extends Record<string, unknown> {
  id: string;
  name: string;
  label?: string;
  newPatient?: boolean;
  dayAvailables?: Record<string, unknown>[];
};



export const AppointmentTypeSchedule: FC = () => {
  const basePath = 'appointmenttypeschedule';
  const baseLocation = `appointment.${ basePath }`;
  // const { id } = useParams();
  const [ _appLocation, setAppLocation ] = useAppLocationState();
  const { scheduleHorizonDays = 60 } = useParentOrg();
  const { data: appointmentTypes } = useGetList<AppointmentType>( 'appointmenttypes', { pagination: { page: 1, perPage: 200 } } );
  const { data: locations } = useGetList<LocationInterface>( 'locations', { filter: { active: true, 'locationEnabledForMessageSend': 'true' }, pagination: { page: 1, perPage: 100 }, sort: { field: 'name', order: 'ASC' } } );
  const { data: practitioners } = useGetList( 'practitioners', {
    filter: {
      // DEBUG DEBUG DEBUG // active: true,
      'q:npi': '1'
    }, pagination: { page: 1, perPage: 100 }
  } );
  const theme = useTheme();
  const sectionPatientTypes = useRef<HTMLDivElement>( null );
  const sectionTypes = useRef<HTMLDivElement>( null );
  const sectionLocations = useRef<HTMLDivElement>( null );
  const sectionPractitioners = useRef<HTMLDivElement>( null );
  const sectionSlots = useRef<HTMLDivElement>( null );
  const sectionDays = useRef<HTMLDivElement>( null );
  const sectionTimes = useRef<HTMLDivElement>( null );
  const [ slots, setSlots ] = useState<ProfileScheduleSlot[] | undefined>();
  const notify = useNotify();

  const fetchSlots = useCallback( async ( code: string, startDate: string, days = scheduleHorizonDays, location?: string, practitioner?: string ) => {
    const filter = { code, startDate, days, location, practitioner };
    const { body } = await httpClient( `${ apiUrl }/schedule?${ queryString.stringify( filter ) }` );
    return JSON.parse( body );
  }, [ scheduleHorizonDays ] );

  useEffect( () => {
    setAppLocation( `${ baseLocation }` );
    return () => setAppLocation( null );
  }, [] );

  type PatientType = 'New' | 'Existing';
  const allPatientTypes: PatientType[] = [ 'New', 'Existing' ];
  const [ patientType, setPatientType ] = useState<PatientType | undefined>();

  const [ appointmentType, setAppointmentType ] = useState<AppointmentType | undefined>();

  useEffect( () => {
    ( async () => {
      if( !appointmentType?.id ) return;
      const _slots = await fetchSlots( appointmentType.id, new Date().toISOString().slice( 0, 10 ) );
      setSlots( _slots );
    } )();
  }, [ fetchSlots, setSlots, appointmentType?.id ] );

  const [ location, setLocation ] = useState<LocationInterface | undefined>();
  const [ practitioner, setPractitioner ] = useState<PractitionerSummary | undefined>();

  const timeZoneName = 'America/Los_Angeles'; // TODO pull from location

  const [ date, setDate ] = useState<Date | undefined>();

  const isDaySelectedDate = useCallback( ( day: string ) => {
    return day == parseableDateTz( date, timeZoneName );
  }, [ date ] );

  type ShowTimes = 'Morning' | 'Afternoon' | 'Any Time';
  const allShowTimes: ShowTimes[] = [ 'Morning', 'Afternoon', 'Any Time' ]
  const [ showTimes, setShowTimes ] = useState<ShowTimes | undefined>();

  useEffect( () => scrollDown( sectionSlots ) );

  const onClickPatientType = useCallback( ( newPatientType: PatientType ) => {
    setPatientType( patientType == newPatientType ? undefined : newPatientType )
    setAppointmentType( undefined );
    setSlots( undefined );
    setLocation( undefined )
    setPractitioner( undefined );
    setShowTimes( undefined );
    setDate( undefined );
    scrollDown( sectionTypes );
  }, [ sectionTypes.current?.offsetTop, patientType, setPatientType, setAppointmentType ] );

  const onClickType = useCallback( ( newType: AppointmentType ) => {
    setSlots( undefined );
    setAppointmentType( appointmentType?.id == newType.id ? undefined : newType )
    setLocation( undefined )
    setPractitioner( undefined );
    setShowTimes( undefined );
    setDate( undefined );
    scrollDown( sectionLocations );
  }, [ sectionLocations.current?.offsetTop, appointmentTypes, setAppointmentType, setDate ] );

  const onClickLocation = useCallback( ( newLocation: LocationInterface ) => {
    setLocation( location?.id == newLocation.id ? undefined : newLocation )
    setPractitioner( undefined );
    setShowTimes( undefined );
    setDate( undefined );
    scrollDown( sectionPractitioners );
  }, [ sectionPractitioners.current?.offsetTop, locations, setPractitioner, setDate ] );

  const onClickPractitioner = useCallback( ( newPractitioner: PractitionerSummary ) => {
    setPractitioner( practitioner?.id == newPractitioner.id ? undefined : newPractitioner )
    setShowTimes( undefined );
    setDate( undefined );
    scrollDown( sectionSlots );
  }, [ sectionSlots.current?.offsetTop, setPractitioner, setDate, practitioner ] );

  const onClickShowTimes = useCallback( ( newShowTimes: ShowTimes ) => {
    setShowTimes( showTimes == newShowTimes ? undefined : newShowTimes )
    setDate( undefined );
    scrollDown( sectionDays );
  }, [ sectionDays.current?.offsetTop, showTimes, setDate ] );

  const onClickDate = useCallback( ( d: string ) => {
    setDate( new Date( d ) );
    scrollDown( sectionTimes );
  }, [ sectionTimes.current?.offsetTop, showTimes, setDate ] );

  const isMorning = ( d: string ): boolean => prettyTime( d, timeZoneName ).includes( 'AM' );

  const availableTypes = useMemo( () => {
    if( !appointmentTypes ) return;
    return sortBy(
      appointmentTypes
        .filter( type => type.dayAvailables?.length )
        .filter( type => ( patientType == 'New' ) == !!type.newPatient )
      , t => t.label || t.name );
  }, [ appointmentTypes, patientType ] );

  const availableLocations = useMemo<LocationInterface[] | undefined>( () => {
    if( !locations?.length || slots === undefined || !appointmentType?.id ) return;
    const available = uniq( slots
      .filter( slot => slot.code == appointmentType.id )
      .map( slot => slot.location ) );
    return locations.filter( l => available.includes( l.id ) );
  }, [ slots, locations, appointmentType?.id ] );

  const availablePractitioners = useMemo<PractitionerInterface[] | undefined>( () => {
    if( !practitioners?.length || slots === undefined || !location?.id ) return;
    const available = uniq( slots
      .filter( slot => slot.location == location.id )
      .filter( slot => !showTimes || showTimes == 'Any Time' || ( showTimes == 'Morning' ? isMorning( slot.start ) : !isMorning( slot.start ) ) )
      .map( slot => slot.practitioner ) );
    return practitioners.filter( p => available.includes( p.id ) );
  }, [ slots, showTimes, location?.id, practitioners ] );

  type AvailableDate = { label: string; day: string; }
  const availableDates = useMemo<AvailableDate[] | undefined>( () => {
    if( !slots || !location?.id || !practitioner?.id ) return;
    const available = slots
      .filter( slot => slot.location == location.id && slot.practitioner == practitioner.id )
      .filter( slot => showTimes == 'Any Time' || ( showTimes == 'Morning' ? isMorning( slot.start ) : !isMorning( slot.start ) ) )
      .map( slot => ( { label: prettyShortDate( slot.start, timeZoneName ), day: parseableDateTz( slot.start, timeZoneName ) } ) );
    return uniqBy( available, a => a.label );
  }, [ slots, showTimes, location?.id, practitioner?.id ] );

  const filteredSlots = useMemo( () => {
    if( !slots || !date || !showTimes || !location?.id || !practitioner?.id ) return;
    const day = prettyDate( date, timeZoneName );
    const available = slots
      .filter( slot => slot.location == location.id && slot.practitioner == practitioner.id )
      .filter( slot => prettyDate( slot.start, timeZoneName ) == day )
      .filter( slot => showTimes == 'Any Time' || ( showTimes == 'Morning' ? isMorning( slot.start ) : !isMorning( slot.start ) ) )
    return available;
  }, [ slots, date, showTimes, location?.id, practitioner?.id, timeZoneName ] );

  // const onSchedule = useCallback( async ( slot: ProfileScheduleSlot ) => {
  //   if( !location || !practitioner ) return;
  //   const errors = await scheduleAppointment( profileId, slot );
  //   if( errors ) {
  //     setError( errors[ 0 ] );
  //     return;
  //   }
  //   // setStatus( 'booked' );
  //   // setStartTime( slot.start );
  //   setNotify( 'Your appointment has been scheduled.' );
  //   navigate( '..' );
  // }, [ navigate, profileId, location, practitioner ] );


  return (
    <IfCanAccess resource='locations' action='write'>
      <Box>
        <Title title='Schedule graph ' />
        <Card
          sx={ {
            minHeight: '25em',
          } }
        >
          <CardContent>
            <div id='appointment-schedule'>
              <h3 >
                Schedule New Appointment
              </h3>
              <Box display='none'>
                <Typography>{ timeZoneName }</Typography>
                <Typography>{ location?.name ?? '' }</Typography>
                <Typography>{ practitioner?.fullName ?? '' }</Typography>
                <Typography>{ showTimes }</Typography>
                <Typography>{ parseableDateTz( date, timeZoneName ) }</Typography>
              </Box>

              <Stack mb={ 2 }
              >
                <Box
                  sx={ {
                    textAlign: 'center',
                    // border: '1px solid purple',
                    // width: '100%'
                  } }
                >

                  {/***********  Patient Type *****************/ }

                  <ScheduleSection<PatientType>
                    sectionRef={ sectionPatientTypes }
                    header={ 'What type of patient?' }
                    choices={ allPatientTypes }
                    label={ v => v }
                    sampleSize={ 0 }
                    onSelect={ onClickPatientType }
                    isSelected={ ( v ) => v == patientType }
                    containerProps={ {
                      // justifyContent: 'space-around',
                      columnSpacing: 2,
                    } }
                    itemProps={ {
                      xs: 10,
                      sm: Math.floor( 8 / allPatientTypes.length ),
                    } }
                    buttonSx={ {
                      width: { xs: undefined, sm: '100%' },
                      '& .MuiTypography-root': {
                        margin: 'inherit',
                      },
                    } }

                  />


                  {/***********  APPOINTMENT TYPES *****************/ }
                  <ScheduleSection<AppointmentType>
                    enable={ !!patientType }
                    sectionRef={ sectionTypes }
                    header={ 'What type of appointment?' }
                    choices={ availableTypes }
                    label={ v => v.label || v.name }
                    sampleSize={ 11 }
                    onSelect={ onClickType }
                    isSelected={ ( v ) => v.id == appointmentType?.id }
                    buttonSx={ { width: { xs: undefined, sm: '10rem', md: '24rem' } } }
                  />


                  {/***********  LOCATIONS *****************/ }
                  <ScheduleSection<LocationInterface>
                    enable={ !!appointmentType }
                    sectionRef={ sectionLocations }
                    header={ 'What is your preferred location?' }
                    choices={ availableLocations }
                    label={ v => v.nickname || v.name }
                    sampleSize={ 11 }
                    onSelect={ onClickLocation }
                    isSelected={ ( v ) => v.id == location?.id }
                    buttonSx={ { width: { xs: undefined, sm: '10rem', md: '20rem' } } }

                  />

                  {/***********  PRACTITIONERS *****************/ }
                  <ScheduleSection<PractitionerInterface>
                    enable={ !!location }
                    sectionRef={ sectionPractitioners }
                    header={ 'Who is your preferred practitioner?' }
                    choices={ availablePractitioners }
                    labelField='fullName'
                    sampleSize={ 19 }
                    onSelect={ onClickPractitioner }
                    isSelected={ ( v ) => v.id == practitioner?.id }
                    buttonSx={ { width: { xs: undefined, sm: '10rem', md: '20rem' } } }

                  />

                  {/***********  Time Slots *****************/ }

                  <ScheduleSection<ShowTimes>
                    enable={ !!practitioner }
                    sectionRef={ sectionSlots }
                    header={ 'What is your preferred time of day?' }
                    choices={ allShowTimes }
                    label={ v => v }
                    sampleSize={ 0 }
                    onSelect={ onClickShowTimes }
                    isSelected={ ( v ) => v == showTimes }
                    containerProps={ {
                      justifyContent: 'space-between',
                      columnSpacing: 2,
                    } }
                    itemProps={ {
                      xs: 10,
                      sm: Math.floor( 12 / allShowTimes.length ),
                    } }
                    buttonSx={ {
                      width: { xs: undefined, sm: '100%' },
                      '& .MuiTypography-root': {
                        margin: 'inherit',
                      },
                    } }

                  />





                  {/***********  Dates *****************/ }

                  <ScheduleSection<AvailableDate>
                    enable={ !!showTimes }
                    sectionRef={ sectionDays }
                    header={ `Available "${ showTimes }" Dates` }
                    choices={ availableDates }
                    labelField='label'
                    sampleSize={ 19 }
                    onSelect={ ( v ) =>
                      onClickDate( v.day )
                    }
                    isSelected={ ( v ) => isDaySelectedDate( v.day ) }
                  />


                  {/***********  Times *****************/ }
                  <ScheduleSection<ProfileScheduleSlot>
                    enable={ !!date }
                    sectionRef={ sectionTimes }
                    header={ `Available Times for ${ prettyDate( date, timeZoneName ) }` }
                    choices={ filteredSlots }
                    label={ v => prettyTime( v.start, timeZoneName ) }
                    sampleSize={ 19 }
                    onSelect={ () => { } }
                    isSelected={ ( v ) => v.id == practitioner?.id }
                    buttonSx={ { width: { xs: undefined, sm: '10rem', md: '9rem' } } }
                  />




                </Box>

              </Stack>
            </div>
          </CardContent>
        </Card>


      </Box >
    </IfCanAccess >
  );
}

export interface ScheduleSectionProps<T extends string | Record<string, unknown>> {
  enable?: boolean;
  header: string;
  sectionRef: RefObject<HTMLDivElement>;
  choices: T[] | undefined;
  labelField?: keyof PickByPropertyType<T, string>;
  label?: ( v: T ) => string;
  sampleSize?: number;
  onSelect: ( v: T ) => void;
  isSelected: ( v: T ) => boolean;
  buttonSx?: ButtonProps[ 'sx' ];
  containerProps?: Partial<GridProps>;
  itemProps?: Partial<GridProps>;
}

export const ScheduleSection = <T extends string | Record<string, unknown>>( props: ScheduleSectionProps<T> ): ReactElement | null => {
  const { enable = true, header, sectionRef, choices, sampleSize = 0, onSelect, isSelected, labelField, label,
    buttonSx = {}, itemProps = {}, containerProps = {},
  } = props;
  const theme = useTheme();
  const [ more, setMore ] = useState( false );
  const sampled = useMemo( () => sample( choices || [], sampleSize ), [ choices ] );

  useEffect( () => {
    if( more ) setMore( false );
  }, [ choices ] );

  const getLabel = ( choice: T ): string => {
    if( labelField ) return ( choice[ labelField ] || labelField ) as string;
    return label ? label( choice ) : 'label';
  };

  if( !enable ) return null;

  return (
    <>
      <Box
        ref={ sectionRef }
        sx={ {
          // border: '1px solid red',
          margin: '2rem auto 0',
          '& .MuiTypography-body1': {
            // color: 'red',
            margin: '1.25rem 1rem 1rem 0',
            fontWeight: 'bold',
            width: '30rem',
            display: 'inline-block',
          },
        } }
      >
        { choices === undefined
          ? (
            <Box pt={ 2 }>
              <CircularProgress size='2rem' />
              {/* <Typography> Loading...</Typography> */ }
            </Box>
          )
          : (
            <Typography
              component='span'
              sx={ {
              } }
              align='center'
            >
              { header }
            </Typography>
          ) }
      </Box>

      <Slide in={ !!enable && choices !== undefined } direction='up' >
        <Grid container
          alignItems='flex-start'
          sx={ {
            // height: '22rem',
            // border: '1px solid purple',
            '& .MuiButton-outlined': {
              backgroundColor: alpha( theme.palette.primary.main, 0.08 ),
              color: theme.palette.primary.main,
              '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
              },
              '&:hover': {
                backgroundColor: alpha( theme.palette.primary.main, 0.18 ),
              },
            }
          } }
          { ...containerProps }
        >
          { !choices?.length
            ? (
              <Typography pt={ 2 }>No available choices</Typography>
            )
            : ( more ? choices : sampled ).map( ( choice, idx ) => (
              <Grid
                item
                key={ idx }
                xs={ 12 } sm={ 'auto' }
                { ...itemProps }
              >

                <Button
                  variant={ isSelected( choice ) ? 'contained' : 'outlined' }
                  size='small'
                  onClick={ () =>
                    onSelect( choice )
                  }
                  sx={ {
                    margin: { xs: '0.5rem 0', sm: '0.5rem' },
                    width: { xs: undefined, sm: '10rem', md: '14rem' },
                    '& .MuiTypography-root': {
                      margin: 'inherit',
                    },
                    ...buttonSx
                  } }
                  disableRipple
                >
                  <Typography
                    component='span'
                    sx={ {
                      margin: '0.5rem 1rem 0 0',
                    } }>
                    { getLabel( choice ) }
                  </Typography>
                </Button>

              </Grid>
            ) )
          }
          { choices && choices.length > sampled.length &&
            <Grid item key='more'
              { ...itemProps }

            >
              <Button
                variant='text'
                size='medium'
                onClick={ () => setMore( !more ) }
                sx={ {
                  margin: '0.5em',
                  width: { xs: '12rem', sm: '10rem', md: '9rem' },
                  // '& .MuiTypography-root': {
                  //   margin: 'inherit',
                  // },
                  ...buttonSx
                } }
                disableRipple
              >
                <Typography component='span' sx={ { margin: '0.5rem 1rem 0 0' } }>
                  { more ? 'less' : 'more' }
                </Typography>
              </Button>
            </Grid>
          }

        </Grid>
      </Slide >
    </>
  );
}



export const sample = <T,>( arr: T[], n: number ): T[] => {
  if( !n || arr.length <= n ) return arr;
  const x = Math.floor( arr.length / n );
  if( x > 1 ) {
    return sample( arr.filter( ( _a, idx ) => idx % x == 0 ), n );
  }
  const y = Math.ceil( arr.length / ( arr.length - n ) );
  const b = arr.filter( ( _a, idx ) => idx % y != 0 );
  return [ ...b.slice( 0, n - 2 ), ...b.slice( -2 ) ];
}

export const scrollDown = ( ref: MutableRefObject<HTMLDivElement | null> ) => {
  window.setTimeout( () => {
    ref?.current &&
      window.scrollTo( {
        top: ref.current.offsetTop,
        behavior: 'smooth',
      } );
  }, 200 );
}


export type IdentifierType = string;
export interface StandardFieldsInterface<T extends IdentifierType = string> extends Record<string, unknown> {
  _id: T;
  id: string;
  createdAt: string; // Date;
  updatedAt: string; // Date;

}

export interface TagsFieldInterface {
  tags: string[];
}

export enum RecipientTypeEnum {
  UNKNOWN = 'unknown',
  ALL = 'All',
  PATIENT = 'Patient',
  NON_PATIENT = 'Non-Patient',
  FAMILY_MEMBER = 'Family Member',
  EXTENDED_FAMILY_MEMBER = 'Extended Family Member',
  NEIGHBOR = 'Neighbor',
  FRIEND = 'Friend',
  GUARDIAN = 'Guardian',
  CAREGIVER = 'Caregiver',
  TRANSPORTATION = 'Transportation',
  SKILLED_NURSING = 'Skilled Nursing',
  PHYSICIAN = 'Physician',
  ATTORNEY = 'Attorney',
  PRACTITIONER = 'Practitioner',
  OTHER = 'Other',
}

export interface PractitionerInterface extends StandardFieldsInterface, TagsFieldInterface {
  comment: string;
  fhirId: string;
  active: boolean;
  prefix?: string;
  firstName: string;
  middleName?: string;
  givenName?: string;
  lastName: string;
  suffix?: string;
  fullName: string;

  npi: string;
  profileImageUrl?: string;
}

export type PractitionerSummary = Pick<PractitionerInterface, 'id' | 'fullName' | 'profileImageUrl' | 'fhirId' | 'prefix' | 'suffix'>;

export interface LocationInterface extends StandardFieldsInterface, TagsFieldInterface { // , WeekdaysHoursInterface, GeolocationInterface {
  comment?: string;
  name: string;
  nickname?: string;
  slug?: string;
  //   status?: ELOCATION_STATUS;
  fhirId: string;
  //   lastUpdateMethod?: ELOCATION_UPDATE_METHOD;
  //   fhirAddress?: FhirAddress[];
  //   fhirAddressId?: string;
  humanAddress?: string;
  telephone?: string;
  fax?: string;
  email?: string;
  webUrl?: string;
  webShortUrl?: string;
  logoUrl?: string;
  amenityUrl?: string;
  amenityShortUrl?: string;
  locationSignature?: string;
  timeZoneName?: string;

  // Coordinates plugin?
  lat?: number;
  lng?: number;

  // weekdaysHours plugin

  geoCoded?: boolean;
  timeZoneHumanName: string;
  // googleGeoData?: GeoDataInterface;

  //  reputationServices: PractitionerReputationServiceInterface[];

  // new from org-api/amenities
  description?: string;
  organization?: string;
  // geolocation
  // tags

  twitter?: string;
  facebook?: string;

  locationEnabledForMessageSend?: boolean;
  locationEnabledForReviewGating?: boolean;

  appointmentTypeCodesEnabledForCancel?: string[];
  appointmentTypeCodesEnabledForReschedule?: string[];
  appointmentTypeCodesEnabledForSchedule?: string[];
}

export const prettyDate = ( date: Date | string | undefined, timeZone: string ): string => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleDateString( 'en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone } );
}

export const parseableDateTz = ( date: Date | string | undefined, timeZone: string ): string => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleDateString( 'en-US', { year: 'numeric', month: 'numeric', day: 'numeric', timeZone, timeZoneName: 'short' } );
}

export const prettyShortDate = ( date: Date | string | undefined, timeZone: string ): string => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleDateString( 'en-US', { weekday: 'short', year: undefined, month: 'short', day: 'numeric', timeZone } );
}

export const prettyTime = ( date: Date | string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleTimeString( 'en-US', { timeStyle: 'short', timeZone } );
}

export const prettyTimeTz = ( date: Date | string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleTimeString( 'en-US', { hour: 'numeric', minute: 'numeric', timeZoneName: 'short', timeZone } );
}

export const prettyDateTime = ( date: Date | string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return [
    d.toLocaleDateString( 'en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone } ),
    d.toLocaleTimeString( 'en-US', { timeStyle: 'short', timeZone } )
  ].join( ' ' );
}

export const prettyShortDateTime = ( date: Date | string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return [
    d.toLocaleDateString( 'en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', timeZone } ),
    d.toLocaleTimeString( 'en-US', { timeStyle: 'short', timeZone } )
  ].join( ' ' );
}

export const prettyTimezone = ( date: Date | string | undefined = new Date(), timeZone?: string ): string => {
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleTimeString( 'en-US', { hour: '2-digit', hour12: false, timeZoneName: 'long', timeZone } ).slice( 3 );
}

export const getProperName = ( names: Pick<PractitionerInterface, 'prefix' | 'fullName' | 'suffix'> ): string => {
  const { fullName, prefix, suffix } = names;
  return [ prefix, fullName, suffix ].filter( n => !!n ).join( ' ' )
}

// export const SimpleConfirmSnackbar: FC<{ open: boolean, onClose: () => void, message: string }> = ( props ) => {
//   const { open, onClose, message } = props;
//  
//   return (
//     <Snackbar
//       key='notify'
//       open={ open }
//       autoHideDuration={ 3000 }
//       onClose={ onClose }
//       anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
//     >
//       <Alert
//         severity='success'
//         variant='filled'
//       >
//         { message }
//       </Alert>
//     </Snackbar>
//   );
// }
//  
// export const SimpleErrorSnackbar: FC<{ open: boolean, onClose: () => void, message: string }> = ( props ) => {
//   const { open, onClose, message } = props;
//  
//   return (
//     <Snackbar
//       key='error'
//       open={ open }
//       autoHideDuration={ 10000 }
//       onClose={ onClose }
//       anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
//     >
//       <Alert
//         severity='error'
//         variant='filled'
//       >
//         { message }
//       </Alert>
//     </Snackbar>
//   );
// }

