import { Box, Card, CardContent, CardHeader, Typography, useTheme } from '@mui/material';
import { Bar, BarDatum } from '@nivo/bar';
import { addDays } from 'date-fns';
import { capitalize } from 'inflection';
import { get, set, sortBy } from 'lodash';
import { default as queryString } from 'query-string';
import { FC, useMemo } from 'react';
import { Loading, useRedirect } from 'react-admin';
import { ChartProps, elevation, useDashboardSearchParams } from './DashboardWidget';
import useColorPalettes from './use-color-palettes';
import usePatterns from './use-patterns';
import useSummary from './use-summary';
import { DashboardInfo } from './DashboardInfo';

export const messagePurposeMap: Record<string, string> = {
  'Reminder': 'Reminder',
  'Appointment Update': 'Update',
  'Appointment Rescheduled': 'Update',
  'Welcome Message': 'Welcome',
  'Appointment Reminder': 'Reminder',
  'Thank You From Staff': 'Thank you',
  'Thank You From Doctor Visit': 'Thank you',
  'Thank You From Doctor Post-Op': 'Thank you',
  'Pre-op Reminder': 'Reminder',
  'Post-op Reminder': 'Reminder',
  'At-Op Reminder': 'Reminder',
  'Clinical Update': 'Update',
  'Pathology Welcome Message': 'Welcome',
  'Pathology Update': 'Pathology',
  'Pathology Completed': 'Pathology',
  'Operational Update': 'Pathology',
  'Amenity Update': 'Update',
  'Birthday Update': 'Birthday',
  'Appointment Reminder Include Confirm Request': 'Reminder',
  'Alert': 'Broadcast',
  'Performance Review Public': 'Review',
  'Performance Review Internal': 'Review',
  'No-Show to Appointment Reminder': 'Canc/NoSh',
  'Cancelled Appointment Reminder': 'Canc/NoSh',
  'Test Message': 'Test'
}

export const mapMessagePurpose = ( key: string ): string => ( key && messagePurposeMap[ key ] ) || key || 'Other';
export const getMessagePurposes = ( val: string ): string[] => {
  return Object.entries( messagePurposeMap ).filter( ( [ _k, v ] ) => v == val ).map( ( [ k ] ) => k )
};

interface MessagesSentDatum {
  purpose: string;
  method: string;
  count: string;
  templateNames?: string[];
}

export const DashboardSentMessages: FC<ChartProps> = ( { date } ) => {
  const title = 'Sent Messages';
  const theme = useTheme();
  const redirect = useRedirect();
  const isSmall = false;
  const { isReportView } = useDashboardSearchParams();
  // const keys = useMemo( () => isEmpty ? [ 'No data' ] : [ 'email', 'sms', 'voice' ].map( capitalize ), [ data, isEmpty ] );
  const keys = [ 'email', 'sms', 'voice' ].map( capitalize );

  const summary = useSummary<MessagesSentDatum[]>( 'sentByTemplateByMethodTrailing7Days', { date } );

  const data = useMemo( () => {
    const _data: Record<string, Record<string, number | string[]>> = {};
    for( const d of summary.data || [] ) {
      if( !d.method || !d.purpose ) continue; // safeguard for bad data
      const countKey = `${ mapMessagePurpose( d.purpose ) }.${ capitalize( d.method ) }`;
      const templateKey = `${ mapMessagePurpose( d.purpose ) }.templateNames`;
      set( _data, countKey, get( _data, countKey, 0 ) + d.count );
      set(
        _data,
        templateKey,
        Array.from( new Set( [ ...get( _data, templateKey, [] ) as string[], ...( d.templateNames || [] ) ] ) ),
      );
    }
    let rows = sortBy( Object.entries( _data ).map( d => ( {
      ...d[ 1 ],
      purpose: d[ 0 ],
      templateNames: ( d[ 1 ].templateNames as string[] || [] ).join( ';' ),
    } ) ), 'purpose' ).reverse() as BarDatum[];
    // console.log( rows );
    if( rows.length == 0 ) {
      // @ts-ignore:2322
      rows = [ { purpose: 'No data', 'Email': 100 }, { purpose: '' }, { purpose: ' ' } ];
    }
    return rows;
  }, [ date, summary.data ] );

  const isEmpty = useMemo( () => data[ 0 ]?.template === 'No data', [ data ] );

  const colorPalettes = useColorPalettes();
  const colors = useMemo( () => colorPalettes.discretePrimary.slice( -keys.length ), [ colorPalettes, keys ] );
  const patterns = usePatterns();

  return (

    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={ isReportView ?
          title :
          <Box sx={ { display: 'flex' } } >
            <Typography>{ title }</Typography>
            <DashboardInfo tag='sent-messages'>
              <i>{ isReportView }</i> shows message stats for messages sent over the previous period of time.
              Messages are grouped by type and are stacked based on the sending method (email, SMS, voice).
              Clicking on a colored portion of the bar will redirect you to the Sent Messages page,
              pre-filtered for the message type selected.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `7 Days Ending ${ date.format( 'MMM D, YYYY' ) }` : 'Previous 7 Days' }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
            // fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
      // avatar={ <DashboardAlertIcon /> }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { summary.isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          : <Bar
            width={ 500 }
            height={ 400 }
            data={ data }
            indexBy='purpose'
            keys={ keys }
            margin={ { top: 20, right: 80, bottom: 55, left: 120 } }
            padding={ 0.3 }
            groupMode='stacked'
            layout='horizontal'
            valueScale={ { type: 'linear' } }
            indexScale={ { type: 'band', round: true } }
            // maxValue={ 3000 }
            gridXValues={ 6 } // [ 0, 1000, 2000, 3000 ] }

            isInteractive={ !isEmpty }

            onClick={ ( datum ) => {
              // @ts-ignore:2339
              const { id: transmitMethod, data: { templateNames = '' } } = datum;
              if( !templateNames ) return;

              const filter = JSON.stringify( {
                delimiter: ';',
                templateName: templateNames, // joined w ";"
                transmitMethod: ( transmitMethod as string ).toLowerCase(),
                transmitCompleted: true,
                postedDate: [ `gte:${ addDays( new Date(), -7 ).toISOString().slice( 0, 10 ) }`, `lt:${ new Date().toISOString().slice( 0, 10 ) }` ].join( ';' ),
              } );
              redirect( `/messaging?${ queryString.stringify( { filter } ) }` );
            } }

            theme={ {
              background: theme.palette?.background?.default,
              axis: {
                legend: { text: { fontSize: isSmall ? 16 : 18 } },
                ticks: { text: { fontSize: isSmall ? 0 : 14 } },
              },
              labels: { text: { fill: '#333' } },
              legends: { text: { fontSize: 14 } },
              text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
              tooltip: {
                container: {
                  borderRadius: '8px',
                  border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                  boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                  backgroundColor: theme.palette?.background?.default,
                  color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                  padding: '5px 9px',
                },
              },
            } }

            colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } // { scheme: 'accent', size: keys.length } }
            defs={ patterns.defs }
            fill={ patterns.fillByKeys( keys ) }

            enableLabel={ false }
            enableGridX={ true }
            enableGridY={ false }
            axisTop={ null }
            axisRight={ null }
            axisBottom={ {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: 6, // [ 0, 1000, 2000, 3000 ],
              legend: 'Count by channel',
              legendPosition: 'middle',
              legendOffset: 42,
            } }
            axisLeft={ {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Message type',
              legendPosition: 'middle',
              legendOffset: -110
            } }
            legends={ [
              {
                dataFrom: 'keys',
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 90,
                translateY: -120,
                itemsSpacing: 4,
                itemWidth: 80,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                symbolShape: 'square',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ] }
          />
        }
      </CardContent>
    </Card>

  );

}
