import { Box, SxProps, Theme } from '@mui/material';
import { DataGridPremium, GridColDef, GridColType, GridEventListener, GridPremiumSlotsComponent, GridRowsProp, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import { capitalize } from 'lodash';
import { FC, useMemo } from 'react';
import { useDashboardSearchParams } from './DashboardWidget';

export interface DashboardDatagridProps extends DashboardTableProps { }
export const DashboardDatagrid: FC<DashboardDatagridProps> = props => {
  const { columns, ...rest } = props;
  return <DashboardTable
    { ...rest }
    columns={ [
      { field: 'ymd', headerName: 'Date', sortable: true, flex: 2 },
      ...columns
    ] }
  />;
}

export interface DashboardTableProps {
  columns: Array<string|GridColDef>;
  rows: GridRowsProp;
  hideFooter?: boolean;
  exportFileName?: string;
  toolbar?: GridPremiumSlotsComponent[ 'toolbar' ];
  onRowClick?: GridEventListener<'rowClick'>;
  sx?: SxProps<Theme>;
}
export const DashboardTable: FC<DashboardTableProps> = props => {
  const { hideFooter, onRowClick, rows, sx } = props;
  const { isReportView } = useDashboardSearchParams();

  const columns = useMemo<GridColDef[]>( () => {
    return props.columns.map( column => typeof column == 'string'
      ? {
        field: column,
        headerName: capitalize( column ),
        type: 'number' as GridColType,
        sortable: false,
        flex: 1,
      }
      : column );
  }, [ props.columns ] );

  const toolbar = useMemo( () => {
    if( props.toolbar ) return props.toolbar;
    if( props.exportFileName ) return ExportOnlyToolbar.bind( undefined, { fileName: props.exportFileName } );
  }, [ props.exportFileName, props.toolbar ] );

  return (
    <Box
      width={ 500 }
      height={ 400 + 48 + 24 }
      sx={ {
        marginTop: '-48px',
        marginBottom: '-17px',
        ...sx,
      } }
    >
      <DataGridPremium
        density='compact'
        disableRowSelectionOnClick
        disableColumnResize
        disableColumnSelector
        disableColumnMenu
        disableColumnReorder
        disableColumnPinning
        disableChildrenSorting
        disableMultipleColumnsSorting
        hideFooter={ hideFooter || !rows.length || isReportView }
        pagination
        autoPageSize
        rows={ rows }
        columns={ columns }
        onRowClick={ onRowClick }
        slots={ toolbar && { toolbar } }
        sx={ {
          borderColor: 'transparent',
          '& .MuiDataGrid-columnHeaders,& .MuiDataGrid-cell': {
            borderBottomColor: 'transparent',
          },
          '& .MuiDataGrid-virtualScroller': {
            overflow: 'hidden',
          },
        } }
        initialState={ {
          sorting: {
            sortModel: [ { field: 'ymd', sort: 'desc' } ],
          }
        } }
      />
    </Box>
  );
}

export interface ExportOnlyToolbarProps {
  fileName?: string;
}
export const ExportOnlyToolbar: FC<ExportOnlyToolbarProps> = props => {
  const { fileName = 'export' } = props;
  const { isReportView } = useDashboardSearchParams();
  return (
    <GridToolbarContainer
      sx={ {
        height: '2.5rem',
        '& > .MuiButton-root': {
          marginTop: '-1rem',
          marginBottom: '-1rem',
        }
      } }
    >
      <Box sx={ { flexGrow: 1 } } />
      { !isReportView && <GridToolbarExport
        csvOptions={ {
          utf8WithBom: true,
          allColumns: true,
          // getRowsToExport: getRowsWithoutGroups,
          fileName,
        } }
        excelOptions={ {
          fileName,
          allColumns: true,
          // getRowsToExport: getRowsWithoutGroups,
          // exceljsPreProcess,
          // exceljsPostProcess,
        } }
        printOptions={ {
          disableToolbarButton: true,
          hideToolbar: true,
          hideFooter: true,
          includeHeaders: false,
          fileName,
        } }

      /> }
    </GridToolbarContainer>
  );
}
