import { Box, Grid } from '@mui/material';
import { getHours, getMinutes } from 'date-fns';
import { capitalize } from 'inflection';
import { get, sortBy } from 'lodash';
import { FC } from 'react';
import { BooleanInput, FieldProps, FormDataConsumer, FunctionField, InputProps, RaRecord, TextField, TimeInput } from 'react-admin';
import { defaultWeekdaysHours, formatWeekdaysHours, lpad, WeekdayOpenClose, WeekdaysHours } from './weekdays-hours';

// import { TimeInput } from '@react-admin/ra-form-layout';

export { defaultWeekdaysHours, lpad };
export type { WeekdayOpenClose, WeekdaysHours };

export type DayId = '1' | '2' | '3' | '4' | '5' | '6' | '0';
// type HourKey = 'openTime' | 'closeTime';
export type OpenClose = {
  isOpen?: boolean;
  openTime?: string;
  closeTime: string;
}
export type DaysOpenClose = Record<string, OpenClose>;
export type DayChoice = { id: DayId, name: string };
export const dayChoices: DayChoice[] = [
  { id: '1', name: 'Monday' },
  { id: '2', name: 'Tuesday' },
  { id: '3', name: 'Wednesday' },
  { id: '4', name: 'Thursday' },
  { id: '5', name: 'Friday' },
  { id: '6', name: 'Saturday' },
  { id: '0', name: 'Sunday' },
];

export const getWeekdaysHours = ( daysOpenClose: DaysOpenClose ): WeekdaysHours => {
  const getWeekdayOpenClose = ( dayName: string, openClose?: OpenClose ): WeekdayOpenClose => {
    const dayId = ( dayChoices.find( c => c.name == dayName ) || {} ).id || '-1';
    const weekday = parseInt( dayId );
    const { isOpen, openTime, closeTime } = openClose || {};
    if( !( isOpen && openTime && closeTime ) ) {
      return { weekday };
    }
    return {
      weekday,
      openHour: getHours( new Date( openTime ) ),
      openMinute: getMinutes( new Date( openTime ) ),
      closeHour: getHours( new Date( closeTime ) ),
      closeMinute: getMinutes( new Date( closeTime ) ),
    };
  }
  return Object.entries( daysOpenClose ).map( ( [ dayName, openClose ] ) => getWeekdayOpenClose( dayName, openClose ) ) as WeekdaysHours;
}

export const getDaysOpenClose = ( weekdaysHours: WeekdaysHours = defaultWeekdaysHours ): DaysOpenClose => {
  return Object.fromEntries(
    weekdaysHours
      .map( weekdayOpenClose => {
        const { weekday, openHour, openMinute = 0, closeHour, closeMinute = 0 } = weekdayOpenClose;
        const dayName = ( dayChoices.find( c => c.id == String( weekday ) ) || {} )?.name;
        if( !dayName ) return undefined;

        return [
          dayName, {
            isOpen: openHour !== undefined && closeHour != undefined,
            openTime: `2021-01-01T${ lpad( openHour || 8 ) }:${ lpad( openMinute ) }:00`,
            closeTime: `2021-01-01T${ lpad( closeHour || 17 ) }:${ lpad( closeMinute ) }:00`,
          }
        ]
      } )
      .filter( ( x ): x is ( string | Required<OpenClose> )[] => x !== null )
  );
}

export const defaultDaysOpenClose: DaysOpenClose = {
  Monday: { isOpen: true, openTime: '2021-01-01T08:00:00', closeTime: '2021-01-01T17:00:00' },
  Tuesday: { isOpen: true, openTime: '2021-01-01T08:00:00', closeTime: '2021-01-01T17:00:00' },
  Wednesday: { isOpen: true, openTime: '2021-01-01T08:00:00', closeTime: '2021-01-01T17:00:00' },
  Thursday: { isOpen: true, openTime: '2021-01-01T08:00:00', closeTime: '2021-01-01T17:00:00' },
  Friday: { isOpen: true, openTime: '2021-01-01T08:00:00', closeTime: '2021-01-01T17:00:00' },
  Saturday: { isOpen: false, openTime: '2021-01-01T08:00:00', closeTime: '2021-01-01T17:00:00' },
  Sunday: { isOpen: false, openTime: '2021-01-01T08:00:00', closeTime: '2021-01-01T17:00:00' },
}

export interface RetailHoursInputProps extends Omit<InputProps, 'source'> {
  source?: string;
}

export const RetailHoursInput: FC<RetailHoursInputProps> = props => {
  const { defaultValue, label = 'Hours', source = 'daysOpenClose', ...rest } = props;

  return (
    <Grid container direction="column">

      { sortBy( dayChoices, 'id' ).map( ( { id, name } ) => (
        <DayOpenCloseInput
          { ...rest }
          key={ `${ source }.${ id }` }
          source={ `${ source }.${ id }` }
          label={ name }
          defaultValue={ get( defaultValue, name, defaultDaysOpenClose[ name ] ) }
        />
      ) ) }

      <Grid container item key={ `${ source }.placeholder` } />

    </Grid>
  );
}

export interface DayOpenCloseInputProps extends Omit<InputProps<OpenClose>, 'format' | 'parse'> {
  formatIsOpen?: InputProps[ 'format' ];
  parseIsOpen?: InputProps[ 'parse' ];
  formatSlot?: ( slot: string ) => InputProps[ 'format' ];
  parseSlot?: ( slot: string ) => InputProps[ 'parse' ];
}

const convertDateToString = ( value: Date ) => {
  if( !( value instanceof Date ) || isNaN( value.getDate() ) ) return '';
  const hh = lpad( value.getUTCHours(), 2 );
  const mm = lpad( value.getUTCMinutes(), 2 );
  return `${ hh }:${ mm }`;
};
const timeRegex = /^\d{2}:\d{2}$/;
export const formatBaseTimeInput = ( value: string | Date ) => {
  if( value == null || value === '' ) return '';
  if( value instanceof Date ) return convertDateToString( value );
  if( timeRegex.test( value ) ) return value;
  return convertDateToString( new Date( value ) );
};

// export const formatBaseTimeInput = ( iso: string ) => { // return hh:mm
//   if( !iso ) return '';
//   const d = new Date( iso );
//   return [ d.getUTCHours(), d.getUTCMinutes() ].join( ':' );
// };

export const parseBaseTimeInput = ( value: string ) => { // value hh:mm
  if( !value ) return null;
  const [ hour, minute ] = value.split( ':' ).map( v => parseInt( v ) );
  const today = new Date();
  today.setUTCHours( hour ?? 0 );
  today.setUTCMinutes( minute ?? 0 );
  today.setUTCSeconds( 0 );
  today.setUTCMilliseconds( 0 );
  return today.toISOString();
};

export const DayOpenCloseInput: FC<InputProps> = props => {
  const { source, label = 'Day', defaultValue, ...rest } = props;


  return (
    <Box border={ 0 } key={ source }>
      <FormDataConsumer>
        { ( { formData } ) => (
          <Grid container direction="row" spacing={ 0 } alignContent="space-around" alignItems="flex-end">
            <Grid item xs={ 8 } sm={ 4 } >
              <Box pb={ 1 } mb={ 0 } border={ 0 }>
                <BooleanInput
                  key={ `${ source }.isOpen` }
                  label={ label }
                  source={ `${ source }.isOpen` }
                />
              </Box>
            </Grid>

            { [ 'open', 'close' ].map( slot => (

              <Grid item xs={ 6 } sm={ 4 } key={ `${ source }.${ slot }` }>
                <TimeInput
                  { ...rest }
                  key={ `${ source }.${ slot }Time` }
                  source={ `${ source }.${ slot }Time` }
                  label={ capitalize( slot ) }
                  inputProps={ { disabled: !get( formData, `${ source }.isOpen` ) } }
                  fullWidth={ false }
                  parse={ parseBaseTimeInput }
                  format={ formatBaseTimeInput }
                />
              </Grid>

            ) ) }

          </Grid>
        ) }
      </FormDataConsumer>
    </Box>
  );
}

export const RetailHoursField: FC<FieldProps<RaRecord>> = props => {
  const { record: oRecord, source = 'weekdaysHours' } = props;

  const format = ( record?: RaRecord, source?: string ): string => {
    return formatWeekdaysHours( get( record || {}, source || '' ) as WeekdaysHours | undefined, true );
  }

  return (
    <FunctionField<RaRecord> record={ oRecord } render={ ( record?: RaRecord ) => record && source && (
      <TextField  { ...props } source="date" record={ { id: source, date: format( record, source ) } } />
    ) } />
  );

}

