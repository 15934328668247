import { Box, Grid, useTheme } from '@mui/material';
import { LineProps } from '@nivo/line';
import { IfCanAccess } from '@react-admin/ra-rbac';
import dayjs, { Dayjs } from 'dayjs';
import { cloneElement, FC, ReactElement, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DashboardWidgetDragDrop, OnDrop } from './DashboardWidgetDragDrop';
import { PickByPropertyType } from './types';

export type LineLegendProps = NonNullable<LineProps[ 'legends' ]>[ 0 ];
export type OnlyNumbers<Type> = PickByPropertyType<Type, number>;

export const elevation = 3;

export interface PieDatum {
  id: string;
  value: number;
}

export interface Widget extends Omit<DashboardWidgetProps, 'children' | 'idx' | 'onDrop'> {
  component: FC<ChartProps>;
}


export interface ChartProps {
  date?: Dayjs | null;
}


export interface DashboardSearchParams {
  activityDays?: number;
  date: Dayjs | null;
  isReportView: boolean;
  trendMonths?: number;
  showAll: boolean;
}
export const useDashboardSearchParams = (): DashboardSearchParams => {
  const [ searchParams ] = useSearchParams();
  const date = useMemo( () => {
    const dateStr = searchParams.get( 'date' );
    if( !dateStr ) return null;
    const d = dayjs( dateStr );
    return d.isValid() ? d : null;
  }, [ searchParams ] );
  const isReportView = useMemo( () => searchParams.get( 'reportView' ) == 'true', [ searchParams ] );
  const showAll = useMemo( () => searchParams.get( 'showAll' ) == 'true', [ searchParams ] );
  const activityDays = useMemo( () => {
    try { return parseInt( searchParams.get( 'activityDays' ) ?? '' ) }
    catch { return }
  }, [ searchParams ] );
  const trendMonths = useMemo( () => {
    try { return parseInt( searchParams.get( 'trendMonths' ) ?? '' ) }
    catch { return }
  }, [ searchParams ] );
  return {
    activityDays,
    date,
    isReportView,
    showAll,
    trendMonths,
  }
}

export interface DashboardWidgetProps extends ChartProps {
  children: ReactElement;
  hidden?: boolean;
  resource?: string;
  action?: string;
  tag: string;
  label: string;
  idx: number;
  onDrop: OnDrop;
}

export const DashboardWidget: FC<DashboardWidgetProps> = ( props ) => {
  const { children, hidden, resource, action, date, tag, idx, onDrop } = props;
  const theme = useTheme();
  // useEffect( () => { console.log( `DashboardWidget ${ idx } ${ tag }` ) }, [ idx, tag ] );

  if( hidden ) return null;

  const widget = (
    <Grid item id={ `widget-${ tag }` } className='dashboardWidget' xs={ 'auto' }>
      <Box
        sx={ {
          height: 529,
          width: 532,
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.default,
          }
        } }
      >
        <DashboardWidgetDragDrop idx={ idx } onDrop={ onDrop } tag={ tag } disabled={ !!date }>
          { cloneElement( children, { date } ) }
        </DashboardWidgetDragDrop>
      </Box>
    </Grid>
  );

  if( action ) {
    return (
      <IfCanAccess action={ action } resource={ resource } >
        { widget }
      </IfCanAccess>
    );
  }

  return widget;

}
