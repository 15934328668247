import { assign } from "./utils";
import { Default } from "./default";

const Theme = {
  "themeName": "default-dark",
  "iconsSet": "v2",
  "isLight": false,
  "cssVariables": {
    "--sjs-layer-1-background-500": "#181818FF",
    "--sjs-layer-1-background-400": "#282828FF",
    "--sjs-layer-1-foreground-100": "#FFFFFFCC",
    "--sjs-layer-1-foreground-50": "#FFFFFF66",
    "--sjs-layer-3-background-500": "#202020FF",
    "--sjs-layer-3-foreground-100": "#FFFFFFCC",
    "--sjs-layer-3-foreground-50": "#FFFFFF66",
    "--sjs-layer-2-background-500": "#101010FF",
    "--sjs-layer-2-background-400": "#202020FF",
    "--sjs-special-haze": "#000000BF",
    "--sjs-border-25": "#303030FF",
    "--sjs-border-10": "#303030FF",
    "--sjs-primary-background-500": "#19B394FF",
    "--sjs-primary-background-10": "#19B3941A",
    "--sjs-primary-background-400": "#14A48BFF",
    "--sjs-primary-foreground-100": "#0C0C0CFF",
    "--sjs-primary-foreground-25": "#0C0C0C40",
    "--sjs-secondary-background-500": "#19B394FF",
    "--sjs-secondary-background-25": "#19B39440",
    "--sjs-secondary-background-10": "#19B3941A",
    "--sjs-secondary-foreground-100": "#0C0C0CFF",
    "--sjs-secondary-forecolor-25": "#0C0C0C40",
    "--sjs-semantic-red-background-500": "#FF6D9AFF",
    "--sjs-semantic-red-background-10": "#FF6D9A1A",
    "--sjs-semantic-red-foreground-100": "#0C0C0CFF",
    "--sjs-semantic-green-background-500": "#15CDABFF",
    "--sjs-semantic-green-background-10": "#15CDAB1A",
    "--sjs-semantic-green-foreground-100": "#0C0C0CFF",
    "--sjs-semantic-blue-background-500": "#66B4FCFF",
    "--sjs-semantic-blue-background-10": "#66B4FC1A",
    "--sjs-semantic-blue-foreground-100": "#0C0C0CFF",
    "--sjs-semantic-yellow-background-500": "#EDA925FF",
    "--sjs-semantic-yellow-background-10": "#EDA9251A",
    "--sjs-semantic-yellow-foreground-100": "#0C0C0CFF",
    "--sjs-semantic-white-background-500": "#FFFFFFFF",
    "--sjs-code-gray-700": "#5E5E5EFF",
    "--sjs-code-blue-500": "#53B3F0FF",
    "--sjs-code-gray-300": "#D1D1D1FF",
    "--sjs-code-green-500": "#37C7AAFF",
    "--sjs-code-red-500": "#FD6E89FF",
    "--sjs-code-purple-500": "#F888DFFF",
    "--sjs-code-yellow-500": "#FCC669FF",
    "--sjs-code-gray-500": "#909090FF",
    "--sjs-special-background": "#121212FF",
    "--sjs-layer-1-foreground-75": "#FFFFFFB3",
    "--sjs-layer-3-background-400": "#303030FF",
    "--sjs-special-glow": "#00000040",
    "--sjs-special-shadow": "#FFFFFF40",
    "--sjs-layer-3-foreground-75": "#FFFFFFB3",
    "--sjs-layer-2-foreground-100": "#FFFFFFCC",
    "--sjs-layer-2-foreground-75": "#FFFFFFB3",
    "--sjs-layer-2-foreground-50": "#FFFFFF66",
    "--sjs-border-25-overlay": "#FFFFFF26",
    "--sjs-secondary-background-400": "#14A48BFF"
  }
};
const themeCssVariables = {};
assign(themeCssVariables, Default.cssVariables, Theme.cssVariables);
assign(Theme, { cssVariables: themeCssVariables });

export default Theme;
export const DefaultDark = Theme;