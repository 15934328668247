import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton, Typography, useTheme } from '@mui/material';
import { BarDatum } from '@nivo/bar';
import { Pie, PieSvgProps } from '@nivo/pie';
import { animated } from '@react-spring/web';
import { sumBy } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { Loading, useGetList, useRedirect } from 'react-admin';
import { DashboardInfo } from './DashboardInfo';
import { makePieChartTooltip } from './DashboardTooltip';
import { ChartProps, elevation, PieDatum, useDashboardSearchParams } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import { DashboardTable } from './DashboardDatagrid';
import useColorPalettes from './use-color-palettes';
import usePatterns from './use-patterns';
import useSummary from './use-summary';


export const arcLabelWithOutline: PieSvgProps<PieDatum>[ 'arcLabelsComponent' ] = ( { label, style } ) => (
  <animated.g transform={style.transform} style={{ overflow: 'hidden' }}>
    <animated.text
      textAnchor="middle"
      dominantBaseline="central"
      stroke='black'
      strokeOpacity={0.8}
      strokeWidth={4}
      style={{
        fontSize: 14,
      }}
    > {label} </animated.text>
    <animated.text
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fill: '#fff',
        fontSize: 14,
      }}
    > {label} </animated.text>
  </animated.g>
);

export const DashboardReputationSummary: FC<ChartProps> = ( { date } ) => {
  const title = 'Reputation Summary';
  const theme = useTheme();
  const redirect = useRedirect();
  const isSmall = false;
  const cacheKey = 'dashboard-reputation-summary';
  const defaultDurationMonths = 6;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const { data: locations, isLoading: isLoadingLocations } = useGetList( 'locations', { filter: { status: 'active' } } );
  const [ showDatagrid, setShowDatagrid ] = useState( false );
  const { isReportView, trendMonths } = useDashboardSearchParams();

  const durationMonths = useMemo( () => {
    if( trendMonths ) return trendMonths;
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences, trendMonths ] );

  const summary = useSummary<BarDatum[]>( 'reviewTrend', { date, months: durationMonths } );

  const keys = [ '1 star', '2 stars', '3 stars', '4 stars', '5 stars' ];
  const data = useMemo( () => {
    const partial = ( summary.data || [] )
      .map( o => { const { id, ...rest } = o; return { ...rest }; } )
      // @ts-ignore:2769
      .reduce( ( tot, o ) => {
        for( const s in tot ) {
          // @ts-ignore:2365
          tot[ s ] += o[ s ] || 0;
        }
        return tot;
      }, { '1 star': 0, '2 stars': 0, '3 stars': 0, '4 stars': 0, '5 stars': 0 } );
    // console.log( partial );
    let _data = Object.entries( partial ).map( ( [ id, value ] ) => ( { id, value } ) ).reverse() as PieDatum[];
    if( sumBy( _data, d => d.value ) === 0 ) {
      // @ts-ignore:2322
      _data = [ { id: null, label: 'No data', value: true } ];
    }
    return _data;
  }, [ summary.data, isLoadingLocations, locations, date ] );

  const total = useMemo( () => data.reduce( ( tot, d ) => tot += d.value, 0 ), [ data ] );

  const tableData = useMemo( () => data.map( d => ( {
    id: d.id,
    Count: d.value,
    Percentage: total ? `${ ( d.value / total * 100 ).toFixed( 1 ) }%` : null,
  } ) ), [ data, total ] );

  const isEmpty = useMemo( () => data[ 0 ]?.id === null, [ data ] );

  const colorPalettes = useColorPalettes();
  const colors = useMemo( () => colorPalettes.spectrumGreenRed, [ colorPalettes ] );
  const patterns = usePatterns();

  return (

    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={ isReportView ?
          title :
          <Box sx={ { display: 'flex' } } >
            <Typography>{ title }</Typography>
            <DashboardInfo tag='reputation-summary'>
              <i>{ title }</i> shows the review ratings for all locations/practitioners within the organization.
              Clicking on the chart will redirect you to the Reviews page and show the actual reviews.
              Use the <TableIcon fontSize='small' sx={{ verticalAlign: 'bottom' }}/> Data View button to show the numerical data.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
            <IconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </IconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationMonths } Months Ending ${ date.format( 'MMM D, YYYY' ) }` : `Previous ${ durationMonths } Months` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      // avatar={ <DashboardAlertIcon /> }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { summary.isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative'
            } }
          >
            { showDatagrid
              ? (
                <DashboardTable
                  rows={ isEmpty ? [] : tableData }
                  columns={ [ { field: 'id', headerName: 'Rating', flex: 1 }, 'Count', 'Percentage' ] }
                  exportFileName={ cacheKey }
                  hideFooter
                />
              )
              : <>
                <Pie
                  width={ 500 }
                  height={ 400 }
                  data={ data }
                  margin={ { top: 0, right: 160, bottom: 10, left: 10 } }

                  onClick={ ( datum ) => {
                    const { id } = datum;
                    const [ ord ] = id.toString().split( ' ' ) as string[];
                    return redirect( 'list', `/reviews/reviews?filter=%7B"rating"%3A%5B${ ord }%5D%7D` );
                  } }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333', fontSize: 14, fontWeight: 'bold' } },
                    legends: { text: { fontSize: 14 } },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                      },
                    },
                  } }
                  tooltip={ makePieChartTooltip( theme, total ) }

                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors }
                  defs={ patterns.defs }
                  fill={ patterns.fill }

                  arcLabel={ ( d ) => `${ ( 100 * d.value / total ).toFixed( 0 ) }% (${ d.value })` }
                  arcLabelsRadiusOffset={ 0.6 }
                  arcLabelsComponent={ arcLabelWithOutline }

                  isInteractive={ !isEmpty }
                  enableArcLabels={ !isEmpty }
                  enableArcLinkLabels={ false }
                  arcLabelsSkipAngle={ 20 }

                  legends={ [
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 120,
                      translateY: -80,
                      itemsSpacing: 4,
                      itemWidth: 80,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1
                          }
                        }
                      ]
                    }
                  ] }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card>

  );

}
