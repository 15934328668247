import { Card, CardActions, CardContent, Paper, Typography } from '@mui/material';
import { useCanAccess } from '@react-admin/ra-rbac';
import { FC, ReactNode } from 'react';
import { Logout, Title } from 'react-admin';


export const CheckAccountAccess: FC<{ children: ReactNode }> = ( { children } ) => {
  const { isPending, canAccess: enabledAccess } = useCanAccess( { action: 'read', resource: 'locations' } );

  if( isPending ) return null;

  if( !enabledAccess ) return <AccountSuspended />;

  return children;
}

export const AccountSuspended: FC = () => {

  return (
    <Paper
      sx={ {
        minHeight: '20rem',
      } }
    >
      <Card
        sx={ {
          minHeight: '60rem',
          padding: '2rem',
          // width: '40rem',
        } }
      >
        <Title title="Analog Concierge Admin" />
        <CardContent>
          <Typography variant="h5" component="h2">
            Account suspended
          </Typography>
          <Typography component="p">
            Please contact your administrator for more information.
          </Typography>
        </CardContent>
        <CardActions>
          <Logout redirectTo='/auth' />
        </CardActions>
      </Card>
    </Paper >
  );
}
