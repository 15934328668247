import { InfoOutlined as InfoIcon, NorthEast as LaunchIcon } from '@mui/icons-material';
import { IconButton, IconButtonProps, Link, Tooltip, TooltipProps, Typography, useTheme } from '@mui/material';
import { FC, ReactNode, useMemo, useState } from 'react';

export type DashboardInfoProps = {
  children: ReactNode;
  placement?: TooltipProps['placement'];
  link?: URL;
  tag?: string;
  iconStyle?: IconButtonProps[ 'sx' ];
}
export const DashboardInfo: FC<DashboardInfoProps> = props => {
  const [ open, setOpen ] = useState( false );
  const theme = useTheme();
  const bgcolor = useMemo( () => theme.palette.mode == 'dark' ? '#444' : '#333', [ theme.palette.mode ] );
  const href = useMemo( () => {
    if( props.link ) return props.link.href;
    if( props.tag ) return `https://docs.analoginfo.com/link/54#bkmrk-${ props.tag }`;
    return undefined;
  }, [ props.link, props.tag ] );
  return (
    <Tooltip
      arrow
      disableHoverListener
      disableTouchListener
      onClose={ () => setTimeout( () => setOpen( false ), 150 ) }
      open={ open }
      placement={ props.placement }
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor,
            '& .MuiTooltip-arrow': { color: bgcolor },
          }
        }
      }}
      slotProps={{
        popper: {
          modifiers: [
            { name: 'offset', options: { offset: [ 0, -26 ] } },
          ],
        },
      }}
      title={
        <Typography variant='body2'>
          { props.children }
          { href
            ? (
              <Link
                href={ href }
                rel="noopener"
                target="_blank"
                display='block'
                color='#fff'
              >
                <span style={ { whiteSpace: 'nowrap' } } >
                  More
                  <LaunchIcon fontSize="inherit" sx={ { verticalAlign: 'middle' } } />
                </span>
              </Link>
            )
            : null
          }
        </Typography>
      }
    >
      <IconButton
        disableRipple
        sx={{
          fontSize: '90%',
          padding: '16px',
          marginLeft: '-12px',
          marginY: '-16px',
          ...props.iconStyle,
        }}
        onClick={ () => setOpen( v => !v ) }
      >
        <InfoIcon fontSize='inherit' />
      </IconButton>
    </Tooltip>
  );
};
