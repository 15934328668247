import EmailIcon from '@mui/icons-material/Email';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Tooltip, Tabs, Tab, ToggleButtonGroup, ToggleButton, Typography, Select, MenuItem, SelectChangeEvent, InputLabel, Checkbox, FormControlLabel } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { httpClient, apiUrl } from './DataProvider';
import { EmailsInput } from './EmailsInput';
import { RaRecord, useCreate, useDelete, useGetList, useNotify, useUpdate } from 'react-admin';
import { useUserPreference } from './UserPreferences';

const { REACT_APP_TENANT_ID: TENANT_ID } = process.env;

export interface DashboardReportEmailButtonProps {
  date?: Dayjs|null;
}
export const DashboardReportEmailButton: FC<DashboardReportEmailButtonProps> = props => {
  const [ open, setOpen ] = useState( false );
  const tabs = [ 'send', 'schedule' ];
  const [ tab, setTab ] = useState( tabs[ 0 ] );
  const resource = 'reputationreportschedules';
  const [ sendRecipients, setSendRecipients ] = useState<string[]>( [] );
  const [ scheduleRecipients, setScheduleRecipients ] = useState<string[]>( [] );
  const [ isValidInput, setIsValidInput ] = useState( true );
  const [ frequency, setFrequency ] = useState<'weeks'|'months'>( 'weeks' );
  const [ weeklySendOn, setWeeklySendOn ] = useState( 1 ); // Monday
  const [ monthlySendOn, setMonthlySendOn ] = useState( 1 );
  const [ sendNow, setSendNow ] = useState( true );
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const [ isLoading, setIsLoading ] = useState( false );
  const [ isDeleting, setIsDeleting ] = useState( false );
  const notify = useNotify();

  const onSuccess = () => { refetch(); setOpen( false ); }
  const onError = () => notify( 'ra.page.error', { type: 'error' } );
  const [ createOne ] = useCreate( resource, undefined, { onError, onSuccess, onSettled: () => setIsLoading( false ) } );
  const [ updateOne ] = useUpdate( resource, undefined, { onError, onSuccess, onSettled: () => setIsLoading( false ), mutationMode: 'pessimistic' } );
  const [ deleteOne ] = useDelete( resource, undefined, { onError, onSuccess, onSettled: () => setIsDeleting( false ), mutationMode: 'pessimistic' } );

  // @ts-ignore: 2339
  const tenantId = useMemo<string|undefined>( () => ( window.INSTANCE_DATA || { TENANT_ID } ).TENANT_ID, [ window.INSTANCE_DATA ] );

  const settings = useMemo( () => {
    const s: Record<string,unknown> = {};
    if( props.date ) s.date = props.date;
    if( !isLoadingPreferences && preferences?.dashboardActivityDays ) s.activityDays = preferences.dashboardActivityDays;
    if( !isLoadingPreferences && preferences?.dashboardTrendMonths ) s.trendMonths = preferences.dashboardTrendMonths;
    if( !isLoadingPreferences && preferences?.dashboardHiddenWidgets ) s.hiddenWidgets = preferences.dashboardHiddenWidgets;
    return s;
  }, [ props.date, preferences, isLoadingPreferences ] );

  const { data, isPending, refetch } = useGetList<RaRecord>( resource, {
    filter: {
      kind: 'tenant',
    },
  } );
  const scheduledReport = useMemo( () => !isPending && data?.length ? data[ 0 ] : undefined, [ data, isPending ] );
  useEffect( () => {
    setScheduleRecipients( scheduledReport?.recipients ?? sendRecipients );
  }, [ scheduledReport, sendRecipients ] );
  useEffect( () => {
    if( !scheduledReport ) {
      setSendNow( true );
      return;
    }
    setSendNow( false );
    setFrequency( scheduledReport.frequency.unit );
    const nextScheduledAt = new Date( scheduledReport.nextScheduledAt );
    setWeeklySendOn( nextScheduledAt.getDay() );
    setMonthlySendOn( nextScheduledAt.getDate() );
  }, [ scheduledReport ] );

  const sendOnOptions = useMemo( () => {
    if( frequency == 'months' ) return _.range( 1, 32 ).map( value => ( { value, label: `${value}` } ) );
    return [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ].map( ( label, value ) => ( { value, label } ) );
  }, [ frequency ] );

  const isScheduleModified = useMemo( () => {
    if( !scheduledReport || !scheduledReport.nextScheduledAt ) return true;
    if( scheduledReport.frequency.unit != frequency ) return true;
    if( _.difference( scheduleRecipients, scheduledReport.recipients ).length ) return true;
    if( _.difference( scheduledReport.recipients, scheduleRecipients ).length ) return true;
    const nextScheduledAt = new Date( scheduledReport.nextScheduledAt );
    if( frequency == 'weeks' && weeklySendOn != nextScheduledAt.getDay() ) return true;
    if( frequency == 'months' && monthlySendOn != nextScheduledAt.getDate() ) return true;
    return false;
  }, [ frequency, monthlySendOn, scheduleRecipients, scheduledReport, weeklySendOn ] );

  const scheduledAt = useMemo<Dayjs>( () => {
    let d = dayjs();
    d = frequency == 'months' ? d.date( monthlySendOn ) : d.day( weeklySendOn );
    if( sendNow ) {
      if( d.isBefore( new Date() ) ) return d;
      return d.subtract( 1, frequency );
    }
    if( d.isAfter( new Date() ) ) return d;
    return d.add( 1, frequency );
  }, [ frequency, monthlySendOn, sendNow, weeklySendOn ] );

  const handleClickOpen = () => setOpen( true );
  const handleClose = () => setOpen( false );

  const handleTabChange = ( _e: SyntheticEvent, newTab: string ) => {
    if( tabs.includes( newTab ) ) setTab( newTab );
  }

  const handleSendOnChange = useCallback( ( event: SelectChangeEvent<number> ) => {
    const newValue = event.target.value as number;
    frequency == 'weeks' ? setWeeklySendOn( newValue ) : setMonthlySendOn( newValue );
  }, [ frequency ] );

  const handleSend = useCallback( async () => {
    if( !tenantId ) return;
    try {
      await httpClient( `${ apiUrl }/reputationreports`, {
        method: 'POST',
        body: JSON.stringify( {
          kind: 'tenant',
          id: tenantId,
          recipients: sendRecipients,
          ...settings,
        } ),
      } );
      handleClose();
      notify( 'app.confirmation.report.send', { type: 'success' } );
    } catch {
      notify( 'ra.page.error', { type: 'error' } );
    }
  }, [ notify, sendRecipients, settings, tenantId ] );

  const handleSchedule = useCallback( async () => {
    setIsLoading( true );
    if( scheduledReport ) {
      updateOne( resource, {
        id: scheduledReport.id,
        data: {
          recipients: scheduleRecipients,
          nextScheduledAt: scheduledAt.toDate(),
          frequency: { unit: frequency, count: 1 },
        },
        previousData: scheduledReport,
      } );
      return;
    }
    createOne( resource, {
      data: {
        kind: 'tenant',
        tenant: tenantId,
        nextScheduledAt: scheduledAt.toDate(),
        recipients: scheduleRecipients,
        frequency: { unit: frequency, count: 1 },
      }
    } );
  }, [ frequency, refetch, scheduledAt, scheduledReport, scheduleRecipients, tenantId ] );

  const handleUnschedule = useCallback( async () => {
    if( !scheduledReport?.id ) return;
    setIsDeleting( true )
    deleteOne( resource, {
      id: scheduledReport.id,
    } );
  }, [ scheduledReport ] );

  return (
    <div>
      <Tooltip title='Manage Reports'>
        <IconButton onClick={ handleClickOpen }>
          <EmailIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={ open } onClose={ handleClose }>
        <DialogTitle>Manage Reports</DialogTitle>
        <DialogContent sx={ { width: '600px' } }>
          <Tabs value={ tab } onChange={ handleTabChange } >
            { tabs.map( t => <Tab value={ t } label={ t } key={ t } /> ) }
          </Tabs>

          {
            tab == 'send' ?
              <>
                <Typography marginTop={ 1 }>
                  Send a report with your selected charts{ props.date ? ` for ${ props.date.format( 'MMM D, YYYY' )}` : ''}.
                </Typography>
                <EmailsInput
                  value={ sendRecipients }
                  onChange={ setSendRecipients }
                  setValidity={ setIsValidInput }
                  tabIndex={ 0 }
                />
              </> :
              <>
                <Typography marginTop={ 1 }>
                  Schedule a recurring report with all available charts.
                </Typography>
                <EmailsInput
                  value={ scheduleRecipients }
                  onChange={ setScheduleRecipients }
                  setValidity={ setIsValidInput }
                  tabIndex={ 0 }
                />
                <ToggleButtonGroup
                  exclusive
                  value={ frequency }
                  onChange={ ( _e, value ) => setFrequency( value ) }
                >
                  <ToggleButton value='weeks'>Weekly</ToggleButton>
                  <ToggleButton value='months'>Monthly</ToggleButton>
                </ToggleButtonGroup>
                <InputLabel id='send-on-select-label'> Send On </InputLabel>
                <Select
                  labelId='send-on-select-label'
                  value={ frequency == 'weeks' ? weeklySendOn : monthlySendOn }
                  onChange={ handleSendOnChange }
                  MenuProps={ {
                    sx: { maxHeight: '400px' }
                  } }
                >
                  { sendOnOptions.map( o => <MenuItem value={ o.value } key={ o.label }>{ o.label }</MenuItem> ) }
                </Select>
                <FormControlLabel label='Send Report Now' control={
                  <Checkbox
                    checked={ sendNow }
                    onChange={ ( _e: unknown, checked ) => setSendNow( checked ) }
                    disableRipple
                    disableFocusRipple
                    sx={{
                      marginLeft: '1em',
                    }}
                  />
                } />
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={ handleClose }
            color='inherit'
            variant='contained'
            disableFocusRipple
            tabIndex={ 2 }
          >Cancel</Button>
          {
            tab == 'schedule' && scheduledReport ?
              <LoadingButton
                loading={ isDeleting }
                onClick={ handleUnschedule }
                color='error'
                variant='contained'
                disableFocusRipple
                disabled={ isLoading }
              >Delete</LoadingButton> :
              null
          }
          {
            tab == 'send' ?
              <Button
                onClick={ handleSend }
                color='primary'
                variant='contained'
                disableFocusRipple
                disabled={ !isValidInput || !sendRecipients.length }
                tabIndex={ 1 }
              >Submit</Button> :
              <LoadingButton
                loading={ isLoading }
                onClick={ handleSchedule }
                color='primary'
                variant='contained'
                disableFocusRipple
                disabled={ !isValidInput || !scheduleRecipients.length || ( !isScheduleModified && !sendNow ) || isDeleting }
                tabIndex={ 1 }
              >{ scheduledReport ? 'Update' : 'Schedule' }</LoadingButton>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}