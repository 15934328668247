import { Add as AddIcon, Delete as DeleteIcon, Email as EmailIcon, Fax, Help, HelpOutline, Lock, Phone as PhoneIcon, RecordVoiceOver, RemoveCircle as Unsubscribe, Send as SendIcon, Sms } from '@mui/icons-material';
import { Box, CardHeader, Checkbox, Grid, MenuItem, Select, SelectProps, SvgIconProps, Table, TableBody, TableCell, TableContainer, TableHead, TableHeadProps, TableRow, Theme, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';
import { CreateInDialogButton, EditInDialogButton, useFormDialogContext } from '@react-admin/ra-form-layout';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { capitalize } from 'inflection';
import { cloneDeep, get, pickBy, throttle, toPath } from 'lodash';
import chunk from 'lodash/chunk';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Datagrid, email, FieldProps, Form, FormDataConsumer, IconButtonWithTooltip, Labeled, RadioButtonGroupInput, RaRecord, RecordContextProvider, ReferenceManyField, SaveButton, SimpleForm, SimpleFormProps, TextField, TextInput, Toolbar, useGetIdentity, useGetList, useGetManyReference, useGetOne, useNotify, useRecordContext, useSaveContext, useUpdate, WithRecord } from 'react-admin';
import { AwesomePhoneField, AwesomePhoneInput, renderPhoneNumber } from './AwesomePhoneInput';
import { BooleanCheckboxInput } from './BooleanCheckboxInput';
import { ButtonWithConfirm } from './ButtonWithConfirm';
import { DatagridRowButton, ResourceActionHandler } from './DatagridRowButton';
import { DatagridRowButton2, ResourceActionHandler2 } from './DatagridRowButton2';
import { apiUrl, default as myDataProvider, httpClient } from './DataProvider';
import { LanguageField, LanguageInput, LanguageSelect, LanguageSelectProps } from './LanguageSelect';
import { ResourceContextProvider } from './ResourceContextProvider';
import { dump } from 'js-yaml';

export type CommunicationTypeFieldNames = 'disableAppointmentReminders' |
  'disablePatientRealTimeUpdates' |
  'disableAppointmentRealTimeUpdates' |
  'disableLabUpdates' |
  'disableOfficeCommunication' |
  'disablePractitionerCommunication' |
  'disablePrePostOpCareInstructions' |
  'disableBirthdays' |
  'disableMarketingUpdates' |
  'disableReviewReminders';

export interface FhirTelecom extends RaRecord {
  id: string;
  system: 'phone' | 'fax' | 'email' | 'pager' | 'url' | 'sms' | 'other';
  value: string;
  use: 'home' | 'work' | 'temp' | 'old' | 'mobile';
  rank: number;
}

export interface TelecomOption extends FhirTelecom {
  readonly invalidValue?: boolean;
  readonly unsubscribe?: boolean;
  readonly unsubscribeDate?: Date | string;
  readonly unsubscribeReason?: string;
  readonly forcedUse?: boolean; //Always Use entry
  readonly forcedIgnore?: boolean; //Always Skip this entry
  readonly forcedOverridesDate?: Date | string; // Date this entry put in place
  readonly forcedNoMms?: boolean; // sms only
  transmitMethod?: string;
  dataSource?: string; // 'directEntry' is the only editable one
  readonly carrierChecked?: boolean;
  readonly carrierCheckFailed?: boolean;
  readonly carrierCheckedDate?: Date | string;
  readonly carrierDataSupplier?: string;
  readonly carrierName?: string;
  readonly carrierLineType?: string;
  readonly carrierPhoneNumber?: string;
  readonly carrierNationalPhoneNumber?: string;
  readonly carrierCountryCode?: string;
  readonly carrierMobileCountryCode?: string | null; //Added variable to lookup normalized name of carrier - 07/09/2022 PL
  readonly carrierMobileNetworkCode?: string | null; // from third party lookup table.  Used for SMS Email.

}

export interface CommChannelsFound {
  annotatedTelecomOptions: ( TelecomOption | { value?: string; transmitMethod: string } )[];
}

export interface Option extends Record<string, unknown> {
  id: string;
  fullName: string;
  birthDate?: string;
  lastName?: string;
  firstName?: string;
  telecomOptions: TelecomOption[];
  commChannelsFound?: CommChannelsFound;
  lang?: string;
  identifier?: { id: string; type: string }[];
  ignoreEmrLang?: boolean;
}

export interface Unsubscribed extends Record<string, unknown> {
  id: string;
  comment: string;
  phone?: string;
  email?: string;
  createdAt: string;
  updatedAt: string;
}

export interface SubscriptionChanges extends Record<string, unknown> {
  id: string;
  action: string;
  comment: string;
  phone?: string;
  email?: string;
  createdAt: string;
  updatedAt: string;
}


export type EditorMode = 'recipients' | 'practitioners';
export const modeLabels: Record<EditorMode, string> = {
  recipients: 'Patient',
  practitioners: 'Practitioner',
}
export const labelOfMode = ( mode: EditorMode ): string => modeLabels[ mode ] || '';

export const splitArray = <T = unknown>( a: T[], chunks = 2 ): T[][] => {
  return chunk( a, Math.ceil( a.length / chunks ) );
}

export const TelecomOptionsValueField = ( props: FieldProps<TelecomOption> ) => {
  const { source } = props;
  const record = useRecordContext<TelecomOption>( props )
  const system = get( record, [ ...toPath( source ).slice( 0, -1 ), 'system' ], '' );

  if( !system ) return null;
  if( [ 'phone', 'fax', 'pager', 'sms', 'other' ].includes( system ) ) return <AwesomePhoneField source={ source } />;
  return <TextField source={ source } />;
}

export interface TelecomOptionsUnsubscribedFieldProps extends Omit<FieldProps<TelecomOption>, 'source'> {
  source?: string;
  unsubscribeds?: Unsubscribed[];
  undeliverables?: SubscriptionChanges[];
}

export const TelecomOptionsUnsubscribedField: FC<TelecomOptionsUnsubscribedFieldProps> = props => {
  const { source = 'value', unsubscribeds = [], undeliverables = [] } = props;
  const record = useRecordContext<TelecomOption>( props )
  const system = get( record, [ ...toPath( source ).slice( 0, -1 ), 'system' ], '' );
  const value = get( record, source, '' );
  const { unsubscribed, undeliverable } = useMemo( () => {
    // if( !system || !value || !unsubscribeds?.length ) return {};
    if( !system || !value ) return {};
    if( [ 'phone', 'fax', 'pager', 'sms', 'other' ].includes( system ) ) {
      const phone = parsePhoneNumber( value, { regionCode: 'US' } ).number?.e164;
      const invalid = get( record, 'invalidValue' );
      const unsubscribed = unsubscribeds.find( u => u.phone == phone );
      // const undeliverable = undeliverables.find( u => u.phone == phone );
      const undeliverable = !invalid
        ? undefined
        : {
          comment: dump( pickBy( record || {}, ( _v, k ) => k.startsWith( 'carrier' ) ) ),
          createdAt: get( record || {}, 'carrierCheckedDate' ),
        };
      return { unsubscribed, undeliverable };
    }
    const unsubscribed = unsubscribeds.find( u => u.email?.toLowerCase() == value.toLowerCase() );
    const undeliverable = undeliverables.find( u => u.email?.toLowerCase() == value.toLowerCase() );
    return { unsubscribed, undeliverable };
  }, [ system, value, unsubscribeds, undeliverables, record ] );

  // if( !unsubscribed && !undeliverable ) return null;

  if( undeliverable ) {
    const { comment, createdAt = new Date().toISOString() } = undeliverable;
    const date = new Date( createdAt ).toLocaleString();

    return (
      <Tooltip title={
        <Box>
          <Typography>value: { value }</Typography>
          { comment.split( /\n/ ).map( c => <Typography>{ c }</Typography> ) }
          <Typography>{ date }</Typography>
        </Box>
      }>
        <Box sx={ { whiteSpace: 'nowrap' } } >
          <Typography component='span' color='action'> Undeliverable </Typography> <Help color='action' fontSize='inherit' />
        </Box>
      </Tooltip>
    );
  }

  if( unsubscribed ) {
    const { comment, createdAt } = unsubscribed;
    const date = new Date( createdAt ).toLocaleString();
    let prettyComment = comment;
    try {
      prettyComment = dump( { value, date, ...pickBy( JSON.parse( comment ), a => !!a ) } );
    } catch( _e ) { } // eslint-disable-line no-empty

    return (
      <Tooltip title={
        <Box>
          <Typography component='pre'>{ prettyComment }</Typography>
        </Box>
      }>
        <Box sx={ { whiteSpace: 'nowrap' } } >
          <Typography component='span' color='action'> Unsubscribed </Typography> <Help color='action' fontSize='inherit' />
        </Box>
      </Tooltip>
    );
  }
  return null;

}

export const useTelecomOptionValues = ( ...recipients: ( Option | undefined )[] ) => {
  return useMemo<{ phones: string[], emails: string[] }>( () => {
    const telecomOptions = recipients.filter( ( r ): r is Option => !!r )
      .flatMap( r => r.telecomOptions );
    return {
      phones: telecomOptions.filter( o => [ 'phone', 'fax', 'pager', 'sms', 'other' ].includes( o.system ) )
        .map( o => parsePhoneNumber( o.value, { regionCode: 'US' } ).number?.e164 ).filter( ( s ): s is string => !!s ),
      emails: telecomOptions.filter( o => ![ 'phone', 'fax', 'pager', 'sms', 'other' ].includes( o.system ) )
        .map( o => o.value ).filter( ( s ): s is string => !!s ),
    };
  }, [ recipients ] );
};


export const NoDatagridHeader: FC<TableHeadProps> = () => {
  return ( <TableHead /> );
}


export interface TelecomEditorFormProps {
  id: string;
  resource: EditorMode;
}

const lookupPhone = async ( { phone }: { phone: string } ): Promise<Record<string, unknown> | undefined> => {
  const { json } = await httpClient( `${ apiUrl }/recipients/actions/lookup`, { method: 'POST', body: JSON.stringify( { phone } ) } );
  return json;
};
const throttledLookupPhone = throttle( lookupPhone, 2000, { leading: false, trailing: true } );


export const TelecomEditorForm: FC<TelecomEditorFormProps> = ( { id, resource } ) => {
  const isXSmall = useMediaQuery<Theme>( theme => theme.breakpoints.down( 'sm' ) );
  const notify = useNotify();
  const { data: record, refetch: refetchRecord, isLoading: isRecordLoading } = useGetOne<Option>( resource, { id }, { enabled: !!id } );
  const [ update, { isLoading: isUpdating } ] = useUpdate( undefined, {}, { mutationMode: 'pessimistic' } );
  const { identity } = useGetIdentity();
  const { data: recipients, refetch: refetchRecipients, isLoading: isRecipientsLoading } = useGetManyReference<Option>( 'recipients', { target: 'parent', id } );
  const { phones, emails } = useTelecomOptionValues( record, ...( recipients || [] ) );
  const { data: unsubscribedPhones, refetch: _refetchUnsubscribedPhones } = useGetList<Unsubscribed>( 'unsubscribeds', { pagination: { page: 1, perPage: 100 }, filter: { phone: phones.join( ';' ) } }, { enabled: !isRecordLoading && !isRecipientsLoading } );
  const { data: unsubscribedEmails, refetch: _refetchUnsubscribedEmails } = useGetList<Unsubscribed>( 'unsubscribeds', { pagination: { page: 1, perPage: 100 }, filter: { email: emails.join( ';' ) } }, { enabled: !isRecordLoading && !isRecipientsLoading } );
  // const { data: undeliverablePhones } = useGetList<SubscriptionChanges>( 'subscriptionchanges', { pagination: { page: 1, perPage: 100 }, filter: { phone: phones.join( ';' ), action: 'undeliverable' } }, { enabled: !isRecordLoading && !isRecipientsLoading } );
  const { data: undeliverableEmails } = useGetList<SubscriptionChanges>( 'subscriptionchanges', { pagination: { page: 1, perPage: 100 }, filter: { email: emails.join( ';' ), action: 'undeliverable' } }, { enabled: !isRecordLoading && !isRecipientsLoading } );
  const isMutating = useIsMutating();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const isLoading = useMemo( () => isUpdating || isMutating > 0, [ isUpdating, isMutating ] );

  const unsubscribeds = useMemo( () => {
    return [ ...( unsubscribedEmails || [] ), ...( unsubscribedPhones || [] ) ];
  }, [ unsubscribedEmails, unsubscribedPhones ] );

  const undeliverables = useMemo( () => undeliverableEmails || [], [ undeliverableEmails ] );

  const refetchAll = useCallback( async () => {
    await queryClient.invalidateQueries( {
      queryKey: [ 'recipients' ]
    } );  // for race conditions like AI-77
    await refetchRecord();
    await refetchRecipients();
  }, [ refetchRecipients, refetchRecord ] );

  const [ checkedIds, setCheckedIds ] = useState<Set<string>>( new Set() );
  const [ enableWarnings, setEnableWarnings ] = useState( false );
  useEffect( () => {
    if( !record ) return;
    setCheckedIds( new Set( record.telecomOptions
      .filter( o => !o.forcedIgnore )
      .map( o => o.id )
    ) );
    setEnableWarnings( true );
    if( !refetchRecipients ) return;
    refetchRecipients()
  }, [ record, setCheckedIds, refetchRecipients ] );

  const onTestContactOption = useCallback<ResourceActionHandler2<TelecomOption>>( async ( _resource, telecomOption, channel ) => {
    if( !record || !telecomOption ) return;
    const idx = record.telecomOptions.findIndex( o => o.id && o.id === telecomOption.id );
    const formatted = renderPhoneNumber( telecomOption.value ) || telecomOption.value || '';
    const transmitMethod = channel && channel.toLowerCase() || undefined;
    try {
      const _result = await myDataProvider.fetchJson( `${ myDataProvider.apiUrl }/${ resource }/${ id }/telecomoptions/${ telecomOption.id }/test`, {
        method: 'POST',
        body: JSON.stringify( { transmitMethod } ),
      } );
      notify( 'app.confirmation.test.contactMethod.success', {
        type: 'success',
        autoHideDuration: 10000,
        messageArgs: {
          formatted,
        }
      } );
    } catch( e: unknown ) {
      console.log( `ERROR test ${ telecomOption.system } ${ telecomOption.value } idx ${ idx }`, e );
      const error = e && typeof e === 'object' && get( e, 'message' ) || e;
      notify( 'app.confirmation.test.contactMethod.error', {
        type: 'error',
        autoHideDuration: 10000,
        messageArgs: {
          formatted,
          error,
        },
      } )

    }
  }, [ record, id, resource, notify ] );

  const onDeleteRecipient = useCallback<ResourceActionHandler>( async ( resource, data ) => {
    if( !record || !data ) return;
    const { id } = data;
    console.log( `delete a ${ resource } named ${ record.fullName }` );
    data.parent = null;
    data.active = false;
    notify( 'app.confirmation.delete.recipient', { type: 'success', autoHideDuration: 5000 } );
    await update( resource, { id, data }, { returnPromise: true } );
    await refetchAll();
  }, [ record, refetchAll, update ] );

  const unsubscribeAllRecipients = useCallback( async ( recipients?: Option[] ) => {
    if( !recipients ) return;
    for( const recipient of recipients ) {
      const { id } = recipient;
      const data = cloneDeep( recipient );
      for( const { field } of commTypes ) {
        data[ field ] = true;
      }
      await update( resource, { id, data, previousData: recipient }, { returnPromise: true } );
    }
    await refetchAll();
  }, [ refetchAll, update ] );

  const updateMode = useCallback( async ( optionId: string | undefined, mode: Mode | string, transmitMethod: string | undefined ) => {
    if( !record || !optionId ) return;
    const data = cloneDeep( record );
    const idx = data.telecomOptions.findIndex( o => o.id === optionId );
    if( idx < 0 ) return;
    const { system } = data.telecomOptions[ idx ];
    data.telecomOptions[ idx ] = {
      ...data.telecomOptions[ idx ],
      forcedIgnore: mode == 'ignore',
      forcedUse: mode == 'use',
      system: mode == 'use' ? ( transmitMethod == 'sms' ? transmitMethod : transmitMethod == 'voice' ? 'phone' : system ) : system,
      transmitMethod: mode == 'use' ? transmitMethod : 'unknown',
      forcedOverridesDate: new Date().toISOString(),
    };
    await update( resource, { id, data, previousData: record }, { returnPromise: true } );
    await refetchAll();
  }, [ record, refetchAll, update ] );

  const unsubscribeAllContactMethods = useCallback( async ( record: Option ) => {
    // if( !record ) return;
    const data = cloneDeep( record );
    data.telecomOptions = data.telecomOptions.map( o => ( {
      ...o,
      forcedIgnore: true,
      forcedOverridesDate: new Date(),
    } ) );
    await update( resource, { id, data, previousData: record }, { returnPromise: true, mutationMode: 'pessimistic' } );
    await refetchAll();
  }, [ /* record, */ update, refetchAll ] );

  const onDeleteContactMethod = useCallback<ResourceActionHandler<TelecomOption>>( async ( _resource, telecomOption ) => {
    if( !record || !telecomOption ) return;
    const data = cloneDeep( record );
    const idx = data.telecomOptions.findIndex( o => o.id === telecomOption.id );
    if( idx < 0 ) return;
    data.telecomOptions.splice( idx, 1 );
    const formatted = renderPhoneNumber( telecomOption.value ) || telecomOption.value || '';
    await update( resource, { id, data, previousData: record }, { returnPromise: true } );
    notify( 'app.confirmation.delete.contactMethod', { type: 'success', autoHideDuration: 5000, messageArgs: { formatted } } );
    await refetchAll();
  }, [ record, update, notify, refetchAll ] );

  const onUnsubscribeAll = useCallback( async ( record: Option ) => {
    await unsubscribeAllContactMethods( record );
    notify( 'app.confirmation.unsubscribe.allContactMethods', { type: 'success', autoHideDuration: 5000 } );
  }, [] );

  const onUnsubscribeAllRecipients = useCallback( async ( recipients?: Option[] ) => {
    await unsubscribeAllRecipients( recipients );
    notify( 'app.confirmation.unsubscribe.allRecipients', { type: 'success', autoHideDuration: 5000 } );
  }, [] );

  const TelecomOptionCapabilityIcons: FC<{ source?: string; color?: SvgIconProps[ 'color' ] }> = ( { color } ) => {
    const option = useRecordContext();
    if( !option ) return null;
    const { system, carrierLineType: lineType, value, transmitMethod } = option;
    const props = { color, className: 'capabilityIcon' };
    if( system ) {
      if( system == 'email' ) return <Tooltip title="Email"><EmailIcon { ...props } /></Tooltip>;
      if( system == 'fax' ) return <Tooltip title="Fax"><Fax { ...props } /></Tooltip>;
      if( lineType ) {
        if( [ 'mobile', 'prepaid' ].includes( lineType ) ) {
          const annotated = record?.commChannelsFound?.annotatedTelecomOptions.find( a => a.value == value );
          const { transmitMethod: annotatedTransmitMethod } = annotated || {};
          if( transmitMethod == 'voice' || annotatedTransmitMethod == 'voice' ) return <Tooltip title="Voice"><RecordVoiceOver { ...props } /></Tooltip>;
          return <Tooltip title="SMS Text"><Sms { ...props } /></Tooltip>;
        }
        if( [ 'voip', 'landline' ].includes( lineType ) ) return <Tooltip title="Voice"><RecordVoiceOver { ...props } /></Tooltip>;
      }
    }
    return <Tooltip title="Voice"><HelpOutline { ...props } /></Tooltip>;
  };

  const TelecomOptionLineType: FC<{ source?: string }> = () => {
    const record = useRecordContext();
    if( !record ) return null;
    const { system, carrierLineType: lineType } = record;
    if( !system ) return null;
    if( lineType == 'other' ) return <>Landline</>;
    if( lineType == 'voip' ) return <>Landline</>;
    if( lineType ) return <>{ capitalize( lineType ) }</>;
    return <>{ capitalize( system ) }</>;
  };

  const onModeChange = useCallback( ( id: string | undefined, mode: string ) => {
    if( !id ) return;
    const updated = new Set( checkedIds );
    updated[ mode == 'ignore' ? 'delete' : 'add' ]( id );
    setCheckedIds( updated );
  }, [ checkedIds, setCheckedIds ] );

  type Mode = 'auto' | 'use' | 'ignore';
  type ModeSelect = 'auto' | 'use' | 'ignore' | 'use:sms' | 'use:voice';

  const ModeSelect: FC<{ source?: string, onChanged?: ( id: string | undefined, mode: string ) => void }> = ( { onChanged } ) => {
    const record = useRecordContext<TelecomOption>();
    if( !record ) return null;
    const { id, system, value, forcedIgnore, forcedUse, transmitMethod, carrierLineType } = record;
    const formatted = system == 'phone' ? renderPhoneNumber( value ) : value || '';
    const getActiveLabel = ( active: boolean ) => active ? 'Active' : 'Not active';
    const [ tooltip, setTooltip ] = useState( getActiveLabel( !forcedIgnore ) );

    const mode = useMemo<ModeSelect>( () => {
      if( !forcedIgnore && !forcedUse ) return 'auto';
      if( forcedIgnore ) return 'ignore';
      if( carrierLineType != 'mobile' ) return 'use';
      if( transmitMethod == 'voice' ) return 'use:voice';
      return 'use:sms';
      // return forcedIgnore ? 'ignore' : forcedUse ? ( transmitMethod == 'sms' ? 'use:sms' : transmitMethod == 'voice' ? 'use:voice' : 'use' ) : 'auto';
    }, [ forcedUse, forcedIgnore, transmitMethod, carrierLineType, system ] );

    const onChange = useCallback<NonNullable<SelectProps<ModeSelect>[ 'onChange' ]>>( async ( { target: { value } } ) => {
      const [ mode, transmitMethod ] = value.split( ':' );
      await updateMode( id, mode, transmitMethod );
      notify( `app.confirmation.value.${ mode }`, {
        type: 'success',
        autoHideDuration: 5000,
        messageArgs: { formatted }
      } );
      await refetchRecord();
      setTooltip( capitalize( mode ) );
      onChanged && onChanged( id, mode );
    }, [ record?.id, notify, record ] );

    const StyledMenuItem = styled( MenuItem )( () => ( {
      fontSize: '90%',
    } ) );

    return (
      <Tooltip title={ tooltip } placement='right'>
        <Select
          variant='standard'
          defaultValue={ mode }
          label='Mode'
          onChange={ onChange }
          sx={ {
            fontSize: '90%',
          } }
          disabled={ isLoading }
        >
          <StyledMenuItem value='auto' disabled={ isLoading }>AUTO</StyledMenuItem>
          { record.carrierLineType == 'mobile' &&
            <StyledMenuItem value='use:sms' disabled={ isLoading }>USE SMS</StyledMenuItem>
          }
          { record.carrierLineType == 'mobile' &&
            <StyledMenuItem value='use:voice' disabled={ isLoading }>USE VOICE</StyledMenuItem>
          }
          { record.carrierLineType != 'mobile' &&
            <StyledMenuItem value='use' disabled={ isLoading }>USE</StyledMenuItem>
          }
          <StyledMenuItem value='ignore' disabled={ isLoading }>IGNORE</StyledMenuItem>
        </Select>
      </Tooltip>
    );
  }

  type CommType = { label: string, field: CommunicationTypeFieldNames };
  const resourceCommTypes: Record<'recipients' | 'practitioners', CommType[]> = {
    recipients: [
      { label: 'Appointment Reminders', field: 'disableAppointmentReminders' },
      { label: 'Real-Time Appointment Status', field: 'disableAppointmentRealTimeUpdates' },
      { label: 'Real-Time Patient Status', field: 'disablePatientRealTimeUpdates' },
      { label: 'Practitioner Communications', field: 'disablePractitionerCommunication' },
      { label: 'Pre-/Post-Op Care Instructions', field: 'disablePrePostOpCareInstructions' },
      { label: 'Pathology/Lab Updates', field: 'disableLabUpdates' },
      { label: 'Real-Time Office Alerts', field: 'disableOfficeCommunication' },
      { label: 'Feedback/Review Reminders', field: 'disableReviewReminders' },
      { label: 'Birthday Greetings', field: 'disableBirthdays' },
      { label: 'General Information', field: 'disableMarketingUpdates' },
    ],
    practitioners: [
      { label: 'Office Communication', field: 'disableOfficeCommunication' },
      { label: 'Appointment Real Time Updates', field: 'disableAppointmentRealTimeUpdates' },
      { label: 'Patient Real Time Updates', field: 'disablePatientRealTimeUpdates' },
      { label: 'Lab Updates', field: 'disableLabUpdates' },
    ]
  };
  const commTypes = useMemo( () => resourceCommTypes[ resource || 'recipients' ], [ resource, resourceCommTypes ] );

  const recipientsSubcriptions = useMemo( () => {
    if( !recipients ) return 0;
    return recipients.reduce( ( count, recipient ) => {
      return commTypes.reduce( ( count, commType ) => count += recipient[ commType.field ] ? 0 : 1, 0 );
    }, 0 );
  }, [ recipients, commTypes ] );

  const activateCommunicationType = useCallback( ( commType: CommType | undefined ) => async ( _e: unknown, value: boolean ) => {
    if( !record || !commType ) return;
    const { field, label } = commType;
    // Flipping pattern here for optimistic data change
    const previousData = cloneDeep( record );
    record[ field ] = !value;
    await update( resource, { id, data: record, previousData }, { returnPromise: true } );
    notify( `Saved ${ label } ${ value ? 'on' : 'off' }`, { type: 'success' } ); // TODO i18n
    await refetchAll();
  }, [ record, refetchAll, update ] );

  const StyledTableRow = styled( TableRow )( () => ( {
    [ `&.${ tableRowClasses.root }` ]: {
      // '& td, & th': { border: 0 },
      '& th': { border: 0 },
      '& button': { color: 'hsl(0deg 0% 0% / 50%)' },
      [ `&.${ tableRowClasses.hover }` ]: {
        '&:hover': { backgroundColor: 'inherit' },
      },

    },
    [ `&.${ tableRowClasses.hover }` ]: {
      '&:hover button': { color: 'hsl(0deg 0% 0% / 50%)' },
    },
  } ) );

  const StyledBaseTableCell = styled( TableCell )( ( { theme } ) => ( {
    [ `&.${ tableCellClasses.head }` ]: {
      backgroundColor: theme.palette.common.white,
      color: 'hsl( 0 0% 0% /26% )', //theme.palette.augmentColor,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    [ `&.${ tableCellClasses.body }` ]: {
      fontSize: isXSmall ? 16 : 18,
      // paddingRight: '4em',
      borderBottomColor: 'transparent',
    },
  } ) );

  const StyledTableCell = styled( StyledBaseTableCell )( ( /* {theme} */ ) => ( {
    [ `&.${ tableCellClasses.body }` ]: {
      paddingRight: isXSmall ? '2rem' : '4rem',
    },
  } ) );

  const PatientRecipientForm: FC = () => {
    const { save, saving } = useSaveContext();
    const record = useRecordContext();
    if( !record ) return null;
    const [ prep, setPrep ] = useState( false );

    // Enforce [0] phone [1] email
    useEffect( () => {
      // if( record.telecomPreference && record.telecomOptions.length >= 2 ) return;
      if( prep ) return;
      if( saving ) {
        setPrep( true ); // WHY DOES PREP GET RESET ON saving??
        return;
      }
      const origOptions = [ ...record.telecomOptions ];
      const phone = origOptions.find( o => [ 'phone', 'sms' ].includes( o.system ) ) || { system: 'phone', value: '' };
      const email = origOptions.find( o => o.system == 'email' ) || { system: 'email', value: '' };
      record.telecomOptions = [ phone, email ];
      record.comment = `Edit via Admin App by ${ identity?.fullName } (${ identity?.id })`;
      record.telecomPreference = record.telecomOptions[ 1 ].rank == 1 ? 'email' : record.telecomOptions[ 0 ].system == 'sms' ? 'sms' : 'phone';
      setPrep( true );
    }, [ record, saving, prep, setPrep ] );

    return (
      <Form
        record={ record }
        warnWhenUnsavedChanges
        validate={ async ( data ) => {
          const phoneField = 'telecomOptions.0.value';
          const emailField = 'telecomOptions.1.value';
          const typeField = 'telecomPreference';
          if( !data.telecomOptions[ 0 ].value && !data.telecomOptions[ 1 ].value ) {
            return {
              [ phoneField ]: 'Must have at least one phone or email.',
              [ emailField ]: 'Must have at least one email or phone.',
            };
          }
          if( !data.telecomOptions[ 0 ].value ) return {};
          const { value, system } = data.telecomOptions[ 0 ];
          const info = await throttledLookupPhone( { phone: value } );
          const type = get( info || {}, 'carrier.type', 'unknown' ) as string;
          if( [ 'invalid', 'unknown' ].includes( type ) ) {
            return { [ phoneField ]: 'This number is invalid.' };
          }
          if( system == 'sms' && ![ 'mobile', 'prepaid' ].includes( type ) ) {
            return { [ typeField ]: `Selecting SMS for this ${ type } number is invalid.` };
          }
          return {};
        } }
        // @ts-ignore: 7006
        onSubmit={ async ( data ) => {
          if( !save ) return;
          if( data.telecomPreference ) {
            data.telecomOptions[ data.telecomPreference == 'email' ? 1 : 0 ].rank = 1;
            data.telecomOptions[ data.telecomPreference == 'email' ? 0 : 1 ].rank = 2;
            data.telecomOptions[ 0 ].system = data.telecomPreference != 'email' ? data.telecomPreference : 'phone';
            data.telecomOptions[ 0 ].forcedUse = data.telecomPreference != 'email';
            data.telecomOptions[ 0 ].forcedIgnore = data.telecomPreference == 'email';
            data.telecomOptions[ 0 ].dataSource = 'directEntry';
            data.telecomOptions[ 0 ].forcedOverridesDate = new Date().toISOString();
            data.telecomOptions[ 1 ].forcedUse = data.telecomPreference == 'email';
            data.telecomOptions[ 1 ].forcedIgnore = data.telecomPreference != 'email';
            data.telecomOptions[ 1 ].dataSource = 'directEntry';
            data.telecomOptions[ 1 ].forcedOverridesDate = new Date().toISOString();
            data.telecomPreference = undefined;
          }
          const errors = await save( data );
          if( errors ) return errors;
        } }

      >
        <Grid container >
          <Grid item container sx={ { padding: '1rem' } } xs={ 12 }>
            <FormDataConsumer >
              { ( { formData } ) => (

                <Grid item container xs={ 12 }>
                  <Grid item xs={ 12 }>
                    <TextInput label="Name" source="fullName" fullWidth required />
                  </Grid>
                  <Grid item xs={ 12 }>
                    <LanguageInput label='Preferred language' source='lang' />
                  </Grid>
                  <Grid item container xs={ 12 } spacing={ 2 } justifyContent='flex-start' >

                    <Grid item xs={ 12 } >
                      <AwesomePhoneInput label="Phone" source="telecomOptions.0.value" fullWidth />
                    </Grid>
                    <Grid item xs={ 12 }>
                      <TextInput label="Email" source="telecomOptions.1.value" fullWidth validate={ email() } />
                    </Grid>
                    <Grid item xs={ 12 } mt={ -1 } >
                      { prep && formData.telecomOptions[ 0 ].value &&
                        <RadioButtonGroupInput
                          label='Preference'
                          source="telecomPreference"
                          row
                          helperText={ false }
                          choices={ [
                            ...[
                              { id: 'phone', name: 'Phone/Voice' },
                              { id: 'sms', name: 'SMS/Text' },
                            ],
                            ...( formData.telecomOptions[ 1 ].value ? [ { id: 'email', name: 'Email' } ] : [] ),
                          ] }
                          sx={ {
                            marginTop: 0
                          } }
                        />
                      }
                    </Grid>
                  </Grid>

                </Grid>
              ) }
            </FormDataConsumer>

            <Labeled label='Communication types'
              sx={ {
                '& .RaLabeled-label': {
                  fontSize: '0.85em',
                }
              } }
            >
              <Grid item>



                { splitArray( commTypes, 2 ).map( ( commTypes, column ) => (
                  <TableContainer key={ column } sx={ { width: 'fit-content', display: 'inline-block', verticalAlign: 'top' } }>
                    <Table size='small' padding='none' aria-label='selected recipient communications table' >
                      <TableBody>
                        { commTypes.map( ( row, idx ) => (
                          <StyledTableRow
                            key={ `row${ idx }` }
                            hover={ true }
                          >
                            <StyledBaseTableCell scope='row' >
                              <BooleanCheckboxInput label={ row.label } source={ row.field } parse={ v => !v } format={ v => !v }
                                disabled={ isLoading }
                              />
                            </StyledBaseTableCell>
                          </StyledTableRow>
                        ) ) }
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) ) }

              </Grid>
            </Labeled>
          </Grid>
          <Grid item xs={ 12 }>
            <Toolbar>
              <SaveButton />
            </Toolbar>
          </Grid>
        </Grid>
      </Form >
    );
  }

  const PatientRecipientPreferredCapabilityIcon: FC<{ source?: string; color?: SvgIconProps[ 'color' ] }> = ( { color } ) => {
    const recipient = useRecordContext();
    const props = { color, className: 'capabilityIcon' };
    if( !recipient ) return null;
    if( recipient.telecomOptions[ 1 ]?.value && recipient.telecomOptions[ 1 ]?.rank === 1 ) {
      return <Tooltip title="Email"><EmailIcon { ...props } /></Tooltip>;
    }
    if( recipient.telecomOptions[ 0 ]?.value ) {
      if( recipient.telecomOptions[ 0 ]?.system == 'sms' ) {
        return <Tooltip title="SMS Text"><Sms { ...props } /></Tooltip>;
      }
      return <Tooltip title="Voice"><RecordVoiceOver { ...props } /></Tooltip>;
    }
    return <Tooltip title="Voice"><HelpOutline { ...props } /></Tooltip>;
  };

  const [ lang, setLang ] = useState( '' );
  useEffect( () => {
    if( !record?.lang ) return;
    setLang( record.lang );
  }, [ record?.lang, setLang ] );
  const langChange = useCallback<NonNullable<LanguageSelectProps[ 'onChange' ]>>( async ( e ) => {
    const { value } = e.target;
    setLang( value );
    if( !record ) return;
    const previousData = cloneDeep( record );
    record.lang = value;
    record.ignoreEmrLang = true;
    await update( resource, { id, data: record, previousData }, { returnPromise: true } );
    notify( `Saved preferred language`, { type: 'success' } );
    await refetchAll();
  }, [ setLang, record, refetchAll, update ] );

  // const identifiers = ( record?.identifier || [] ).map( i => i.id ).join( '/' );


  if( !record ) return null;

  return (
    <ResourceContextProvider value='recipients'>
      <RecordContextProvider value={ record } >
        <Grid container
          direction='column'
          alignItems='flex-start'
          sx={ {
            '& .addButton': {
              marginLeft: '-6px',
              marginRight: '20px',
            },
            '& .capabilityIcon': {
              verticalAlign: 'bottom',
            },
          } }
        >
          { enableWarnings && checkedIds.size === 0 &&
            <Grid item container
              direction='column'
              alignItems='flex-start'
              xs={ 6 }
            >
              <Box
                sx={ {
                  borderStyle: 'solid',
                  borderWidth: '4px',
                  borderColor: theme.palette.error.main || 'red',
                  padding: '1rem',
                  marginBottom: '2rem',
                  maxWidth: '600px',
                } }
              >
                <Typography variant='h5' color='error' sx={ { fontWeight: 'bold' } }  >
                  WARNING!
                </Typography>
                <Typography variant='body1' sx={ { fontWeight: 'bold' } } >
                  { modeLabels[ resource ] } has no active contact methods and will not receive any communications. Change the state of any method from IGNORE to another value to resolve this message.
                </Typography>
              </Box>
            </Grid>
          }

          { resource != 'practitioners' && enableWarnings && recipients?.length === 0 &&
            <Grid item container
              direction='column'
              alignItems='flex-start'
              xs={ 6 }
            >
              <Box
                sx={ {
                  borderStyle: 'solid',
                  borderWidth: '4px',
                  borderColor: theme.palette.error.main || 'red',
                  padding: '1rem',
                  marginBottom: '2rem',
                  maxWidth: '600px',
                } }
              >
                <Typography variant='h5' color='error' sx={ { fontWeight: 'bold' } }  >
                  WARNING!
                </Typography>
                <Typography variant='body1' sx={ { fontWeight: 'bold' } } >

                  Patient has no 3rd party, authorized message recipients. <br />


                </Typography>
              </Box>
            </Grid>
          }

          { resource == 'recipients' &&
            <Grid item container
              direction='column'
              alignItems='flex-start'
              xs={ 6 }
            >
              <CardHeader
                title={ `MRN Identifiers` }
                sx={ {
                  margin: 0,
                  padding: 0,
                  textTransform: 'uppercase'
                } }
                titleTypographyProps={ {
                  fontWeight: 'bold',
                  fontSize: '1.25rem',
                  letterSpacing: '0.1rem',
                } }
                subheaderTypographyProps={ {
                  fontSize: '0.9rem',
                  color: 'gray',
                } }
              />
              { ( record.identifier || [] ).map( i => (
                <Tooltip key={ `${ i.id }:${ i.type }` } title={ i.type } id={ i.id } arrow placement='right-start' >
                  <Typography key={ i.id }>{ i.id }</Typography>
                </Tooltip>
              ) ) }
            </Grid>
          }

          { resource == 'recipients' &&
            <Grid item container
              direction='column'
              alignItems='flex-start'
              xs={ 6 }
            >
              <CardHeader
                title={ `${ labelOfMode( resource ) } preferred language` }
                subheader={ `Used for email and sms communications` }
                sx={ {
                  marginTop: '2em',
                  padding: 0,
                  textTransform: 'uppercase'
                } }
                titleTypographyProps={ {
                  fontWeight: 'bold',
                  fontSize: '1.25rem',
                  letterSpacing: '0.1rem',
                } }
                subheaderTypographyProps={ {
                  fontSize: '0.9rem',
                  color: 'gray',
                } }
              />
              <Box>
                <LanguageSelect lang={ lang } onChange={ langChange } />
                { record.ignoreEmrLang &&
                  <Typography component='span'
                    sx={ {
                      marginLeft: '1rem',
                      fontSize: '0.9rem',
                      color: 'gray',
                    } } > Overrides EMR </Typography>
                }
              </Box>
            </Grid>
          }

          <Grid item container
            direction='column'
            alignItems='flex-start'
            xs={ 6 }
          >
            <CardHeader
              title={ `${ labelOfMode( resource ) } contact methods` }
              subheader={ `Active: ${ checkedIds.size } ${ record.telecomOptions.length > 0 ? `of ${ record.telecomOptions.length }` : '' }` }
              sx={ {
                marginTop: '2em',
                padding: 0,
                textTransform: 'uppercase'
              } }
              titleTypographyProps={ {
                fontWeight: 'bold',
                fontSize: '1.25rem',
                letterSpacing: '0.1rem',
              } }
              subheaderTypographyProps={ {
                fontSize: '0.9rem',
                color: 'gray',
              } }
            />

            <Datagrid
              rowClick={ () => { return false; } /* TODO remove click handler when this is fixed -> https://github.com/marmelab/react-admin/issues/10100 */ }
              data={ record.telecomOptions }
              bulkActionButtons={ false }
              header={ <NoDatagridHeader /> }
              sx={ {
                '& .RaDatagrid-table': {
                  width: 'inherit',
                  marginLeft: '-12px',  // -leftPadding of rowCell
                },
                '& .RaDatagrid-rowCell': {
                  borderBottomWidth: 0,
                  padding: '0px 12px',
                  '& button': {
                    padding: 1,
                  },
                },
                '& .column-mode .MuiSelect-select': {
                  width: '5rem',
                },
                '& .column-use': {
                  xs: { display: 'none' },
                  sm: { display: 'table-cell' },
                },
                '& .column-capabilityIcons, & .column-optionLineType': {
                  xs: { display: 'none' },
                  sm: { display: 'none' },
                  md: { display: 'table-cell' },
                },
                '& .column-moveUp, & .column-moveDown': {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
                '& .column-undefined': {
                  width: '1.5em',
                  '&:first-of-type': {
                    paddingLeft: 0,
                  },
                },
              } }
            >

              <ModeSelect source='mode' onChanged={ onModeChange } />

              <TelecomOptionsValueField source='value' />

              <TelecomOptionLineType source='optionLineType' />

              <TelecomOptionCapabilityIcons color='action' source="capabilityIcons" />

              <WithRecord render={ ( telecomOption ) => {
                const resource = 'telecomoptions';
                if( telecomOption.forcedIgnore ) return <></>;
                if( [ 'mobile', 'prepaid' ].includes( telecomOption.carrierLineType ) ) {
                  return (
                    <DatagridRowButton2<TelecomOption>
                      resource={ resource } record={ telecomOption }
                      confirm
                      confirmTitle={ ( record ) => !record ? '' : `Test "${ renderPhoneNumber( record.value ) || record.value }"` }
                      confirmContent={ ( record ) => !record ? '' : ( <Typography>Test contact by sending a message to <Box component='span' sx={ { whiteSpace: 'nowrap' } }>"{ renderPhoneNumber( record.value ) || record.value }"?</Box></Typography> ) }
                      label="Test send"
                      className='rowTestSendButton'
                      onClick={ onTestContactOption }
                      options={ [ 'SMS', 'Voice' ] }
                      value='SMS'
                      disabled={ isLoading }
                    >
                      <SendIcon fontSize='small' />
                    </DatagridRowButton2>

                  );
                }
                if( [ 'landline', 'voip' ].includes( telecomOption.carrierLineType ) ) {
                  return (
                    <DatagridRowButton<TelecomOption>
                      resource={ resource } record={ telecomOption }
                      confirm
                      confirmTitle={ ( record ) => !record ? '' : `Test "${ renderPhoneNumber( record.value ) || record.value }"` }
                      confirmContent={ ( record ) => !record ? '' : ( <Typography>Test contact by making a call to <Box component='span' sx={ { whiteSpace: 'nowrap' } }>"{ renderPhoneNumber( record.value ) || record.value }"?</Box></Typography> ) }
                      label="Test send"
                      className='rowTestSendButton'
                      onClick={ onTestContactOption }
                      disabled={ isLoading }
                    >
                      <SendIcon fontSize='small' />
                    </DatagridRowButton>

                  );
                }
                return (
                  <DatagridRowButton<TelecomOption>
                    resource={ resource } record={ telecomOption }
                    confirm
                    confirmTitle={ ( record ) => !record ? '' : `Test "${ record.value }"` }
                    confirmContent={ ( record ) => !record ? '' : ( <Typography>Test contact by sending a message to <Box component='span' sx={ { whiteSpace: 'nowrap' } }>"{ record.value }"?</Box></Typography> ) }
                    label="Test send"
                    className='rowTestSendButton'
                    onClick={ onTestContactOption }
                    disabled={ isLoading }
                  >
                    <SendIcon fontSize='small' />
                  </DatagridRowButton>
                );
              } } />

              <WithRecord render={ ( telecomOption ) => {
                if( telecomOption.dataSource != 'directEntry' ) {
                  return <IconButtonWithTooltip
                    label='Locked'
                    onClick={ () => { return; } }
                  >
                    <Lock fontSize='small' />
                  </IconButtonWithTooltip>;
                }
                return (
                  <DatagridRowButton<TelecomOption>
                    resource={ resource } record={ telecomOption }
                    confirm
                    confirmTitle={ ( record ) => `Delete "${ renderPhoneNumber( record?.value ) || record?.value || 'record' }"` }
                    confirmContent={ ( record ) => `Delete contact method "${ renderPhoneNumber( record?.value ) || record?.value || 'record' }"?` }
                    label="Delete"
                    className='rowDeleteButton'
                    onClick={ onDeleteContactMethod }
                    disabled={ isLoading }
                  >
                    <DeleteIcon fontSize='small' />
                  </DatagridRowButton>
                );
              } } />

              <TelecomOptionsUnsubscribedField unsubscribeds={ unsubscribeds } undeliverables={ undeliverables } />

            </Datagrid>


            <Grid item sx={ { mt: '0.5em', mb: '1em' } }>

              <EditInDialogButton
                label="Add phone"
                title="Add phone"
                icon={ isXSmall ? <PhoneIcon /> : <AddIcon /> }
                resource={ resource }
                ButtonProps={ {
                  disabled: isLoading,
                } }
                mutationMode='pessimistic'
              >

                <SimpleFormWrapper
                  sx={ { minWidth: '300px' } }
                  record={ {
                    ...record,
                    comment: `Edit via Admin App by ${ identity?.fullName } (${ identity?.id })`,
                    telecomOptions: [ {
                      system: 'phone',
                      dataSource: 'directEntry',
                      forcedOverridesDate: new Date().toISOString(),
                      rank: 1,
                    }, ...record.telecomOptions ]
                  } }
                  toolbar={ <Toolbar><SaveButton /></Toolbar> }
                  // @ts-ignore: 7006
                  onSubmit={ async ( data ) => {
                    const phoneField = 'telecomOptions.0.value';
                    const typeField = 'telecomOptions.0.system';
                    const { value, system } = data.telecomOptions[ 0 ];
                    const info = await lookupPhone( { phone: value } );
                    // console.log( info );
                    const type = get( info || {}, 'carrier.type', 'unknown' ) as string;
                    if( [ 'invalid', 'unknown' ].includes( type ) ) {
                      return { [ phoneField ]: 'This number is invalid.' };
                    }
                    if( system == 'sms' && ![ 'mobile', 'prepaid' ].includes( type ) ) {
                      return { [ typeField ]: `Selecting SMS for this ${ type } number is invalid.` };
                    }
                    data.telecomOptions[ 0 ].forcedUse = true;
                    data.telecomOptions[ 0 ].transmitMethod = system == 'sms' ? 'sms' : 'voice';
                    await update( resource, { id: record.id, data, previousData: record }, { returnPromise: true, mutationMode: 'pessimistic' } );
                    await refetchAll();
                  } }
                >
                  <AwesomePhoneInput label="Phone" source="telecomOptions.0.value" fullWidth required />
                  <RadioButtonGroupInput label="System" source="telecomOptions.0.system" row required
                    choices={ [
                      { id: 'phone', name: 'Phone/Voice' },
                      { id: 'sms', name: 'SMS/Text' },
                    ] } />
                </SimpleFormWrapper>
              </EditInDialogButton>

              <EditInDialogButton
                label="Add email"
                title="Add email"
                icon={ isXSmall ? <EmailIcon /> : <AddIcon /> }
                resource={ resource }
                ButtonProps={ {
                  disabled: isLoading,
                } }
                mutationMode='pessimistic'
              >
                <SimpleForm sx={ { minWidth: '300px' } }
                  record={ {
                    ...record,
                    comment: `Edit via Admin App by ${ identity?.fullName } (${ identity?.id })`,
                    telecomOptions: [ {
                      system: 'email',
                      rank: 1,
                      dataSource: 'directEntry',
                      forcedOverridesDate: new Date().toISOString(),
                    }, ...record.telecomOptions ]
                  } }
                  toolbar={ <Toolbar><SaveButton /></Toolbar> }
                >
                  <TextInput label="Email" source="telecomOptions.0.value" fullWidth validate={ email() } required autoComplete='email' />
                  { /* <SelectInput source="telecomOptions.0.use" choices={ choices.contactUse } fullWidth /> */ }
                </SimpleForm>
              </EditInDialogButton>

              { !isXSmall &&
                <ButtonWithConfirm
                  confirm
                  confirmTitle="Unsubscribe all"
                  confirmContent="Unsubscribe all contact methods?"
                  label='Unsubscribe all'
                  title='Unsubscribe all'
                  size="small"
                  // @ts-ignore: 2322
                  color='error'
                  onClick={ () => { onUnsubscribeAll( record ) } }
                  className='unsubscribeAllButton unsubscribeAllContactMethodsButton'
                  disabled={ isLoading || checkedIds.size === 0 }
                >
                  <Unsubscribe />
                </ButtonWithConfirm>
              }


            </Grid>


          </Grid>


          <Grid item sx={ { marginBottom: 4 } }>

            <CardHeader
              title={ `selected communications` }
              sx={ {
                marginTop: '1em',
                padding: 0,
                textTransform: 'uppercase',
              } }
              titleTypographyProps={ {
                fontSize: '1.25rem',
                fontWeight: 'bold',
                letterSpacing: '0.1rem',
              } }
            />
            { splitArray( commTypes, 2 ).map( ( commTypes, column ) => (

              <TableContainer key={ column } sx={ { width: 'fit-content', display: 'inline-block', verticalAlign: 'top' } }>
                <Table size='small' padding='none' aria-label='selected communications table'
                >
                  <TableBody>
                    { commTypes.map( ( row, idx ) =>
                      <StyledTableRow
                        key={ `row${ idx }` }
                        hover={ true }
                      >
                        <StyledBaseTableCell scope='row' >
                          <Checkbox
                            defaultChecked={ !record[ row.field ] }
                            onChange={ activateCommunicationType( row ) }
                            disabled={ isLoading }
                          />
                        </StyledBaseTableCell>

                        <StyledTableCell scope='row'>
                          { row.label }
                        </StyledTableCell>
                      </StyledTableRow>
                    ) }
                  </TableBody>
                </Table>
              </TableContainer>
            ) ) }

          </Grid>


          <Grid item container
            direction='column'
            alignItems='flex-start'
            xs={ 6 }
          >

            <CardHeader
              title={ `${ labelOfMode( resource ) } ${ resource != 'practitioners' ? 'authorized' : '' } recipients ${ recipients && recipients.length > 0 ? `(${ recipients.length })` : '' }` }
              sx={ {
                marginTop: '1em',
                padding: 0,
                textTransform: 'uppercase',
              } }
              titleTypographyProps={ {
                fontSize: '1.25rem',
                fontWeight: 'bold',
                letterSpacing: '0.1rem',

              } }
            />

            <ReferenceManyField label={ false } reference="recipients" target='parent' >
              <Datagrid
                rowClick={ () => false /* TODO remove click handler when this is fixed -> https://github.com/marmelab/react-admin/issues/10100 */ }
                bulkActionButtons={ false }
                header={ <NoDatagridHeader /> }
                sx={ {
                  '& .RaDatagrid-table': {
                    width: 'inherit',
                    marginLeft: '-12px',  // -leftPadding of rowCell
                  },
                  '& .RaDatagrid-rowCell': {
                    borderBottomWidth: 0,
                    padding: '0px 12px',
                    '& button': {
                      padding: 1,
                    },
                  },
                  '& .column-recipientType': {
                    xs: { display: 'none' },
                    sm: { textAlign: 'center', display: 'table-cell' },
                  },
                  '& .column-undefined': {
                    width: '1.5em',
                    '&:first-of-type': {
                      paddingLeft: 0,
                    },
                  },
                } }
              >

                <TextField label="Name" source='fullName' />

                <TelecomOptionsValueField label="Value" source='telecomOptions.0.value' />
                <TelecomOptionsValueField label="Value" source='telecomOptions.1.value' />
                <LanguageField source='lang' />
                <PatientRecipientPreferredCapabilityIcon color='action' source="capabilityIcons" />

                <EditInDialogButton
                  inline
                  title={ `Edit authorized recipient` }
                  mutationMode='pessimistic'
                  maxWidth='md'
                  fullWidth
                  ButtonProps={ {
                    disabled: isLoading,
                    color: 'inherit',
                  } }
                >
                  <PatientRecipientForm />
                </EditInDialogButton>

                <WithRecord render={ ( record ) => (
                  <DatagridRowButton
                    resource='recipients' record={ record }
                    confirm
                    confirmTitle='Delete recipient'
                    confirmContent={ `Are you sure you want to delete "${ record.fullName }" as a recipient?` }
                    label="ra.action.delete"
                    className='rowDeleteButton'
                    onClick={ onDeleteRecipient }
                    disabled={ isLoading }
                  >
                    <DeleteIcon fontSize='small' />
                  </DatagridRowButton>
                ) } />
                <TelecomOptionsUnsubscribedField unsubscribeds={ unsubscribeds } source='telecomOptions.0.value' undeliverables={ undeliverables } />
                <TelecomOptionsUnsubscribedField unsubscribeds={ unsubscribeds } source='telecomOptions.1.value' undeliverables={ undeliverables } />

              </Datagrid>
            </ReferenceManyField>

            <Grid item sx={ { mt: '0.5em', mb: '1em' } }>

              <RecordContextProvider value={ record }>
                <ResourceContextProvider value='recipients'>
                  <>

                    <CreateInDialogButton
                      label="Add recipient"
                      title={ `Add new authorized recipient` }
                      maxWidth='md'
                      fullWidth
                      icon={ <AddIcon /> }
                      record={ {
                        comment: `Add via Admin App by ${ identity?.fullName } (${ identity?.id })`,
                        fhirName: [],
                        recipientType: 'Non-Patient',
                        telecomOptions: [ { system: 'phone', rank: 1 }, { system: 'email', rank: 1 } ],
                        active: true,
                        unsubscribeFlag: false,
                        entryMethod: 'provider app',
                        parent: record.id,
                        parentType: resource == 'practitioners' ? 'Practitioner' : 'Recipient',
                      } }
                      ButtonProps={ {
                        disabled: isLoading,
                      } }
                    >
                      <PatientRecipientForm />
                    </CreateInDialogButton>

                    { !isXSmall &&
                      <ButtonWithConfirm
                        confirm
                        confirmTitle="Unsubscribe all"
                        confirmContent="Unsubscribe all recipients?"
                        label='Unsubscribe all'
                        title='Unsubscribe all'
                        size="small"
                        // @ts-ignore: 2322
                        color='error'
                        onClick={ () => { onUnsubscribeAllRecipients( recipients ) } }
                        className='unsubscribeAllButton unsubscribeAllRecipientsButton'
                        disabled={ !recipients?.length || isLoading || recipientsSubcriptions == 0 }
                      >
                        <Unsubscribe />
                      </ButtonWithConfirm>
                    }



                  </>
                </ResourceContextProvider>
              </RecordContextProvider>

            </Grid>

          </Grid>

        </Grid >
      </RecordContextProvider >
    </ResourceContextProvider >
  )
};

export const SimpleFormWrapper: FC<SimpleFormProps> = props => {
  const { onSubmit: rawOnSumbit } = props;
  const { close } = useFormDialogContext() || {};

  const onSubmit: typeof rawOnSumbit = rawOnSumbit && close
    // @ts-ignore: 2339
    ? async ( data, event ) => {
      const errors = await rawOnSumbit( data, event );
      if( errors ) return errors;
      close();
    }
    : undefined;
  return (
    <SimpleForm { ...props } onSubmit={ onSubmit } />
  );
}

