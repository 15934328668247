import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Title } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppointmentList } from './Appointments';
import { AppointmentTypeList } from './AppointmentTypes';
import { ResourceContextProvider } from './ResourceContextProvider';
import { AppointmentTypeSchedule } from './AppointmentTypeSchedule';
import { useShowScheduleFields } from './Organizations';
import { Schedule, Schedules } from './Schedule';
import dayjs from 'dayjs';

export const Appointment: FC = () => {
  const basePath = 'appointment';
  const tabs = [ 'appointments', 'appointmenttypes', 'appointmenttypeschedule', 'graph' ];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const showScheduleFields = useShowScheduleFields();
  const [ _location, setLocation ] = useAppLocationState();
  const [ tab, setTab ] = useState( tabs[ 0 ] );

  const handleChange = ( _event: SyntheticEvent, newValue: string ) => {
    navigate( `/${ basePath }/${ newValue }` );
  };

  useEffect( () => {
    setLocation( basePath );
    return () => setLocation( null );
  }, [] );

  useEffect( () => {
    const [ , , tab ] = pathname.split( '/' )
    if( !tabs.includes( tab ) ) return;
    setTab( tab );
  }, [ pathname ] );

  useEffect( () => {
    setLocation( `${ basePath }.${ tab }` );
  }, [ tab ] );

  return (
    <Box>
      <Title title='Appointment ' />
      <Tabs value={ tab } onChange={ handleChange } >
        <Tab disableRipple value='appointments' label='Appointments' />
        <Tab disableRipple value='appointmenttypes' label='Types' />
        { showScheduleFields &&
          <Tab disableRipple value='appointmenttypeschedule' label='Schedule Test' />
        }
        { showScheduleFields &&
          <Tab disableRipple value='graph' label='Schedule Graph' />
        }
      </Tabs>

      <Card
        sx={ {
          minHeight: '25em',
        } }
      >
        <CardContent>
          <ResourceContextProvider value={ tab }>
            { tab == 'graph' && showScheduleFields
              ? <Schedule d={ dayjs().add( 1, 'day' ).toISOString().slice( 0, 10 ) } />
              : tab == 'appointmenttypeschedule' && showScheduleFields
                ? <AppointmentTypeSchedule />
                : tab == 'appointmenttypes'
                  ? <AppointmentTypeList title={ <></> } />
                  : <AppointmentList title={ <></> } />
            }
          </ResourceContextProvider>
        </CardContent>
      </Card>


    </Box >
  );
}
